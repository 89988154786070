import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { Button } from "../../UIKit/Button";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import {
  ProductNews,
  ProductNewsJournal,
  productNewsPageResponse,
} from "./ProductNews";
import RoutesNews from "./Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "../../UIKit/Modal";

interface NewsListProps {
  journal: ProductNewsJournal;
  columns: tableColumn<ProductNews>[];
  clientId: string;
  supplierId: string;
}

export function ProductNewsList(props: NewsListProps) {
  const newsPerPage = 20;
  const history = useHistory();
  const intl = useIntl();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [newsToDelete, triggerDeleteModal] = useState<ProductNews | false>(
    false
  );
  const queryClient = useQueryClient();
  const {
    isFetching: newsAreFetching,
    data: newsPage,
    refetch,
  } = useQuery<productNewsPageResponse>(
    "productnews::all::page::" + startingAt,
    () => {
      return props.journal.findPaginated(
        (startingAt - 1) * newsPerPage + 1,
        newsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadNewsSlice(number: number) {
    setStartingAt(number);
  }

  function deleteNews(newsToDelete: ProductNews) {
    props.journal.delete(newsToDelete).then((success) => {
      if (success) {
        refetch();
        triggerDeleteModal(false);
      } else {
      }
    });
  }

  if (!newsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "productnews.checking.news",
            defaultMessage: "verification of new products",
          })}
        />
      </div>
    );
  } else {
    const news = newsPage.items;
    const newsStats = newsPage.stats;
    return (
      <>
        {newsToDelete && (
          <Modal
            onCancel={() => {
              triggerDeleteModal(false);
            }}
            onConfirm={() => deleteNews(newsToDelete)}
            altButtonLabel="cancel"
            mainButtonLabel="delete"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="productnews.delete.warning.title"
                  defaultMessage="Delete a new product"
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="productnews.delete.warning.body"
                  defaultMessage="This deletion is irreversible"
                />
              </p>
            </div>
          </Modal>
        )}
        {news.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="productnews.no.news.yet.1"
                  defaultMessage="...no new products for the moment 🤷"
                />
              </p>
              <p>
                <FormattedMessage
                  id="productnews.no.news.yet.2"
                  defaultMessage="You certainly have a product to highlight!"
                />
              </p>
              <div className="pt-2">
                <Button
                  label={"productnews.create.first.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(
                      RoutesNews(props.clientId, props.supplierId).add
                    )
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2 mt-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="productnews.productnews"
                  defaultMessage="New products"
                />{" "}
                &gt;{" "}
                <FormattedMessage
                  id="productnews.count.plural"
                  defaultMessage="{num, plural, one {# product news} other {# product news}}"
                  values={{ num: newsStats.totalCount }}
                />
              </h2>
              {newsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "productnews.checking.news",
                      defaultMessage: "verification of new products",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<ProductNews>
              onPageChange={loadNewsSlice}
              totalItemsCount={newsStats.totalCount || 0}
              itemsPerPage={newsPerPage}
              data={news}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  content: (news) => (
                    <div className="flex flex-row">
                      <div className="mr-1">
                        <Button
                          size="small"
                          iconName="PencilIcon"
                          onClick={() => {
                            history.push(
                              RoutesNews(props.clientId, props.supplierId).edit(
                                news.id
                              )
                            );
                          }}
                          label={"edit"}
                        />
                      </div>
                      <Button
                        size="tiny"
                        type="cancel"
                        iconName="XIcon"
                        onClick={() => {
                          triggerDeleteModal(news);
                        }}
                        label={intl.formatMessage({
                          id: "forms.delete",
                          defaultMessage: "delete",
                        })}
                      />
                    </div>
                  ),
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "productnews.add.a.news",
                    defaultMessage: "Add a new product",
                  })}
                  onClick={(_e) => {
                    history.push(
                      RoutesNews(props.clientId, props.supplierId).add
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
