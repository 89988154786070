import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { MetricsList } from "./MetricsList";
import { MetricsProviderAPI } from "./MetricsAPI";
import { FormattedMessage } from "react-intl";

export function MetricsModule() {
  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").metrics} exact>
        <MetricsDashboard />
      </Route>
    </Switch>
  );
}

export function MetricsDashboard() {
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const provider = new MetricsProviderAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">Statistiques</h2>
        <p className="text-xl">
          <FormattedMessage
            id="metrics.introduction.body"
            defaultMessage="All the data about how your application works"
          />
        </p>
        <hr className="mt-6 mb-6" />
        <div className="relative">
          <MetricsList provider={provider} />
        </div>
      </div>
    </div>
  );
}
