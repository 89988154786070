import React from "react";
import { mandatoryFieldLabel, validableFormWidgetProps } from "./forms";
import { UI_Size } from "../definitions";
import { InputLabel } from "./InputLabel";

interface Choice {
  value: string;
  label?: string;
}

interface SelectProps extends validableFormWidgetProps {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  expanded?: boolean;
  label?: string;
  size?: UI_Size;
  placeholder?: string;
  options: Choice[];
  onChange: (value: string) => void;
}

export function Select(props: SelectProps) {
  function onChoiceChange(e: any) {
    props.onChange(e);
  }

  return (
    <div>
      {!!props.label && (
        <InputLabel
          required={props.required}
          label={props.label}
          size={props.size}
          awaitsValidation={props.awaitsValidation}
          isValid={props.isValid}
          validationMessage={props.validationMessage || mandatoryFieldLabel}
        />
      )}
      {props.expanded ? (
        <ExpandedSelect
          value={props.value}
          options={props.options}
          onChange={onChoiceChange}
        />
      ) : (
        <SelectField
          options={props.options}
          onChange={onChoiceChange}
          id={props.id}
          required={props.required}
          name={props.name}
          size={props.size}
          placeholder={props.placeholder}
          value={props.value}
          isValid={props.isValid}
        />
      )}
    </div>
  );
}

function ExpandedSelect(props: {
  options: Choice[];
  value?: string;
  onChange: (value: string) => void;
}) {
  return (
    <>
      {props.options.map((o) => (
        <span
          onClick={() => props.onChange(o.value)}
          className={
            " cursor-pointer rounded text-xs p-1 " +
            (o.value === props.value
              ? " font-bold text-white bg-primary-500"
              : " ")
          }
        >
          {o.label}
        </span>
      ))}
    </>
  );
}

interface SelectFieldProps extends validableFormWidgetProps {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  size?: UI_Size;
  placeholder?: string;
  options: Choice[];
  onChange: (value: string) => void;
}
export function SelectField(props: SelectFieldProps) {
  const invalidClassNames =
    props.awaitsValidation || props.isValid
      ? " focus:ring-secondary-700 focus:border-secondary-700"
      : " ring-red-500 border-red-500 bg-red-50";
  return (
    <select
      id={props.id || ""}
      name={props.name || ""}
      value={props.value}
      required={props.required}
      onChange={(e) => props.onChange(e.target.value)}
      className={
        "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none " +
        invalidClassNames
      }
    >
      {props.placeholder && (
        <option value={undefined}>{props.placeholder}</option>
      )}
      {props.options.map((o, k) => (
        <option value={o.value} key={k}>
          {o.label || o.value}
        </option>
      ))}
    </select>
  );
}
