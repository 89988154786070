import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { InputDate } from "./InputDate";
import { EyeIcon } from "@heroicons/react/solid";
import { Switch } from "./Swich";
import { Statuses } from "../../modules/commonTypes";
import { useIntl } from "react-intl";

interface SchedulingFormWidgetState {
  publicationDate: DateTime;
  removalDate: DateTime;
  publicationStatus: Statuses;
}

export function SchedulingFormWidget(props: {
  onChange: (data: SchedulingFormWidgetState) => void;
  awaitsValidation: boolean;
  publicationDate?: DateTime;
  removalDate?: DateTime;
  publicationStatus: Statuses;
}) {
  const [publicationDate, setPublicationDate] = useState(
    props.publicationDate !== undefined
      ? props.publicationDate
      : DateTime.local()
  );
  const [removalDate, setRemovalDate] = useState(
    props.removalDate !== undefined ? props.removalDate : DateTime.local()
  );
  const [publicationStatus, setPublicationStatus] = useState<Statuses>(
    props.publicationStatus
  );
  const intl = useIntl();
  useEffect(() => {
    props.onChange({
      publicationDate: publicationDate,
      removalDate: removalDate,
      publicationStatus: publicationStatus,
    });
  }, [publicationDate, removalDate, publicationStatus]);
  useEffect(() => {
    setRemovalDate(props.removalDate ? props.removalDate : DateTime.local());
    setPublicationDate(
      props.publicationDate ? props.publicationDate : DateTime.local()
    );
    setPublicationStatus(props.publicationStatus);
  }, [props]);
  return (
    <div>
      <div className="flex flex-row">
        <div className=" w-1/2 capitalize">
          <InputDate
            name="publicationDate"
            label={intl.formatMessage({
              id: "schedulable.publication.date",
              defaultMessage: "publication date",
            })}
            date={publicationDate}
            onChange={setPublicationDate}
            isValid={!!publicationDate}
            awaitsValidation={props.awaitsValidation}
            required={true}
          />
        </div>
        <div className="ml-4 w-1/2">
          <InputDate
            name="removalDate"
            label={intl.formatMessage({
              id: "schedulable.removal.date",
              defaultMessage: "removal date",
            })}
            date={removalDate}
            onChange={setRemovalDate}
            isValid={!!removalDate}
            awaitsValidation={props.awaitsValidation}
            required={true}
          />
        </div>
      </div>
      <div className="mt-6">
        <h3 className="font-bold capitalize">
          <EyeIcon className="w-5 inline" />{" "}
          {intl.formatMessage({ id: "status", defaultMessage: "Status" })}
        </h3>
        <Switch
          choice1Label={intl.formatMessage(
            {
              id: "published.from.to",
              defaultMessage: "Published from {from} to {to}",
            },
            {
              from: publicationDate.setLocale(intl.locale).toLocaleString(),
              to: removalDate.setLocale(intl.locale).toLocaleString(),
            }
          )}
          choice1Value="published"
          choice2Label="draft"
          choice2Value="draft"
          onChange={setPublicationStatus}
          value={publicationStatus}
          name="status"
        />
      </div>
    </div>
  );
}
