import { AppRoutes } from "../../app/Routes";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).videos;

const routes = (clientId: string, supplierId: string) => {
  return {
    videos: base(clientId, supplierId),
    add: base(clientId, supplierId) + "/add",
    edit: (id: string) => `${base(clientId, supplierId)}/edit/${id}`,
  };
};

export default routes;
