import React, { useState } from "react";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
  Offer,
  OffersDirectory,
  offersPageResponse,
  OfferStatus,
} from "./Offers";
import { Button } from "../../UIKit/Button";
import { Modal } from "../../UIKit/Modal";
import { Badge, BadgeType } from "../../UIKit/Badge";
import { BONotificationPayload } from "../../app/Notifications";

interface OffersListProps {
  directory: OffersDirectory;
  columns: tableColumn<Offer>[];
  notify: (not: BONotificationPayload) => void;
}

export function OffersList(props: OffersListProps) {
  const offersPerPage = 20;
  const [startingAt, setStartingAt] = useState<number>(1);
  const [offerToPublish, triggerPublishModal] = useState<Offer | false>(false);
  const intl = useIntl();
  const {
    isFetching: offersAreFetching,
    data: offersPage,
    refetch,
  } = useQuery<offersPageResponse>(
    "offers::all::page::" + startingAt,
    () => {
      return props.directory
        .findPaginated((startingAt - 1) * offersPerPage + 1, offersPerPage)
        .catch(() => {
          props.notify({
            type: "error",
            message: "offers.error.when.fetching",
          });
          return { items: [], stats: { totalCount: 0 } };
        });
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function publishOffer(offer: Offer) {
    props.directory.publish(offer).then((success) => {
      if (success) {
        refetch();
        triggerPublishModal(false);
        props.notify({
          type: "success",
          message: "offers.offer.was.published",
        });
      } else {
        props.notify({
          type: "error",
          message: "offers.problem.when.publishing",
        });
      }
    });
  }

  function rejectOffer(offer: Offer) {
    props.directory.reject(offer).then((success) => {
      if (success) {
        refetch();
        triggerPublishModal(false);
        props.notify({ type: "success", message: "offers.offer.was.blocked" });
      } else {
        props.notify({
          type: "error",
          message: "offers.problem.when.blocking",
        });
      }
    });
  }

  function loadOffersSlice(number: number) {
    setStartingAt(number);
  }

  if (!offersPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "offers.checking.offers",
            defaultMessage: "Loading announcements",
          })}
        />
      </div>
    );
  } else {
    const offers = offersPage.items;
    const offersStats = offersPage.stats;
    return (
      <>
        {offerToPublish && (
          <Modal
            onCancel={() => {
              triggerPublishModal(false);
            }}
            onConfirm={() => {}}
            altButtonLabel="cancel"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="offer.moderate.warning.title"
                  defaultMessage="Moderate an announcement"
                />
              </p>
              <div className="bg-gray-100 p-2 rounded my-2 ">
                <div>
                  <p className="font-bold">{offerToPublish.title}</p>
                  <p>{offerToPublish.body}</p>
                </div>
                <ul className="mt-4 flex flex-row">
                  {offerToPublish.images.map((image) => {
                    return (
                      <li className="m-2">
                        <img
                          src={image.imageUrl}
                          className="max-w-2xl max-h-36"
                          alt=""
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex flex-row justify-end">
                <div className="mr-8">
                  <Button
                    onClick={() => rejectOffer(offerToPublish)}
                    label="offers.reject"
                    iconName="XIcon"
                    type="destroy"
                  />
                </div>
                <Button
                  onClick={() => publishOffer(offerToPublish)}
                  iconName={"CheckIcon"}
                  label="publish"
                  type="primary"
                />
              </div>
            </div>
          </Modal>
        )}
        {offers.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="offers.no.offer.yet.1"
                  defaultMessage="No announcement has been posted yet"
                />
              </p>
              <p>
                <FormattedMessage
                  id="offers.no.offer.yet.2"
                  defaultMessage="You will be notified by email when they appear!"
                />
              </p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="offers.count.plural"
                  defaultMessage="{num, plural, one {# announcement} other {# announcements}}"
                  values={{ num: offersStats.totalCount }}
                />
              </h2>
              {offersAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "offers.checking.offers",
                      defaultMessage: "Loading announcements",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Offer>
              onPageChange={loadOffersSlice}
              totalItemsCount={offersStats.totalCount || 0}
              itemsPerPage={offersPerPage}
              data={offers}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  align: "right",
                  content: (offer) => (
                    <div className="flex flex-row justify-end">
                      <Button
                        size="tiny"
                        type="secondary"
                        iconName="CheckIcon"
                        onClick={() => {
                          triggerPublishModal(offer);
                        }}
                        label={"offers.moderate"}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        )}
      </>
    );
  }
}

function typeFromStatus(status: OfferStatus): BadgeType {
  switch (status) {
    case "blocked":
      return "ko";
    case "published":
      return "ok";
    default:
      return "secondary";
  }
}

export function offersColumns(intl: IntlShape): tableColumn<Offer>[] {
  return [
    {
      label: intl.formatMessage({
        id: "offers.title",
        defaultMessage: "Title",
      }),
      content: (offer: Offer) => <div>{offer.title}</div>,
    },
    {
      label: intl.formatMessage({
        id: "offers.category",
        defaultMessage: "Category",
      }),
      content: (offer: Offer) => <div>{offer.category}</div>,
    },
    {
      label: "offers.status",
      content: (offer: Offer) => (
        <Badge
          type={typeFromStatus(offer.status)}
          label={intl.formatMessage({
            id: `offers.status.${offer.status}`,
            defaultMessage: offer.status,
          })}
        />
      ),
    },
  ];
}
