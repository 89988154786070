import React from "react";

type position = "first" | "last" | undefined;

function PaginationPageLink(props: {
  label: string;
  srLabel?: string;
  position?: position;
  isCurrent?: boolean;
  onClick: () => void;
}) {
  function extractRoundingStyle(pos: position): string {
    if (pos === "last") return "rounded-r-md";
    if (pos === "first") return "rounded-l-md";
    return "";
  }

  function extractCurrentStyle(isCurrent: boolean) {
    if (!isCurrent) return " bg-white hover:bg-gray-50";
    return " bg-gray-100 cursor-default ";
  }

  return (
    <>
      <span className="sr-only">{props.srLabel || props.label}</span>
      <button
        onClick={props.onClick}
        className={
          "w-10 text-center relative items-center px-1 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 " +
          extractRoundingStyle(props.position) +
          extractCurrentStyle(!!props.isCurrent)
        }
      >
        {props.label}
      </button>
    </>
  );
}

interface paginationPages {
  label: string;
  number: number;
}

export interface PaginationNavProps {
  totalItemsCount: number;
  onNavigate: (start: number) => void;
  itemsPerPage?: number;
  current?: number;
  nbLinksDisplayed?: number;
}

export function PaginationNav(props: PaginationNavProps) {
  const defaultItemsPerPage = 10;
  const defaultNbLinksDisplayed = 5;

  const nbLinksDisplayed = props.nbLinksDisplayed || defaultNbLinksDisplayed;
  const nbLinksBeforeCurrent = Math.floor((nbLinksDisplayed - 1) / 2);
  const nbLinksAfterCurrent = Math.floor((nbLinksDisplayed - 1) / 2);
  const pageCount = Math.ceil(
    props.totalItemsCount / (props.itemsPerPage || defaultItemsPerPage)
  );
  const current = props.current || 1;
  const pages: paginationPages[] = [];

  if (!pageCount) return <></>;

  for (
    let i = Math.max(
      1,
      Math.min(pageCount - nbLinksDisplayed + 1, current - nbLinksBeforeCurrent)
    );
    i <=
    Math.min(
      pageCount,
      Math.max(
        nbLinksDisplayed,
        Math.min(pageCount, current + nbLinksAfterCurrent)
      )
    );
    i++
  ) {
    pages.push({ label: i.toString(), number: i });
  }

  return (
    <div>
      <nav
        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
        aria-label="Pagination"
      >
        <PaginationPageLink
          label="&#10094;&#10094;"
          position="first"
          srLabel={"première page"}
          onClick={() => props.onNavigate(1)}
        />
        <PaginationPageLink
          label="&#10094;"
          srLabel={"page précédente"}
          onClick={() => props.onNavigate(Math.max(1, current - 1))}
        />
        {pages.map((page) => (
          <PaginationPageLink
            label={page.label}
            key={page.number}
            onClick={() => props.onNavigate(page.number)}
            isCurrent={page.number === props.current}
          />
        ))}
        <PaginationPageLink
          label="&#10095;"
          srLabel={"page suivante"}
          onClick={() => {
            props.onNavigate(Math.min(current + 1, pageCount));
          }}
        />
        <PaginationPageLink
          label="&#10095;&#10095;"
          position="last"
          srLabel={"dernière page"}
          onClick={() => props.onNavigate(pageCount)}
        />
      </nav>
    </div>
  );
}
