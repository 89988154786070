import React from "react";
import { appConfigurationsBySections } from "../Apps/Application";
import { BONotificationPayload } from "../../app/Notifications";
import { SectionConfigurationsForm } from "./SectionConfigurationsForm";
import { FormattedMessage } from "react-intl";
import { Loading } from "../../UIKit/Loading";

export interface AppsConfigurationsAdminFormProps {
  listsAreFetching: boolean;
  sectionedAppConfig: appConfigurationsBySections[] | undefined | void;
  submit: (feature: string, values: string[]) => void;
  notify: (notification: BONotificationPayload) => void;
  appName?: string;
}

export type ConfigurationFormType =
  | "BOOLEAN"
  | "COLOR"
  | "STRING"
  | "XML"
  | "JSON"
  | "MULTIPLE_STRING";

export function AppsConfigurationsForm(
  props: AppsConfigurationsAdminFormProps
) {
  return (
    <div className="flex gap-2 flex-col">
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-bold">
          <FormattedMessage
            id="appsConfigurationsForm.title"
            defaultMessage="Settings"
          />
          {props.appName && ` - ${props.appName}`}
        </h1>
        {props.listsAreFetching && <Loading />}
      </div>
      {props.sectionedAppConfig?.length === 0 ? (
        <div className="text-center">
          <FormattedMessage
            id="appsConfigurationsAdminForm.noConfigurations"
            defaultMessage="No configuration"
          />
        </div>
      ) : (
        props.sectionedAppConfig?.map((section) => (
          <SectionConfigurationsForm
            key={section.name}
            sectionTitle={section.name}
            sectionDescription={""}
            configurations={section.content.map((config) => config)}
            onSubmit={(feature, values) => {
              props.submit(feature, values);
            }}
          />
        ))
      )}
    </div>
  );
}
