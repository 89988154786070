import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DynamicHeroIcon } from "./DynamicHeroIcon";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { FileUploadZone } from "./form/FileUploadZone";
import { ConfigurationFormType } from "../modules/Settings/AppsConfigurationsForm";

export interface ConfigurationAdminFormProps {
  title: string;
  type: ConfigurationFormType;
  initialValue: string;
  onSubmit: (feature: string, values: string) => void;
  description?: string;
}

export function ConfigurationCardText(props: ConfigurationAdminFormProps) {
  const [value, setValue] = useState<string>(props.initialValue);
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div className="flex items-center justify-between bg-gray-100 text-base p-4 rounded-md gap-4">
      <div className="flex items-center gap-1">
        <FormattedMessage id={props.title} />
        {props.description && (
          <div
            onMouseOver={() => {
              setShowDescription(true);
            }}
            onMouseLeave={() => {
              setShowDescription(false);
            }}
          >
            <DynamicHeroIcon icon={"InformationCircleIcon"} width={"5"} />
          </div>
        )}
        {showDescription && props.description && (
          <FormattedMessage id={props.description} />
        )}
      </div>
      {props.type === "XML" || props.type === "JSON" ? (
        <TextEditor
          textType={props.type}
          text={value}
          setText={(s: string) => setValue(s)}
        />
      ) : (
        <div className="flex-1">
          <input
            className="rounded-lg p-2 w-full h-8"
            onInput={(input) => {
              setValue(input.currentTarget.value);
            }}
            value={value}
          />
        </div>
      )}
      <div className="h-8 w-8">
        <button
          className={
            "relative p-2 text-secondary rounded-full h-full w-full " +
            (value === props.initialValue
              ? "bg-primary-50"
              : "bg-primary-300 animate-bounce")
          }
          disabled={value === props.initialValue}
          onClick={() => {
            props.onSubmit(props.title, value);
          }}
        >
          <DynamicHeroIcon icon={"CheckIcon"} width={"4"} />
        </button>
      </div>
    </div>
  );
}

function TextEditor(props: {
  text: string;
  textType?: string;
  setText: (s: string) => void;
}): JSX.Element {
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [tempText, setTempText] = useState<string>(props.text);

  return (
    <>
      <CodeEditor
        value={props.text}
        language={props.textType}
        onChange={(evn: any) => props.setText(evn.target.value)}
        className="flex-1 rounded-lg p-2 border-black border-1"
        style={{
          backgroundColor: "#fff",
        }}
      />
      <Button
        label={"Import"}
        onClick={() => setImportModalOpen(true)}
        size={"small"}
        iconName={"ArrowDownIcon"}
      />
      {importModalOpen && (
        <ImportModal
          onCancel={() => {
            setImportModalOpen(false);
            setTempText("");
          }}
          onConfirm={() => {
            setImportModalOpen(false);
            props.setText(tempText);
          }}
          setImportText={(s: string) => setTempText(s)}
        />
      )}
    </>
  );
}

function ImportModal(props: {
  onCancel: () => void;
  onConfirm: () => void;
  setImportText: (s: string) => void;
}) {
  return (
    <Modal
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      mainButtonLabel={"Import"}
      altButtonLabel={"Cancel"}
    >
      <FileUploadZone
        onFileUpdate={async (file: File) => {
          props.setImportText(await file.text());
        }}
        onClear={() => {}}
        label={"Import"}
      />
    </Modal>
  );
}
