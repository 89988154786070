import { ContentRepository, Statuses } from "../commonTypes";
import { DateTime } from "luxon";

export type MeetupStatuses = Statuses;

export interface Meetup {
  id: string;
  startDate: DateTime;
  endDate: DateTime;
  location: string;
  title: string;
  description: string;
  publicationDate: DateTime;
  removalDate: DateTime;
  status: MeetupStatuses;
}

export interface MeetupCalendar extends ContentRepository<Meetup, meetupDTO> {}

export interface meetupDTO extends Meetup {}

export const EMPTY_MEETUP: Meetup = {
  id: "",
  publicationDate: DateTime.local().startOf("day"),
  removalDate: DateTime.local().endOf("day"),
  status: "draft",
  title: "",
  description: "",
  location: "",
  startDate: DateTime.local(),
  endDate: DateTime.local(),
};
