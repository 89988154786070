import React from "react";

export function DirBatPrinciples() {
  return (
    <div className="">
      <article className="prose">
        <h1>
          DirBat, data imports
          <br />
          Offre “Basic”
          <br />
          <small>notice explicative</small>
        </h1>
        <p>
          <em>
            DirBat vous permet de disposer d’une application entièrement dédiée
            à votre société, de la même manière que si vous aviez développé
            cette application en interne.
            <br />
            Le présent document précise les éléments que vous devez fournir pour
            permettre à DirBat de vous livrer le plus rapidement possible une
            application fonctionnelle, présentant{" "}
            <strong>vos données produits, à vos utilisateurs</strong>.
          </em>
        </p>
        <h2>Les grandes étapes</h2>
        <p>
          La mise en oeuvre de votre application peut être décomposée en 3
          étapes :
        </p>
        <ul>
          <li>la construction d’un prototype intégrant vos données produits</li>
          <li>la phase de tests et de remplissage des données éditoriales</li>
          <li>la soumission aux stores d’applications</li>
        </ul>
        <p>Le schéma ci-dessous précise ces étapes.</p>
      </article>
      <img
        src="/documentation/dirbatProcess.png"
        className="w-full"
        alt="DirBat process"
      />
      <article className="prose">
        <h2>Principes généraux</h2>
        <p>
          Vos données produits dans l’application DirBat constituent un
          catalogue. Ce catalogue comprend toutes les informations à la fois
          marketing, techniques, logistiques et commerciales qui permettent le
          fonctionnement de l’application.
        </p>
        <p>
          A un instant donné, votre application DirBat contient les données
          produit d’un catalogue. Les outils DirBat (API, back-office
          d’administration) vous permettent de mettre à jour de façon globale et
          unitaire ce catalogue : une mise à jour remplace l’intégralité des
          informations disponibles dans l’application.
        </p>
        <p>
          Les données nécessaires à l’alimentation de DirBat doivent donc être
          préparées à partir de vos outils (PIM, ERP, etc.), dans un des formats
          d’imports supportés par DirBat.
        </p>
        <p>
          Le format d’import préconisé est documenté ci-dessous : il correspond
          aux besoins spécifiques des applications DirBat, et son respect permet
          de garantir un import optimal des données dans votre application, avec
          un taux d’erreur minimal. Il peut être facilement généré à partir de
          vos données existantes, en particulier si vous disposez déjà de
          données structurées dans des formats standards (Edoni, FabDis...)
        </p>
        <p>
          DirBat supporte aussi l’import au format Edoni, toutefois la nature
          souple de ce format ne permet pas de garantir une exhaustivité des
          données nécessaire au fonctionnement optimal de l’application, et peut
          donc entraîner une utilisation dégradée de l’application par
          l’utilisateur final, en fonction de votre taux d’adoption de ce
          format.
        </p>
        <h2>Structuration des données dans DirBat</h2>
        <p>
          Les données concernant vos produits peuvent être considérées selon
          deux niveaux, qui vont constituer les couches d’alimentation de
          DirBat.
        </p>
        <ul>
          <li>
            <strong>marketing</strong> : les informations générales sur le
            produit : la description des problématiques qu’il résout et sa
            finalité, sa désignation, etc.
          </li>
          <li>
            <strong>technique</strong> : dimensions, couleur, caractéristiques
            individuelle de chaque référence
          </li>
        </ul>
        <p>
          Le niveau “marketing” est incarné par le “produit” : il s’agit de
          l’item sur lequel vous <em>communiquez</em> : par exemple, une bobine
          de câble, ou un sabot de solive. Ce produit peut être décliné en
          plusieurs références.
          <br />
          Le niveau technique est incarné par la “référence” : il s’agit de l’
          <em>unité de fabrication</em>. La référence véhicule des données
          dimensionnelles, esthétiques, de résistance, etc., comme la longueur,
          la couleur ou la finition.
        </p>
        <h2>Focus sur les “catégories”</h2>
        <p>
          La catégorisation de produits dans votre application est l’un des
          éléments déterminant la façon dont vos utilisateurs vont découvrir vos
          produits. Nous vous permettons de mettre en place jusqu’à 3 axes de
          navigation dans votre catalogue, via le champ “catégories” de l’onglet
          “products”.
        </p>
      </article>
    </div>
  );
}
