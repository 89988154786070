export type NotificationType = "error" | "success";

export interface BONotification extends BONotificationPayload{
  id: string;
}
export interface BONotificationPayload {
  type: NotificationType;
  message: string;
}

export const notificationAutoremoveDelay = 5000;