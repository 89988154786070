import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { ContactMessage } from "./ContactMessages";
import { Repository } from "../Repository";
import { PageResponse } from "../commonTypes";
import { dirbatLocale } from "../../translations/translations";

function getMessages(messageData: []): ContactMessage[] {
  return messageData.map((md: any) => mapMessageFromApi(md));
}

function mapMessageFromApi(md: any): ContactMessage {
  return {
    id: md.id,
    createdAt: DateTime.fromISO(md.createdAt),
    email: md.email,
    message: md.message,
    author: md.name,
    phone: md.phone,
    subject: md.subject,
  };
}

export class MailboxAPI
  extends APIClient
  implements Repository<ContactMessage> {
  constructor(
    token: string,
    clientId: string,
    private supplierId: string,
    locale: dirbatLocale
  ) {
    super(token, locale);
  }
  findPaginated(
    firstIdx: number,
    itemsPerPage: number
  ): Promise<PageResponse<ContactMessage>> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/contacts?page=${
        Math.floor(firstIdx / itemsPerPage) + 1
      }`
    )
      .then((response) => response.json())
      .then((messagesData) => {
        return {
          items: getMessages(messagesData["hydra:member"]),
          stats: { totalCount: messagesData["hydra:totalItems"] },
        };
      });
  }
}
