export type dirbatImportFormat = "dirbat_json" | "dirbat_xls" | "edoni";
export type catalogueId = string;
export type catalogueStatus =
  | "pending_conversion"
  | "converting"
  | "publishable"
  | "unpublishable"
  | "pending_publication"
  | "publishing"
  | "published"
  | "publication_failed";
export interface Catalogue {
  id: catalogueId;
  format: dirbatImportFormat;
  status: catalogueStatus;
  title: string;
}

interface conversionViolation {
  propertyPath: string;
  title: string;
  parameters: {};
}

export interface catalogueConversionError {
  type: string;
  title: string;
  detail: string;
  violations: conversionViolation[];
}

export interface catalogueConversionReport {
  errorsByProduct: {}[];
  itemsCount: number;
  itemsWithErrorsCount: number;
  processableItemsCount: number;
  validItems: number;
  fileErrors: [];
  errors: {
    [lineNumber: number]: catalogueConversionError;
  };
}

export function reportShouldBeAvailable(catalogue: Catalogue) {
  return (
    catalogue.status !== "converting" &&
    catalogue.status !== "pending_conversion"
  );
}
