import React from "react";
import { tableColumn } from "../../UIKit/Table";
import { ContactMessage } from "./ContactMessages";
import { ContactMessagesActions } from "./ContactMessagesActions";

export const messageColumns: tableColumn<ContactMessage>[] = [
  {
    label: "messages.author",
    content: (message: ContactMessage) => message.author,
  },
  {
    label: "messages.subject",
    content: (message: ContactMessage) => message.subject,
  },
  {
    label: "messages.date",
    content: (message: ContactMessage) => message.createdAt.toLocaleString(),
  },
  {
    label: "messages.phone",
    content: (message: ContactMessage) => message.phone,
  },
  {
    label: "messages.email",
    content: (message: ContactMessage) => message.email,
  },
  {
    label: "actions",
    align: "center",
    content: (message) => <ContactMessagesActions message={message} />,
  },
];