import React from "react";
import { APICataloguesProvider } from "./CataloguesDirectoryAPI";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  catalogueConversionError,
  catalogueConversionReport,
  catalogueId,
} from "./Catalogue";
import { Loading } from "../../UIKit/Loading";
import { TriCard } from "../../UIKit/TriCard";
import { FormattedMessage, useIntl } from "react-intl";
import { ConversionReportNotAvailable } from "./ConversionReportNotAvailable";

export function CatalogueConversionReport(props: {
  cataloguesProvider: APICataloguesProvider;
}) {
  const { catalogueId, supplierId } = useParams<{
    catalogueId: catalogueId;
    supplierId: string;
  }>();
  const intl = useIntl();
  try {
    const {
      isFetching: reportIsFetching,
      data: conversionReport,
    } = useQuery<catalogueConversionReport>(
      "catalogue::conversionReport::" + catalogueId,
      () => {
        return props.cataloguesProvider.getReport(catalogueId, supplierId);
      }
    );
    if (!conversionReport) {
      return (
        <div className="min-h-screen flex flex-col justify-center items-center">
          <Loading
            label={intl.formatMessage({
              id: "catalogues.report.fetching.report",
              defaultMessage: "loading the report...",
            })}
          />
        </div>
      );
    } else {
      const criticalErrors = Object.entries(conversionReport.errors).filter(
        ([line, err]) => {
          return err.violations[0].title !== "product.duplicatecode";
        }
      );
      const warnings = Object.entries(conversionReport.errors).filter(
        ([line, err]) => {
          return err.violations[0].title === "product.duplicatecode";
        }
      );
      return (
        <div>
          <div className="flex justify-between">
            <TriCard
              label={intl.formatMessage({
                id: "catalogue.report.number.of.valid.products",
                defaultMessage: "Number of valid dealers",
              })}
              iconName="BadgeCheckIcon"
              value={conversionReport.processableItemsCount?.toString()}
            />
            <TriCard
              label={intl.formatMessage({
                id: "catalogue.report.number.of.errored.products",
                defaultMessage: "Number of products with error",
              })}
              value={conversionReport.itemsWithErrorsCount.toString()}
              iconName="ExclamationCircleIcon"
            />
            <TriCard
              label={intl.formatMessage({
                id: "catalogue.report.number.of.products",
                defaultMessage: "Number of products found",
              })}
              iconName="StarIcon"
              value={conversionReport.itemsCount.toString()}
            />
          </div>
          {conversionReport.fileErrors.map((e) => (
            <TriCard label={e} iconName="ExclamationIcon" />
          ))}
          <h3 className="text-xl font-bold m-4">
            Erreur critiques (le produit ne s'affiche pas) :{" "}
            {criticalErrors.length}
          </h3>
          {criticalErrors.map(([lineNumber, error]) => (
            <ErrorLine error={error} lineNumber={lineNumber} type="critical" />
          ))}
          <h3 className="text-xl font-bold m-4">
            Lignes ignorées (doublons) : {warnings.length}
          </h3>
          {warnings.map(([lineNumber, error]) => (
            <ErrorLine error={error} lineNumber={lineNumber} type="warning" />
          ))}
        </div>
      );
    }
  } catch (e) {
    if (e instanceof ConversionReportNotAvailable) {
      return <div>report not available for catalogue {catalogueId}</div>;
    }
    throw e;
  }
}

function ErrorLine(props: {
  lineNumber: string;
  error: catalogueConversionError;
  type: "critical" | "warning";
}) {
  return (
    <div className="hover:bg-gray-50 mb-4 bg-white shadow overflow-hidden sm:rounded-lg flex flex-row items-center">
      <div className="transform -rotate-90 origin-center text-center">
        <FormattedMessage
          id="catalogues.product.plural"
          values={{ num: 1 }}
          defaultMessage="{num, plural, one {produit} other {produits}}"
        />
      </div>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          <FormattedMessage id="catalogues.report.line" defaultMessage="line" />{" "}
          <span className="inline-flex bg-primary-400 rounded-full p-2">
            {props.lineNumber}
          </span>{" "}
        </h3>
      </div>
      <div className="border-l border-gray-200 flex-1">
        <dl>
          {props.error.violations.map((violation) => {
            return (
              <div className="bg-gray-50 px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300">
                <dt className="text-sm font-medium text-gray-500">
                  <FormattedMessage
                    id={"catalogues.report." + violation.propertyPath}
                  />
                </dt>
                <dd
                  className={
                    "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" +
                    (props.type === "critical" ? " text-red-500 font-bold" : "")
                  }
                >
                  <FormattedMessage
                    id={"catalogues.report." + violation.title}
                  />
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
}
