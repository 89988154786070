import { AppRoutes } from "../../app/Routes";
import { dirbatImportFormat } from "../Catalogues/Catalogue";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).documentation;

const routes = (clientId: string, supplierId: string) => {
  return {
    documentation: base(clientId, supplierId),
    faq: `${base(clientId, supplierId)}/faq`,
    doc_principles: `${base(clientId, supplierId)}/principles`,

    doc_sample: (
      format: dirbatImportFormat | ":format",
      type: "products" | "dealers" | ":type"
    ) => {
      return `${base(clientId, supplierId)}/sample/${type}/${format}`;
    },
    doc_import_catalogues(format: dirbatImportFormat | ":format") {
      return base(clientId, supplierId) + "/catalogues/" + format;
    },
    doc_import_dealers(format: dirbatImportFormat | ":format") {
      return base(clientId, supplierId) + "/dealers/" + format;
    },
  };
};

export default routes;
