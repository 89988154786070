import React, { useEffect, useState } from "react";
import { videoDTO, VideoLibrary } from "./Video";
import { BONotificationPayload } from "../../app/Notifications";
import { DateTime } from "luxon";
import { NewsStatus } from "../News/News";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { Input } from "../../UIKit/form/Input";
import { FileUploadZone } from "../../UIKit/form/FileUploadZone";
import { Button } from "../../UIKit/Button";
import { SchedulingFormWidget } from "../../UIKit/form/SchedulingFormWidget";
import { NotificationFormWidget } from "../../UIKit/form/NotificationFormWidget";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";

export interface VideoFormProps {
  awaitsValidation: true;
  data: videoDTO;
  library: VideoLibrary;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
}

export function VideoForm(props: VideoFormProps) {
  let step = 1;
  const { user } = useAuth();
  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const [title, setTitle] = useState<string>(props.data.title);
  const [url, setUrl] = useState<string>(props.data.url);
  const [headline, setHeadline] = useState<string>(props.data.headline);
  const [publicationDate, setPublicationDate] = useState<DateTime | undefined>(
    props.data.publicationDate ? props.data.publicationDate : DateTime.local()
  );
  const [removalDate, setRemovalDate] = useState<DateTime | undefined>(
    props.data.removalDate ? props.data.removalDate : DateTime.local()
  );
  const [image, setImage] = useState<File | undefined>(undefined);
  const [status, setStatus] = useState<NewsStatus>(props.data.status);
  const [triggersNotification, setTriggersNotification] = useState<boolean>(
    props.data.triggersNotification
  );
  const intl = useIntl();
  function onFileUpdate(file: File) {
    setImage(file);
  }
  function isValid(): boolean {
    return (
      !!title &&
      !!url &&
      !!headline &&
      !!publicationDate &&
      !!status &&
      (!!image || !!props.data.imagePath)
    );
  }
  useEffect(() => {
    setTitle(props.data.title);
    setUrl(props.data.url);
    setHeadline(props.data.headline);
    setTriggersNotification(props.data.triggersNotification);
    setPublicationDate(props.data.publicationDate);
    props.data.removalDate && setRemovalDate(props.data.removalDate);
    setStatus(props.data.status);
  }, [props.data]);
  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const videoDTO: videoDTO = {
        title: title,
        url: url,
        image: image,
        triggersNotification: triggersNotification,
        imagePath: props.data.imagePath,
        headline: headline,
        publicationDate: publicationDate,
        removalDate: removalDate,
        id: props.data.id,
        status: status,
      };
      props.library
        .save(videoDTO)
        .then((savedVideo) => {
          props.onSuccess();
        })
        .catch((reason) => props.notify({ type: "error", message: reason }));
    }
  }
  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.content",
              defaultMessage: "Content",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="title"
            label={intl.formatMessage({
              id: "videos.title",
              defaultMessage: "Video title",
            })}
            value={title}
            maxLength={40}
            isValid={!!title}
            required={true}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="url"
            label={intl.formatMessage({
              id: "videos.url",
              defaultMessage: "URL",
            })}
            value={url}
            isValid={!!url}
            onChange={(e: any) => {
              setUrl(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="headline"
            maxLength={100}
            label={intl.formatMessage({
              id: "videos.headline",
              defaultMessage: "Headline",
            })}
            isValid={!!headline}
            value={headline}
            onChange={(e: any) => {
              setHeadline(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <div className="mb-4">
            <div className="flex flex-row items-start justify-start">
              <div className="">
                <FileUploadZone
                  label={intl.formatMessage({
                    id: "videos.field.label.image",
                    defaultMessage: "Preview image",
                  })}
                  onFileUpdate={onFileUpdate}
                  required={true}
                  awaitsValidation={awaitsValidation}
                  validationMessage="videos.validation.image.required"
                  isValid={!!image || !!props.data.imagePath}
                  onClear={() => {
                    setImage(undefined);
                  }}
                />
              </div>
              <img
                src={image ? URL.createObjectURL(image) : props.data.imagePath}
                className="h-40 ml-4 mt-6"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mb-4 w-1/3">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.schedule",
              defaultMessage: "Scheduling",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <SchedulingFormWidget
            onChange={(data) => {
              setPublicationDate(data.publicationDate);
              setRemovalDate(data.removalDate);
              setStatus(data.publicationStatus);
            }}
            awaitsValidation={props.awaitsValidation}
            publicationDate={publicationDate}
            removalDate={removalDate}
            publicationStatus={status}
          />
          <NotificationFormWidget
            triggersNotification={triggersNotification}
            triggersImmediately={
              publicationDate !== undefined &&
              publicationDate < DateTime.local()
            }
            onChange={setTriggersNotification}
            notification={{
              applicationName: user.supplier,
              body: title,
              title: "Vidéo",
            }}
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        {props.data.id ? (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: "videos.edit.submit",
              defaultMessage: "Edit this video",
            })}
          />
        ) : (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: '"videos.add.submit',
              defaultMessage: "Add this video",
            })}
          />
        )}
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            type="secondary"
            label="cancel"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
