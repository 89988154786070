import React, { ReactNode, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Ability,
  ApplicationContext,
  AuthControl,
  useAuth,
  User,
} from "../auth";
import { LogoutIcon, UserIcon } from "@heroicons/react/solid";
import { AppRoutes } from "../app/Routes";
import { FormattedMessage } from "react-intl";
import { DynamicHeroIcon, IconName } from "./DynamicHeroIcon";
import { dirbatLocale } from "../translations/translations";
import { Application } from "../modules/Apps/Application";

interface navlinkProps {
  label: string;
  icon?: string | ReactNode;
  iconName?: IconName;
  active?: boolean;
  route: string;
}

export interface menuItem {
  label: string;
  route: string;
  icon?: string | ReactNode;
  iconName?: IconName;
  neededAbilities: Ability[];
  children?: menuItem[];
}

function Navlink(props: navlinkProps) {
  const classnames = props.active
    ? "bg-gray-900 text-white"
    : "text-gray-600 hover:bg-gray-300 ";
  return (
    <Link
      to={props.route}
      className={classnames + " px-3 py-2 rounded-md text-sm"}
    >
      <div className="capitalize flex items-center leading-4">
        {props.icon ? props.icon + " " : ""}
        {!!props.iconName && (
          <div className="w-7 mr-1">
            <DynamicHeroIcon icon={props.iconName} width="6" />
          </div>
        )}
        {props.label}
      </div>
    </Link>
  );
}

export function Header(props: {
  menu: menuItem[];
  currentRoute: string;
  setLocale: (locale: dirbatLocale) => void;
  clientId: string;
  supplierId: string;
  teamId: string;
}) {
  const auth = useAuth();

  const application = useContext(ApplicationContext);

  return (
    <HeaderInner
      context={{ auth: auth, application: application }}
      menu={props.menu}
      currentRoute={props.currentRoute}
      setLocale={props.setLocale}
    />
  );
}

interface context {
  auth: { user: User; control: AuthControl };
  application: Application;
}

export function HeaderInner(props: {
  context: context;
  menu: menuItem[];
  currentRoute: string;
  setLocale: (locale: dirbatLocale) => void;
}) {
  return (
    <nav className="bg-gray-200 fixed min-w-full z-50 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ContextBar context={props.context} setLocale={props.setLocale} />
        <Navigation
          menu={props.menu}
          currentRoute={props.currentRoute}
          context={props.context}
        />
      </div>
    </nav>
  );
}

function ContextBar(props: {
  context: context;
  setLocale: (locale: dirbatLocale) => void;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-items-start">
        <div className="flex-shrink-0">
          <Link to={AppRoutes().home}>
            <img className="w-24" src="/logo.svg" alt="DirBat" />
          </Link>
        </div>
        <div className="ml-6">
          <strong>{props.context.application.supplierName}</strong>
          &nbsp;&gt;&nbsp;
          {props.context.application.name}
        </div>
      </div>
      <div>
        <div className="group relative">
          <div className="text-sm flex flex-row items-center">
            <div className="rounded-full p-1 bg-gray-100 m-1 mr-2">
              <UserIcon className="w-5" />
            </div>
            <div>
              <div className="font-bold leading-4">
                {props.context.auth.user.name}
              </div>
              <div className="text-xs">{props.context.auth.user.supplier}</div>
            </div>
          </div>
          <div className="absolute group-hover:block hidden bg-gray-100 shadow p-4 rounded -right-1">
            <div className="px-4 py-2 hover:bg-gray-200 rounded">
              <Link
                to={AppRoutes().logout}
                className="whitespace-nowrap flex flex-nowrap"
              >
                <LogoutIcon className="w-5 mr-2" />
                <FormattedMessage id="logout" defaultMessage="logout" />
              </Link>
            </div>
            <div className="px-4 py-2 hover:bg-gray-200 rounded">
              <div
                className="whitespace-nowrap"
                onClick={() => props.setLocale("en")}
              >
                <span className="w-5 mr-2">🇬🇧</span>
                <span>English</span>
              </div>
            </div>
            <div className="px-4 py-2 hover:bg-gray-200 rounded">
              <div
                className="whitespace-nowrap"
                onClick={() => props.setLocale("fr")}
              >
                <span className="w-5 mr-2">🇫🇷</span>
                <span>Français</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Navigation(props: {
  context: context;
  menu: menuItem[];
  currentRoute: string;
}) {
  const application = useContext(ApplicationContext);
  return (
    <div className=" flex items-center space-x-4 pt-2">
      {props.menu.map((menuItem, k) => {
        if (
          props.context.auth.control.userCan(
            props.context.auth.user,
            menuItem.neededAbilities,
            application
          )
        ) {
          return (
            <div className="group relative" key={k}>
              <div className="text-sm flex flex-row items-center">
                <Navlink
                  label={menuItem.label}
                  active={menuItem.route === props.currentRoute}
                  route={menuItem.route}
                  icon={menuItem.icon}
                  iconName={menuItem.iconName}
                />
              </div>
              {menuItem.children && (
                <div className="absolute group-hover:block hidden bg-gray-100 shadow p-4 rounded text-sm">
                  {menuItem.children.map((subItem) => {
                    if (
                      props.context.auth.control.userCan(
                        props.context.auth.user,
                        subItem.neededAbilities,
                        application
                      )
                    ) {
                      return (
                        <Link
                          key={subItem.route}
                          to={subItem.route}
                          className="whitespace-nowrap px-4 py-2 hover:bg-gray-200 rounded block"
                        >
                          {subItem.icon ? subItem.icon + " " : ""}
                          {subItem.label}
                        </Link>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}

function SideMenuItem(props: navlinkProps) {
  return (
    <Link
      className={
        `p-2 px-4 rounded my-2 block` +
        (props.active ? " bg-gray-300 " : " bg-white ")
      }
      to={props.route}
    >
      {props.label}
    </Link>
  );
}

export function SideMenu(props: { items: menuItem[]; currentRoute: string }) {
  return (
    <div className="w-1/5 flex-1 bg-gray-50 p-5 mr-4">
      {props.items.map((i, k) => (
        <SideMenuItem
          key={k}
          route={i.route}
          label={i.label}
          active={i.route === props.currentRoute}
        />
      ))}
    </div>
  );
}
