import { BONotificationPayload } from "../../app/Notifications";
import { SettingsDirectory } from "./SettingsDirectoryAPI";
import React, { useEffect, useState } from "react";
import RichTextEditor, { EditorValue, ToolbarConfig } from "react-rte";
import { Modal } from "../../UIKit/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { Switch } from "../../UIKit/form/Swich";
import { Button } from "../../UIKit/Button";

const toolbarConfig: ToolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  BLOCK_ALIGNMENT_BUTTONS: [],
  INLINE_STYLE_BUTTONS: [
    { label: "Gras", style: "BOLD", className: "custom-css-class" },
    { label: "Italique", style: "ITALIC" },
    { label: "souligné", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Titre", style: "header-one" },
    { label: "Sous-titre", style: "header-two" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "liste à puce", style: "unordered-list-item" },
    { label: "liste numérotée", style: "ordered-list-item" },
  ],
};

export function PrivacyPolicyForm(props: {
  onSave: () => void;
  notify: (not: BONotificationPayload) => void;
  directory: SettingsDirectory;
  awaitsValidation: boolean;
  data: any;
}) {
  const [id, setId] = useState<string>("");
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [isNewVersion, setIsNewVersion] = useState<boolean>(false);
  const [modalIsOn, toggleModal] = useState<boolean>(false);
  const [
    initialContentInMarkdown,
    setInitialContentInMarkdown,
  ] = useState<string>("");

  const intl = useIntl();
  function onChange(value: EditorValue) {
    setEditorValue(value);
  }

  async function onSave() {
    let dto;
    if (isNewVersion || !id) {
      dto = await props.directory.upgrade({
        content: editorValue.toString("markdown"),
      });
    } else {
      dto = await props.directory.update({
        content: editorValue.toString("markdown"),
        id: id,
      });
    }
    setId(dto.id!);
    setInitialContentInMarkdown(dto.content);
    toggleModal(false);
    setIsNewVersion(false);
  }

  useEffect(() => {
    (async () => {
      const privacyPolicy = await props.directory.getPrivacyPolicy();
      const contentInMarkdown = privacyPolicy.content;
      setInitialContentInMarkdown(contentInMarkdown);
      setEditorValue(
        RichTextEditor.createValueFromString(contentInMarkdown, "markdown")
      );
      if (privacyPolicy.id) {
        setId(privacyPolicy.id);
      }
    })();
  }, []);

  function triggerSave() {
    if (!id) {
      onSave();
    } else {
      toggleModal(true);
    }
  }

  return (
    <div>
      {modalIsOn && (
        <Modal
          onCancel={() => {
            toggleModal(false);
          }}
          onConfirm={() => onSave()}
          altButtonLabel="cancel"
          mainButtonLabel="settings.privacyPolicy.update.btn"
        >
          <div>
            <p className="font-bold text-xl">
              <FormattedMessage
                id="settings.privacyPolicy.save.title"
                defaultMessage="Changing your privacy policy"
              />
            </p>
            <div className="">
              <FormattedMessage
                id="settings.privacyPolicy.save.body"
                defaultMessage={
                  "You are about to replace your privacy policy by a new version. \nDo you wish that this new version triggers a new acceptation request by your users?"
                }
              />
              <Switch
                choice1Label="settings.privacyPolicy.upgrade.choice"
                choice1Value="true"
                choice2Label="settings.privacyPolicy.update.choice"
                choice2Value="false"
                onChange={(value) => {
                  setIsNewVersion(value === "true");
                }}
                value={isNewVersion ? "true" : "false"}
                name="status"
              />
            </div>
          </div>
        </Modal>
      )}
      <div className="flex flex-row justify-between mb-4">
        <div className="w-1/2">
          <FormattedMessage
            id="settings.privacyPolicy.help.1"
            defaultMessage="Your privacy policy will be presented to users of your application for acceptance when they first open the application."
          />
          <br />
          <FormattedMessage
            id="settings.privacyPolicy.help.2"
            defaultMessage="Acceptance of this privacy policy is mandatory for your users."
          />
          <br />
          {!id && (
            <div>
              <FormattedMessage
                id="settings.privacyPolicy.help.3"
                defaultMessage="It is therefore necessary that you fill out this form."
              />
            </div>
          )}
        </div>
        {initialContentInMarkdown !== editorValue.toString("markdown") && (
          <div className="whitespace-nowrap">
            <Button
              onClick={() => triggerSave()}
              label={
                id
                  ? intl.formatMessage({
                      id: "settings.privacyPolicy.update.btn",
                      defaultMessage: "Update",
                    })
                  : intl.formatMessage({
                      id: "settings.privacyPolicy.create.btn",
                      defaultMessage: "Register",
                    })
              }
            />
          </div>
        )}
      </div>
      <RichTextEditor
        value={editorValue}
        onChange={onChange}
        toolbarConfig={toolbarConfig}
      />
    </div>
  );
}
