import { mandatoryFieldLabel } from "./forms";
import React from "react";
import { UI_Size } from "../definitions";
import { FormattedMessage } from "react-intl";

export function InputLabel(props: {
  htmlFor?: string;
  required?: boolean;
  isValid?: boolean;
  size?: UI_Size;
  awaitsValidation?: boolean;
  label: string;
  validationMessage?: string;
}) {
  return (
    <label
      htmlFor={props.htmlFor}
      className={
        "block font-medium text-gray-700 " +
        (props.size === "small" ? " text-sm " : " text-base ")
      }
    >
      {props.required && (
        <>
          {props.isValid && !props.awaitsValidation ? (
            <span className={"text-green-500"}>&#10003;&nbsp;</span>
          ) : (
            <span className={"text-red-500"}>&#10033;&nbsp;</span>
          )}
        </>
      )}
      {props.label}
      {!props.awaitsValidation && !props.isValid ? (
        <>
          {props.label !== "" ? <>&nbsp;:&nbsp;</> : ""}
          <span className={"text-red-500 font-bold"}>
            <FormattedMessage
              id={props.validationMessage || mandatoryFieldLabel}
            />
          </span>
          &nbsp;&darr;&nbsp;
        </>
      ) : (
        <></>
      )}
    </label>
  );
}
