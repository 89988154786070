import { APP_CONFIG } from "../../init";
import { handleErrors } from "../../app/APIError";
import { APIClient, NotFoundError } from "../../utilities/APIClient";
import { EMPTY_POLICY } from "./SettingsModule";
import { appConfigurationsBySections } from "../Apps/Application";
import { dirbatLocale } from "../../translations/translations";

export interface privacyPolicyDTO {
  id?: string;
  content: string;
}

export interface SettingsDirectory {
  getPrivacyPolicy(): Promise<privacyPolicyDTO>;
  upgrade(dto: privacyPolicyDTO): Promise<privacyPolicyDTO>;
  update(dto: privacyPolicyDTO): Promise<privacyPolicyDTO>;
  setSettings(feature: string, state: string[]): Promise<boolean>;
  getSettings(): Promise<appConfigurationsBySections[]>;
}

export class SettingsDirectoryAPI
  extends APIClient
  implements SettingsDirectory {
  constructor(
    token: string,
    private clientId: string,
    private supplierId: string,
    language: dirbatLocale
  ) {
    super(token, language);
  }
  getPrivacyPolicy(): Promise<privacyPolicyDTO> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/privacy_policies/latest`
    )
      .then((response) => response.json())
      .then((data: privacyPolicyDTO) => data)
      .catch((_e: NotFoundError) => {
        return Promise.resolve(EMPTY_POLICY);
      })
      .catch((_e: Error) => {
        return Promise.reject("unknown error");
      });
  }

  upgrade(dto: privacyPolicyDTO): Promise<privacyPolicyDTO> {
    return this.save(
      dto,
      "POST",
      "application/json",
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/privacy_policies`
    );
  }

  update(dto: privacyPolicyDTO): Promise<privacyPolicyDTO> {
    return this.save(
      dto,
      "PATCH",
      "application/merge-patch+json",
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/privacy_policies/${dto.id}`
    );
  }

  setSettings(feature: string, state: string[]): Promise<boolean> {
    state = state.filter((s) => s !== "");

    const data = {
      feature: feature,
      values: JSON.stringify(state),
    };

    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/mobile_apps/${this.clientId}/app_configs`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return true;
        }
        return response.json().then((error) => {
          throw new Error(error["hydra:description"]);
        });
      })
      .then((triggerSucceeded) => triggerSucceeded);
  }

  getSettings(): Promise<appConfigurationsBySections[]> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/sectioned_configs`
    )
      .then((response) => response.json())
      .then((sectionedConfigs) => {
        return sectionedConfigs["hydra:member"];
      });
  }

  private save(
    dto: privacyPolicyDTO,
    method: string,
    contentType: string,
    endPointUrl: string
  ) {
    const body = JSON.stringify({
      content: dto.content,
    });
    const init: RequestInit = {
      method: method,
      body: body,
      headers: {
        "Content-Type": contentType,
      },
    };
    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((response) => response.json())
      .then((data: privacyPolicyDTO) => data)
      .catch((e: Error) => Promise.reject(e.message));
  }
}
