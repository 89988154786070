import React from "react";
import { UI_Size } from "./definitions";
import { DynamicHeroIcon, IconName } from "./DynamicHeroIcon";
import { translatedString } from "../translations/translations";
import { SpinnerLoading } from "./SpinnerLoading";

type buttonType = "primary" | "secondary" | "cancel" | "destroy";
type buttonSize = UI_Size;

export interface ButtonProps extends ButtonBaseProps {
  onClick: (e: any) => void;
}

interface ButtonBaseProps {
  label: translatedString;
  type?: buttonType;
  size?: buttonSize;
  disabled?: boolean;
  iconName?: IconName;
  loading?: boolean;
}

function colorsBasedOnProps(
  type: buttonType | undefined,
  enabled: boolean
): string {
  switch (type) {
    case "destroy":
      return "bg-red-500 border text-white" + (enabled && " hover:bg-red-600");
    case "secondary":
      return (
        "bg-gray-100 border text-secondary" + (enabled && " hover:bg-gray-200")
      );
    case "cancel":
      return (
        "bg-gray-100 border text-secondary" + (enabled && " hover:bg-gray-200")
      );
    default:
      return (
        "bg-primary-300 text-secondary font-bold" +
        (enabled && " hover:bg-primary-400")
      );
  }
}

export function ButtonBase(props: ButtonBaseProps) {
  return (
    <span
      className={
        " flex-grow-0 inline-flex items-center justify-center border border-transparent font-medium rounded-md " +
        colorsBasedOnProps(props.type, !props.disabled) +
        (props.size === "small"
          ? " text-sm px-2 py-1"
          : props.size === "tiny"
          ? " text-xs px-1 py-1 "
          : " text-base px-5 py-3") +
        (props.disabled ? " opacity-50 cursor-default" : "")
      }
    >
      {!!props.iconName &&
        (props.loading ? (
          <SpinnerLoading />
        ) : (
          <div className={`w-7 ${props.loading ? "animate-spin" : ""}`}>
            <DynamicHeroIcon
              icon={props.iconName}
              width={props.size === "small" ? "4" : "6"}
            />
          </div>
        ))}
      {props.label}
    </span>
  );
}

export function Button(props: ButtonProps) {
  return (
    <button onClick={props.onClick} disabled={props.disabled}>
      <ButtonBase
        label={props.label}
        disabled={props.disabled}
        size={props.size}
        type={props.type}
        iconName={props.iconName}
        loading={props.loading}
      />
    </button>
  );
}
