import { ContentRepository, Statuses } from "../commonTypes";
import { DateTime } from "luxon";

export type ExhibitionStatuses = Statuses;

export interface Exhibition {
  id: string;
  start: DateTime;
  end: DateTime;
  location: string;
  triggersNotification:boolean;
  title: string;
  url: string;
  publicationDate: DateTime;
  removalDate: DateTime;
  status: ExhibitionStatuses;
}

export interface ExhibitionCalendar
  extends ContentRepository<Exhibition, exhibitionDTO> {}

export interface exhibitionDTO extends Exhibition {}

export const EMPTY_EXHIBITION: Exhibition = {
  id: "",
  publicationDate: DateTime.local().startOf("day"),
  removalDate: DateTime.local().endOf("day"),
  triggersNotification:false,
  status: "draft",
  title: "",
  url: "",
  end: DateTime.local(),
  location: "",
  start: DateTime.local(),
};
