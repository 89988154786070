import {ContactMessage} from "./ContactMessages";
import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import {Modal} from "../../UIKit/Modal";
import {Button} from "../../UIKit/Button";

export function ContactMessagesActions(props: { message: ContactMessage }) {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            {modalVisible && (
                <Modal
                    onCancel={() => {
                    }}
                    onConfirm={() => {
                        setModalVisible(false);
                    }}
                    mainButtonLabel="OK"
                >
                    <p>{props.message.message}</p>
                </Modal>
            )}
            <Button
                size="small"
                iconName="EyeIcon"
                onClick={() => {
                    setModalVisible(true);
                }}
                label={"messages.read"}
            />
        </>
    );
}