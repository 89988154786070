import { createServer, Model } from "miragejs";
import { APP_CONFIG } from "./init";
import { createToken } from "./utilities/JWT";
import { News } from "./modules/News/News";
import { DateTime } from "luxon";
import { ModelDefinition, Registry } from "miragejs/-types";
import Schema from "miragejs/orm/schema";
import { v4 as uuid } from "uuid";

interface CatalogueAPI {
  "@context": string;
  "@id": string;
  "@type": string;
  id: string;
  filePath: string;
  format: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  title: string;
}
const inMemoryNews: News[] = [
  {
    headline:
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    id: "",
    triggersNotification: false,
    photo: "",
    publicationDate: DateTime.local(),
    removalDate: DateTime.local(),
    status: "draft",
    notificationStatus: "notify",
    title: "Lorem ipsum dolor sit amet",
    url: "https://www.dirbat.com",
  },
];

const CatalogueAPIModel: ModelDefinition<CatalogueAPI> = Model.extend({});
type DirBatRegistry = Registry<
  { catalogue: typeof CatalogueAPIModel },
  {
    /* factories can be defined here */
  }
>;
type DirBatSchema = Schema<DirBatRegistry>;

export function makeServer({ environment = "test" } = {}) {
  if (environment === "production") {
    return;
  }
  return createServer({
    models: {
      catalogue: Model,
    },
    environment,

    routes() {
      this.timing = 500;

      this.get(
        APP_CONFIG.API_BASE_URI + "/catalogues",
        (schema: DirBatSchema) => {
          return {
            "hydra:member": schema.all("catalogue").models,
            "hydra:totalItems": schema.all("catalogue").models.length,
            "hydra:view": {
              "@id": "string",
              "@type": "string",
              "hydra:first": "string",
              "hydra:last": "string",
              "hydra:next": "string",
            },
            "hydra:search": {
              "@type": "string",
              "hydra:template": "string",
              "hydra:variableRepresentation": "string",
              "hydra:mapping": [
                {
                  "@type": "string",
                  variable: "string",
                  property: "string",
                  required: true,
                },
              ],
            },
          };
        }
      );
      this.post(
        `${APP_CONFIG.API_BASE_URI}/suppliers/catalogues/import`,
        (schema: DirBatSchema, request) => {
          // @ts-ignore
          const catalogue = schema.create("catalogue", {
            "@context": "",
            "@id": "",
            "@type": "catalogue",
            id: uuid(),
            filePath: "",
            // @ts-ignore
            format: "request.requestBody.keys()",
            status: "pending",
            createdAt: DateTime.local().toISO(),
            updatedAt: DateTime.local().toISO(),
            title: "Import du " + DateTime.local().toISO(),
          });
          return { ...catalogue, format: request.params.format };
        }
      );
      this.get(APP_CONFIG.API_BASE_URI + "/news", () => {
        return {
          "hydra:member": inMemoryNews,
          "hydra:totalItems": inMemoryNews.length,
        };
      });
      this.get(APP_CONFIG.OAUTH_BASE_URI + "/authorize", () => {
        return { token: "code" };
      });
      this.get(APP_CONFIG.OAUTH_BASE_URI + "/tokenIsInvalid", () => {
        return { token: "code" };
      });
      this.post(APP_CONFIG.OAUTH_BASE_URI + "/token", () => {
        return { access_token: createToken() };
      });
    },
  });
}
