export class ConversionReportNotAvailable extends Error {
  private importId: string;

  constructor(importId: string) {
    super();
    this.importId = importId;
  }

  message = "Conversion report is not available";

  toString() {
    return this.message + ` for import ${this.importId}`;
  }
}
