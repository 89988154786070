import React from "react";
import {
  MetricCardContent,
  MetricCardNumber,
} from "../../UIKit/MetricCardNumber";
import {
  MetricCardChart,
  MetricCardChartData,
} from "../../UIKit/MetricCardChart";
import { MetricValue } from "./Metric";
import { MetricCardData, MetricCardSlider } from "../../UIKit/MetricCardSlider";

export interface MetricCardSelectorProps {
  title: string;
  values: MetricValue[];
  trendGood?: string;
  trendBad?: string;
  linkText?: string;
  destination?: string;
  hint?: string;
}

export function MetricCard(props: { content: MetricCardSelectorProps }) {
  if (
    props.content.title == "metrics.hits" ||
    props.content.title == "metrics.searches"
  ) {
    let data: MetricCardData[] = [];
    for (const v in props.content.values) {
      data.push({
        label: props.content.values[v].date,
        value: props.content.values[v].value,
      });
    }
    return (
      <MetricCardSlider
        title={props.content.title}
        data={data}
        hint={props.content.hint}
      />
    );
  }

  if (props.content.values.length > 1) {
    let metricprops: MetricCardChartData[] = [];

    props.content.values.sort((a, b) => (a.date > b.date ? 1 : -1));

    for (const v in props.content.values) {
      metricprops.push({
        date: props.content.values[v].date,
        value: parseInt(props.content.values[v].value),
      });
    }

    return <MetricCardChart data={metricprops} label={props.content.title} />;
  } else {
    const content: MetricCardContent = {
      title: props.content.title,
      currentValue: props.content.values[0],
      trendGood: props.content.trendGood,
      trendBad: props.content.trendBad,
      linkText: props.content.linkText,
      destination: props.content.destination,
    };
    return <MetricCardNumber content={content} />;
  }
}
