import { AppRoutes } from "../../app/Routes";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).stats;

const routes = (clientId: string, supplierId: string) => {
  return {
    metrics: base(clientId, supplierId),
  };
};

export default routes;
