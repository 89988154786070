export const AppRoutes = (clientId?: string, supplierId?: string): any => {
  return {
    authorizeCallback: "/authorize",
    documentation: `/suppliers/${supplierId}/apps/${clientId}/documentation`,
    catalogues: `/suppliers/${supplierId}/apps/${clientId}/catalogues`,
    login: `/suppliers/${supplierId}/apps/${clientId}/login`,
    apps: `/admin`,
    cms: `/suppliers/${supplierId}/apps/${clientId}/contentManagement`,
    community: `/suppliers/${supplierId}/apps/${clientId}/community`,
    metrics: `/suppliers/${supplierId}/apps/${clientId}/metrics`,
    news: `/suppliers/${supplierId}/apps/${clientId}/contentManagement/news`,
    productnews: `/suppliers/${supplierId}/apps/${clientId}/productnews`,
    videos: `/suppliers/${supplierId}/apps/${clientId}/contentManagement/videos`,
    exhibitions: `/suppliers/${supplierId}/apps/${clientId}/contentManagement/exhibitions`,
    meetups: `/suppliers/${supplierId}/apps/${clientId}/contentManagement/meetups`,
    home: `/`,
    appHome: `/suppliers/${supplierId}/apps/${clientId}`,
    logout: "/logout",
    settings: `/suppliers/${supplierId}/apps/${clientId}/settings`,
  };
};
export const TeamRoutes = (teamId?: string): any => {
  return {
    contributors: `/team/${teamId}/contributors`,
    apps: `/mobile_apps`,
  };
};
export const RoutesDev = {
  authorizeFake: "/oauth/authorize",
};
