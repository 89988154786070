import React from "react";

export function ProgressBar(props: { progress: number }) {
  return (
    <div className="items-center flex justify-center w-full">
      <div className="text-xs relative bg-gray-300 h-4 rounded w-full">
        <div
          className="bg-green-500 absolute rounded h-4"
          style={{ width: props.progress + "%" }}
        >
          &nbsp;
        </div>
        <div className="absolute text-center w-full h-4 text-white">
          {props.progress.toFixed(1)}%
        </div>
      </div>
    </div>
  );
}