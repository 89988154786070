import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { Badge } from "../../UIKit/Badge";
import {
  getBadgeType,
  getFlavorLabel,
  getPublicationDateLabel,
  getRemovalDateLabel,
  getStatusLabel,
  tableColumn,
} from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { BONotificationPayload } from "../../app/Notifications";
import { EMPTY_VIDEO, Video, videoDTO, VideoLibrary } from "./Video";
import { VideoLibraryAPI } from "./VideoLibraryAPI";
import { VideoForm } from "./VideoForm";
import { VideosList } from "./VideosList";
import { Schedulable } from "../News/News";

const emptyVideo: videoDTO = {
  headline: "",
  id: "",
  image: undefined,
  imagePath: "",
  triggersNotification: false,
  publicationDate: undefined,
  status: "draft",
  title: "",
  url: "",
};

export function VideosModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const auth = useAuth();
  const token = auth.user.token;
  const library = new VideoLibraryAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const intl = useIntl();
  const videosColumns: tableColumn<Video>[] = [
    {
      label: intl.formatMessage({
        id: "videos.title",
        defaultMessage: "Title",
      }),
      content: (video: Video) => video.title,
    },
    {
      label: intl.formatMessage({
        id: "schedulable.status",
        defaultMessage: "Status",
      }),
      content: (item: Schedulable) => (
        <Badge
          label={getStatusLabel(item, intl)}
          flavor={getFlavorLabel(item)}
          type={getBadgeType(item)}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.publication.date",
        defaultMessage: "Publication date",
      }),
      content: (item: Schedulable, locale) =>
        getPublicationDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.removal.date",
        defaultMessage: "Removal date",
      }),
      content: (item: Schedulable, locale) => getRemovalDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "videos.image",
        defaultMessage: "Image",
      }),
      align: "center",
      content: (video: Video) =>
        video.photo ? (
          <img
            alt={video.title}
            src={video.photo}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        ) : (
          ""
        ),
    },
    {
      label: intl.formatMessage({
        id: "videos.url",
        defaultMessage: "URL",
      }),
      content: (video: Video) => (
        <a href={video.url} target="_blank">
          {video.url.slice(0, 30)}...
        </a>
      ),
    },
  ];

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="videos.add.title"
            defaultMessage="Add a video"
          />
        </h1>
        <VideoForm
          library={library}
          awaitsValidation={true}
          data={emptyVideo}
          onSuccess={() => {
            history.push(ModuleRoutes(clientId, supplierId).videos);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="videos.edit.title"
            defaultMessage="Edit a video"
          />
        </h1>
        <EditVideo
          library={library}
          notify={props.notify}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").videos} exact>
        <VideosList
          library={library}
          columns={videosColumns}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
    </Switch>
  );
}

function EditVideo(props: {
  library: VideoLibrary;
  notify: (not: BONotificationPayload) => void;
  clientId: string;
  supplierId: string;
}) {
  const [video, setVideo] = useState<Video>(EMPTY_VIDEO);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setVideo(await props.library.findOneById(id)))();
  }, [id]);
  const history = useHistory();

  const dto: videoDTO = {
    headline: video.headline,
    id: video.id,
    image: undefined,
    imagePath: video.photo,
    triggersNotification: video.triggersNotification,
    publicationDate: video.publicationDate,
    removalDate: video.removalDate,
    status: video.status,
    title: video.title,
    url: video.url,
  };
  return (
    <VideoForm
      library={props.library}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(ModuleRoutes(props.clientId, props.supplierId).videos);
      }}
    />
  );
}
