import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { Button, ButtonBase } from "../../UIKit/Button";
import Routes from "./Routes";
import { Badge } from "../../UIKit/Badge";
import { PaginatedTable } from "../../UIKit/Table";
import {
  Catalogue,
  catalogueId,
  dirbatImportFormat,
  reportShouldBeAvailable,
} from "./Catalogue";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import {
  cataloguesPageResponse,
  CataloguesProvider,
} from "./CataloguesProvider";
import { Modal } from "../../UIKit/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { ClockIcon, CloudDownloadIcon } from "@heroicons/react/outline";
import { BadgeCheckIcon } from "@heroicons/react/solid";

interface CataloguesListProps {
  cataloguesProvider: CataloguesProvider;
  clientId: string;
  supplierId: string;
}

export function CataloguesList(props: CataloguesListProps) {
  const cataloguesPerPage = 30; // constrained by the API
  const history = useHistory();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [publicationModalIsOn, togglePublicationModal] = useState<boolean>(
    false
  );
  const intl = useIntl();
  const [
    currentCatalogueId,
    setCurrentCatalogueId,
  ] = useState<catalogueId | null>(null);
  const {
    isFetching: cataloguesAreFetching,
    data: cataloguePage,
  } = useQuery<cataloguesPageResponse>(
    "catalogue::all::page::" + startingAt,
    () => {
      return props.cataloguesProvider.findPaginated(
        (startingAt - 1) * cataloguesPerPage + 1,
        cataloguesPerPage,
        props.supplierId
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  useEffect(() => {
    //setCurrentCatalogueId(null)
  }, [publishCatalogue]);

  function loadCataloguesSlice(number: number) {
    setStartingAt(number);
  }

  function confirmPublication(catalogueId: catalogueId) {
    setCurrentCatalogueId(catalogueId);
    togglePublicationModal(true);
  }

  async function publishCatalogue(catalogueId: catalogueId) {
    togglePublicationModal(false);
    props.cataloguesProvider.publish(catalogueId, props.supplierId);
  }

  if (!cataloguePage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "catalogues.loading",
            defaultMessage: "Loading catalogs...",
          })}
        />
      </div>
    );
  } else {
    const catalogues = cataloguePage.items;
    const cataloguesStats = cataloguePage.stats;
    return (
      <>
        {catalogues.length === 0 && startingAt === 1 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="no.catalogue.yet"
                  defaultMessage="...no catalog at this time 🤷"
                />
              </p>
              <p>
                <FormattedMessage
                  id="no.catalogue.yet.invite"
                  defaultMessage="It's time to be a trendsetter! :-)"
                />
              </p>
              <div className="pt-2">
                <Button
                  label={"import.first.catalogue.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(
                      Routes(props.clientId, props.supplierId).addCatalogue
                    )
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            {publicationModalIsOn && currentCatalogueId && (
              <Modal
                onCancel={() => {
                  togglePublicationModal(false);
                }}
                onConfirm={() => publishCatalogue(currentCatalogueId)}
                altButtonLabel="cancel"
                mainButtonLabel="🚀 Publier"
              >
                <div>
                  <p className="font-bold text-xl">
                    <FormattedMessage
                      id="publish.catalogue.warning.title"
                      defaultMessage="Publish a catalog"
                    />
                  </p>
                  <p className="">
                    <FormattedMessage
                      id="publish.catalogue.warning.body"
                      defaultMessage="The publication of a catalog replaces the entire product data of your application."
                    />
                  </p>
                </div>
              </Modal>
            )}
            <div className="flex justify-between mb-4 ml-2 mt-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="catalogues.catalogues"
                  defaultMessage="Catalogs"
                />{" "}
                &gt;{" "}
                <FormattedMessage
                  id="catalogues.count.plural"
                  defaultMessage="{num, plural, one {# catalogue} other {# catalogues}}"
                  values={{ num: cataloguesStats.totalCount }}
                />
              </h2>
              {cataloguesAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "catalogues.checking.catalogues",
                      defaultMessage: "Checking new catalogs",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Catalogue>
              onPageChange={loadCataloguesSlice}
              totalItemsCount={cataloguesStats.totalCount || 0}
              itemsPerPage={cataloguesPerPage}
              data={catalogues}
              page={startingAt}
              columns={[
                {
                  label: "catalogues",
                  content: (c) => (
                    <span>
                      {c.title}
                      <div
                        onClick={() => {
                          props.cataloguesProvider.downloadFile(
                            c.id,
                            forgeFilename(c),
                            props.supplierId
                          );
                        }}
                        className="cursor-pointer text-xs"
                      >
                        <CloudDownloadIcon className="w-5 inline" />{" "}
                        {intl.formatMessage({
                          id: "download.my.file",
                          defaultMessage: "download my file",
                        })}
                      </div>
                    </span>
                  ),
                },
                {
                  label: intl.formatMessage({
                    id: "catalogues.format",
                    defaultMessage: "Format",
                  }),
                  content: (c) => (
                    <Badge
                      label={intl.formatMessage({
                        id: "catalogues.formats." + c.format,
                        defaultMessage: "Format",
                      })}
                    />
                  ),
                },
                {
                  label: "status",
                  content: (c) => (
                    <Badge
                      label={intl.formatMessage({
                        id: "catalogues.statuses." + c.status,
                      })}
                    />
                  ),
                },
                {
                  label: intl.formatMessage({
                    id: "catalogues.conversion.report",
                    defaultMessage: "Conversion report",
                  }),
                  content: (catalogue) => {
                    if (reportShouldBeAvailable(catalogue)) {
                      return (
                        <Link
                          to={Routes(
                            props.clientId,
                            props.supplierId
                          ).conversionReport(catalogue.id)}
                        >
                          <ButtonBase
                            size="tiny"
                            type="cancel"
                            label={intl.formatMessage({
                              id: "catalogues.display.report",
                              defaultMessage: "display the report",
                            })}
                          />
                        </Link>
                      );
                    }
                    return (
                      <span className="text-gray-400">
                        {intl.formatMessage({
                          id: "processing",
                          defaultMessage: "processing",
                        })}
                      </span>
                    );
                  },
                },
                {
                  label: intl.formatMessage({
                    id: "catalogues.publication.status",
                    defaultMessage: "Publication",
                  }),
                  content: (catalogue) => {
                    switch (catalogue.status) {
                      case "published":
                        return (
                          <div className="flex items-center justify-center">
                            <BadgeCheckIcon className="w-6 mr-2 text-green-400" />
                            <div>
                              {intl.formatMessage({
                                id: "published",
                                defaultMessage: "published",
                              })}
                            </div>
                          </div>
                        );
                      case "publishing":
                      case "pending_publication":
                        return (
                          <div className="flex items-center justify-center">
                            <ClockIcon className="w-6 mr-2 text-gray-400" />
                            <div>
                              {intl.formatMessage({
                                id: "pending.publication",
                                defaultMessage: "publishing",
                              })}
                            </div>
                          </div>
                        );
                      case "publishable":
                        return (
                          <Button
                            type="primary"
                            label={intl.formatMessage({
                              id: "publish",
                              defaultMessage: "publish",
                            })}
                            iconName="PaperAirplaneIcon"
                            onClick={() => confirmPublication(catalogue.id)}
                          />
                        );
                      case "converting":
                      case "pending_conversion":
                        return (
                          <div className="flex items-center justify-center">
                            <ClockIcon className="w-5 mr-2 text-gray-400" />
                            <div>
                              {intl.formatMessage({
                                id: "pendin.conversion",
                                defaultMessage: "conversion ongoing",
                              })}
                            </div>
                          </div>
                        );
                      case "publication_failed":
                        return (
                          <>
                            {intl.formatMessage({
                              id: "publication.error",
                              defaultMessage: "publication error",
                            })}
                          </>
                        );
                      case "unpublishable":
                        return (
                          <>
                            {intl.formatMessage({
                              id: "publication.not.publishable",
                              defaultMessage: "❌ not publishable",
                            })}
                          </>
                        );
                    }
                  },
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "catalogues.import.a.catalogue",
                    defaultMessage: "Import a products catalog",
                  })}
                  onClick={(_e) => {
                    history.push(
                      Routes(props.clientId, props.supplierId).addCatalogue
                    );
                  }}
                />
              </div>
            </div>
            <h4 className="text-xl bold">Statuses meanings :</h4>
            <ul>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.published",
                    defaultMessage: "published",
                  })}
                />
                <span className="ml-2">
                  this catalogue is the one that is currently displayed in your
                  app
                </span>
              </li>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.pending_conversion",
                    defaultMessage: "pending conversion",
                  })}
                />
                <span className="ml-2">
                  you just uploaded the excel file for this catalogue, it will
                  be processed within minutes
                </span>
              </li>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.publishable",
                    defaultMessage: "publishable",
                  })}
                />
                <span className="ml-2">
                  this catalogue has been processed successfully and is ready to
                  be published
                </span>
              </li>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.unpublishable",
                    defaultMessage: "❌ not publishable",
                  })}
                />
                <span className="ml-2">
                  this catalogue has been processed but led to unrecoverable
                  errors and cannot be published
                </span>
              </li>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.converting",
                    defaultMessage: "converting",
                  })}
                />
                <span className="ml-2">
                  this catalogue is currently being converted by DirBat to our
                  data format
                </span>
              </li>
              <li>
                <Badge
                  label={intl.formatMessage({
                    id: "catalogues.statuses.pending_publication",
                    defaultMessage: "pending publication",
                  })}
                />
                <span className="ml-2">
                  you requested to publish this catalogue, it will be online
                  within minutes
                </span>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

function fileFormat(format: dirbatImportFormat) {
  switch (format) {
    case "dirbat_xls":
      return "xlsx";
    case "dirbat_json":
      return "json";
  }
}

function forgeFilename(c: Catalogue) {
  return "dirbat_" + "catalogue_" + c.title + "." + fileFormat(c.format);
}
