import { TeamRoutes } from "../../app/Routes";

const base = (teamId: string) => TeamRoutes(teamId).contributors;

const routes = (teamId: string) => {
  return {
    contributors: base(teamId),
    add: `${base(teamId)}/add`,
    edit: (id: string) => `${base(teamId)}/edit/${id}`,
  };
};

export default routes;
