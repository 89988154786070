import { JWToken, JWTokenHeader, JWTokenPayload } from "../auth";

export function createToken(): JWToken {
  const header:JWTokenHeader = { alg: "none", typ: "" };
  const payload: JWTokenPayload = {
    "iss": "DirBatOAuth", // issuer
    "aud": "", // audience
    "sub": "", // subject
    "exp": "", // expire
    "nbf": "", //not before
    "iat": "", // issued At
    "jti": "", // JWT ID
    "name": "John Doe",
  };

  return [
    btoa(JSON.stringify(header)),
    btoa(JSON.stringify(payload)),
    "",
  ].join(".");
}
