import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { LoginForm } from "../../LoginForm";
import { AppRoutes } from "../../app/Routes";
import React from "react";

export function FakeLogin() {
  if (process.env.NODE_ENV === "development") {
    return (
      <CenterInScreen>
        <LoginForm
          onSubmit={(_login, _password) => {
            window.location.href =
              AppRoutes().authorizeCallback + "?code=FAKE_AUTHORIZATION_CODE";
            return null;
          }}
        />
      </CenterInScreen>
    );
  }
  window.location.href = AppRoutes().home;
  return null;
}
