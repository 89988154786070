import React, { ReactElement } from "react";
import { createPortal } from "react-dom";

export function Portal(props: {
  children: ReactElement;
  className: string;
  el: string;
}) {
  const [container] = React.useState(document.createElement(props.el));

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(props.children, container);
}
