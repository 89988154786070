import React from "react";
import { SpinnerLoading } from "./SpinnerLoading";

export function Loading(props: { label?: string }) {
  return (
    <div className="flex flex-row items-center ">
      <SpinnerLoading />
      <span className="ml-2 text-gray-500">
        {props.label || "search.in.progress"}
      </span>
    </div>
  );
}
