import React from "react";
import { validableFormWidgetProps } from "./forms";

export function FormStepTitle(
  props: validableFormWidgetProps & { label: string; step: number }
) {
  const validationClass =
    !props.awaitsValidation && !props.isValid ? " text-red-500 " : "  ";
  return (
    <div className="flex flex-row justify-start items-center mb-4">
      <span className="rounded-full text-white bg-primary-400 w-10 h-10 mr-4 inline-block flex items-center justify-center font-bold text-2xl">
        {props.step}
      </span>
      <h3 className={"font-bold text-xl" + validationClass}>
        {props.label} {props.isValid ? "" : <>&#x2731;</>}
      </h3>
    </div>
  );
}
