import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export interface ToggleProps {
  label: string;
  toggled?: boolean;
  onToggle: (newState: boolean) => void;
}

export const Toggle = ({ label, toggled, onToggle }: ToggleProps) => {
  const [toggledState, setToggledState] = useState(!!toggled);

  useEffect(() => {
    setToggledState(!!toggled);
  }, [toggled]);

  const clicked = () => {
    setToggledState(!toggledState);
    onToggle(!toggledState);
  };

  return (
    <div className="flex items-center justify-start bg-gray-100 text-base p-4 rounded-md h-16">
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className={`${
          toggledState ? "bg-primary-600" : "bg-gray-200"
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
        onClick={() => {
          clicked();
        }}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${
            toggledState ? "translate-x-5" : "translate-x-0"
          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        />
      </button>
      <span
        onClick={() => {
          clicked();
        }}
        className={` font-medium ${
          toggled ? "text-black" : "text-gray-500"
        } ml-2 hover:text-black `}
      >
        <FormattedMessage id={label} defaultMessage={label as string} />
      </span>
    </div>
  );
};
