import React, { useState } from "react";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { FormattedMessage, useIntl } from "react-intl";
import { contentPageResponse } from "../commonTypes";
import { Metric, MetricsProvider } from "./Metric";
import { MetricCard } from "./MetricCard";

interface MetricsListProps {
  provider: MetricsProvider;
}

export function MetricsList(props: MetricsListProps) {
  const metricsPerPage = 20;
  const [startingAt, setStartingAt] = useState<number>(1);
  const { data: metricsPage } = useQuery<contentPageResponse<Metric>>(
    "metrics::all::page::" + startingAt,
    () => {
      return props.provider.findPaginated(
        (startingAt - 1) * metricsPerPage + 1,
        metricsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );
  const intl = useIntl();

  if (!metricsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "metrics.checking.metrics",
            defaultMessage: "Loading metrics",
          })}
        />
      </div>
    );
  } else {
    const metrics = metricsPage.items;
    return (
      <>
        {metrics.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="metrics.no.metrics.yet"
                  defaultMessage="No numbers yet, they are coming!"
                />
              </p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="grid grid-cols-3 gap-4">
              {metrics.map((m) => (
                <MetricCard
                  key={m.name}
                  content={{ values: m.values, title: m.name, hint: m.hint }}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
