import React from "react";
import Routes from "./Routes";
import { CTACard, CTACardContent } from "../../UIKit/CTACard";
import { FormattedMessage, useIntl } from "react-intl";

export function CataloguesDashboard(props: {
  clientId: string;
  supplierId: string;
}) {
  const intl = useIntl();
  const cardsContent: CTACardContent[] = [
    {
      title: intl.formatMessage({
        id: "catalogues.catalogues",
        defaultMessage: "Catalogs",
      }),
      icon: "CollectionIcon",
      text: intl.formatMessage({
        id: "catalogues.dashboard.catalogues.subtitle",
        defaultMessage:
          "Import all informations about your products, to make it available in your app.",
      }),
      destination: Routes(props.clientId, props.supplierId).catalogues,
      buttonText: intl.formatMessage({
        id: "dashboard.catalogues.cta",
        defaultMessage: "Manage your catalogs",
      }),
      neededAbilities: [
        {
          action: "list",
          subject: "catalogues",
          scope: { id: props.clientId },
        },
      ],
    },
    {
      title: "dealers",
      icon: "GlobeAltIcon",
      text: intl.formatMessage({
        id: "catalogues.dashboard.distributors.text",
        defaultMessage: "Manage dealers and their data",
      }),
      destination: Routes(props.clientId, props.supplierId).dealersImports,
      buttonText: intl.formatMessage({
        id: "catalogues.dashboard.distributors.cta",
        defaultMessage: "Manage dealers",
      }),
      neededAbilities: [
        { action: "list", subject: "dealers", scope: { id: props.clientId } },
      ],
    },
  ];
  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">
          <FormattedMessage
            id={"catalogue.product.and.dealer.title"}
            defaultMessage={"Product catalogs and dealers"}
          />
        </h2>
        <p className="text-2xl">
          <FormattedMessage
            id={"catalogue.product.and.dealer.subtitle"}
            defaultMessage={
              "All information on your products and how to find these."
            }
          />
        </p>
        <hr className="mt-6 mb-6" />
        <div className="grid grid-cols-3 gap-4">
          {cardsContent.map((card) => (
            <CTACard content={card} key={card.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
