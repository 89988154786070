import React from "react";
import { useQuery } from "react-query";
import { SettingsDirectory } from "./SettingsDirectoryAPI";
import { BONotificationPayload } from "../../app/Notifications";
import { AppsConfigurationsForm } from "./AppsConfigurationsForm";

export interface AdminAppsConfigurations {
  settingsDirectory: SettingsDirectory;
  notify: (notification: BONotificationPayload) => void;
}

export function SupplierAppsConfigurations(props: AdminAppsConfigurations) {
  const {
    isFetching: listsAreFetching,
    data: sectionedAppConfig,
    refetch,
  } = useQuery("sectionnedconfig::getconfig", () =>
    props.settingsDirectory.getSettings().catch(() => {
      props.notify({
        message: "Error fetching app configurations",
        type: "error",
      });
    })
  );

  const submit = (feature: string, values: string[]) => {
    props.settingsDirectory
      .setSettings(feature, values)
      .then(() => {
        props.notify({
          message: "Configuration updated",
          type: "success",
        });
        refetch();
      })
      .catch((e) => {
        console.log(e);
        props.notify({
          message: e.message,
          type: "error",
        });
      });
  };

  return (
    <AppsConfigurationsForm
      listsAreFetching={listsAreFetching}
      sectionedAppConfig={sectionedAppConfig}
      submit={submit}
      notify={props.notify}
    />
  );
}
