import { BONotification } from "../app/Notifications";
import { FormattedMessage } from "react-intl";
import { XIcon } from "@heroicons/react/solid";
import React, { ReactElement } from "react";
import { Portal } from "./Portal";

export interface ToasterProps {
  children?: ReactElement;
  notifications: BONotification[];
  onRemove: (notification: BONotification) => void;
}

export function Toaster(props: ToasterProps) {
  return (
    <>
      <Portal className="notification-root" el="div">
        <div className="fixed top-0 z-50 lg:w-3/12 sm:w-1/2 w-full right-0">
          <ToasterList
            notifications={props.notifications}
            onRemove={props.onRemove}
          />
        </div>
      </Portal>
      {props.children}
    </>
  );
}

function ToasterList(props: {
  notifications: BONotification[];
  onRemove: (notification: BONotification) => void;
}) {
  function removeNotification(toRemove: BONotification) {
    props.onRemove(toRemove);
  }

  return (
    <div>
      {props.notifications.map((n) => (
        <Toast
          key={n.id}
          notification={n}
          onRemove={() => removeNotification(n)}
        />
      ))}
    </div>
  );
}

export function Toast(props: {
  notification: BONotification;
  onRemove: () => void;
}) {
  return (
    /* purgecss: border-green-500 border-red-500 */
    <div
      className={
        "flex items-center max-h-24 overflow-scroll bg-gray-50 shadow-lg p-4 rounded m-4 relative border-b-4 " +
        " border-" +
        getColorFromType(props.notification.type) +
        "-500"
      }
    >
      <div className="">
        <FormattedMessage id={props.notification.message} />
      </div>
      <div
        className="absolute right-1 top-1 w-4 p-0.5 bg-gray-200 shadow-sm cursor-pointer"
        onClick={() => {
          props.onRemove();
        }}
      >
        <XIcon />
      </div>
    </div>
  );
}

function getColorFromType(type: string) {
  switch (type) {
    case "error":
      return "red";
    case "success":
      return "green";
  }
}
