import React from "react";

export type BadgeType = "primary" | "secondary" | "ok" | "ko";
export type BadgeFlavor = "outlined" | "light" | "filled";

export interface BadgeProps {
  label: string;
  type?: BadgeType;
  flavor?: BadgeFlavor;
}

export function Badge(props: BadgeProps) {
  let classes = "";
  const type = getColorFromType(props.type || "primary");
  switch (props.flavor) {
    case "filled":
      classes += ` bg-${type}-400 text-white border border-${type}-400 `;
      break;
    case "outlined":
      classes += ` bg-white text-${type}-600 border border-${type}-600 `;
      break;
    case "light":
      classes += ` bg-${type}-100 text-${type}-600 border border-${type}-100 `;
      break;
  }
  return (
    <span
      className={
        " px-2 text-xs leading-5 font-semibold rounded-full inline-flex ml-2 whitespace-nowrap " +
        classes
      }
    >
      {props.label}
    </span>
  );
}

function getColorFromType(type: BadgeType) {
  switch (type) {
    case "primary":
    case "secondary":
      return type;
    case "ok":
      return "green";
    case "ko":
      return "red";
  }
}

// These dynamic classes are generated, we leave them here to avoid their purge by purgeCSS
// bg-red-100 bg-red-400 bg-red-600
// border-red-100 border-red-400 border-red-600
// bg-secondary-100 bg-secondary-400 bg-secondary-600
// border-secondary-100 border-secondary-400 border-secondary-600
// bg-primary-100 bg-primary-400 bg-primary-600
// border-primary-100 border-primary-400 border-primary-600
// bg-green-100 bg-green-400 bg-green-600
// border-green-100 border-green-400 border-green-600

Badge.defaultProps = { flavor: "filled", type: "primary" };
