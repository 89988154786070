import React from "react";
import { ResolverConfigurationForm } from "./ResolverConfigurationForm";
import { appConfiguration } from "../Apps/Application";
import { ConfigurationFormType } from "./AppsConfigurationsForm";

export interface SectionConfigurationsFormProps {
  sectionTitle: string;
  sectionDescription: string;
  configurations: appConfiguration[];
  onSubmit: (feature: string, values: string[]) => void;
  showcase?: React.ReactNode;
}

export interface Configuration {
  key: string;
  type: ConfigurationFormType;
  values: string[];
}

export function SectionConfigurationsForm(
  props: SectionConfigurationsFormProps
) {
  return (
    <div className="bg-white shadow-md border overflow-hidden sm:rounded-lg">
      <div className="px-3 py-4 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {props.sectionTitle}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{props.sectionDescription}</p>
      </div>
      <div className="border-t border-gray-200">
        {props.configurations.map((config) => (
          <div className="m-2">
            <ResolverConfigurationForm
              configuration={config}
              onSubmit={(feature, values) => {
                props.onSubmit(feature, values);
              }}
            />
          </div>
        ))}
      </div>
      <div className="m-2">{!!props.showcase && props.showcase}</div>
    </div>
  );
}
