import { News, NewsJournal, newsPageResponse } from "./News";
import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { newsDTO } from "./NewsForm";
import { handleErrors } from "../../app/APIError";
import { dirbatLocale } from "../../translations/translations";

function getNews(newsData: []): News[] {
  return newsData.map((nd: any) => mapNewsFromApi(nd));
}

function mapNewsFromApi(nd: any): News {
  return {
    headline: nd.headline,
    id: nd.id,
    triggersNotification: nd.triggersNotification,
    photo: nd.photo,
    publicationDate: DateTime.fromISO(nd.publishedAt),
    removalDate: DateTime.fromISO(nd.unpublishedAt),
    status: nd.status,
    notificationStatus: nd.notificationStatus,
    title: nd.title,
    url: nd.url,
  };
}

export class NewsJournalAPI extends APIClient implements NewsJournal {
  constructor(
    token: string,
    clientId: string,
    private supplierId: string,
    locale: dirbatLocale
  ) {
    super(token, locale);
  }
  findPaginated(
    firstIdx: number,
    newsPerPage: number
  ): Promise<newsPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/news?page=${
        Math.floor(firstIdx / newsPerPage) + 1
      }&order[publishedAt]=desc`
    )
      .then((response) => response.json())
      .then((newsData) => {
        return {
          items: getNews(newsData["hydra:member"]),
          stats: { totalCount: newsData["hydra:totalItems"] },
        };
      });
  }

  findOneById(id: string): Promise<News> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/news/${id}`
    )
      .then((response) => response.json())
      .then((newsData): News => mapNewsFromApi(newsData));
  }

  delete(news: News): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/news/${news.id}`,
      {
        method: "delete",
      }
    )
      .then((response) => {
        return response.status === 204;
      })
      .then((success): boolean => success);
  }

  save(dto: newsDTO): Promise<News> {
    const method = dto.image ? "POST" : "PATCH";
    const endPointUrl = dto.id
      ? `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/news/${dto.id}`
      : `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/news`;
    let contentType;
    let body;
    if (dto.image === undefined) {
      contentType = "application/merge-patch+json";
      body = JSON.stringify({
        url: dto.url,
        title: dto.title,
        headline: dto.headline,
        triggersNotification: dto.triggersNotification,
        publishedAt: dto.publicationDate?.startOf("day").toUTC().toISO(),
        unpublishedAt: dto.removalDate?.endOf("day").toUTC().toISO(),
        status: dto.status,
      });
    } else {
      contentType = "application/x-www-form-urlencoded";
      body = new FormData();
      body.append("url", dto.url);
      body.append("photoFile", dto.image);
      body.append("headline", dto.headline);
      body.append(
        "triggersNotification",
        dto.triggersNotification ? "true" : ""
      );
      body.append(
        "publishedAt",
        dto.publicationDate!.startOf("day").toUTC().toISO()
      );
      body.append("title", dto.title);
      body.append("status", dto.status);
      dto.removalDate &&
        body.append(
          "unpublishedAt",
          dto.removalDate.endOf("day").toUTC().toISO()
        );
    }

    let init: RequestInit = {
      method: method,
      body: body,
    };
    dto.image === undefined
      ? (init.headers = {
          "Content-Type": contentType,
        })
      : false;
    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((newsData) => {
        return mapNewsFromApi(newsData);
      })
      .catch((e: Error) =>
        Promise.reject(JSON.parse(e.message)["hydra:description"])
      );
  }
}
