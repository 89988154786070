import React from "react";
import Routes from "./Routes";
import { CTACard, CTACardContent } from "../../UIKit/CTACard";
import { useIntl } from "react-intl";

export function CommunityDashboard(props: {
  clientId: string;
  supplierId: string;
}) {
  const intl = useIntl();
  const cardsContent: CTACardContent[] = [
    {
      title: intl.formatMessage({
        id: "community.contractors",
        defaultMessage: "Pros",
      }),
      text: intl.formatMessage({
        id: "community.dashboard.users.text",
        defaultMessage:
          "Téléchargez les données acquises par votre application à propos des utilisateurs.",
      }),
      destination: Routes(props.clientId, props.supplierId).contractors,
      buttonText: intl.formatMessage({
        id: "community.dashboard.users.cta",
        defaultMessage: "All user data",
      }),
      neededAbilities: [
        { action: "list", subject: "community", scope: { id: props.clientId } },
      ],
    },
    {
      title: intl.formatMessage({
        id: "community.offers",
        defaultMessage: "Announcements of colleagues",
      }),
      text: intl.formatMessage({
        id: "community.dashboard.offers.text",
        defaultMessage:
          "Visualisez et modérez les annonces publiées par les utilisateurs",
      }),
      destination: Routes(props.clientId, props.supplierId).offers,
      buttonText: intl.formatMessage({
        id: "community.dashboard.offers.cta",
        defaultMessage: "Moderation of announcements",
      }),
      neededAbilities: [
        { action: "list", subject: "community", scope: { id: props.clientId } },
      ],
    },
    {
      title: intl.formatMessage({
        id: "community.contacts",
        defaultMessage: "Moderation of announcements",
      }),
      text: intl.formatMessage({
        id: "community.dashboard.contacts.text",
        defaultMessage:
          "Accédez à l'historique des messages ayant été envoyés à vos services par les utilisateurs de l'application",
      }),
      destination: Routes(props.clientId, props.supplierId).contacts,
      buttonText: intl.formatMessage({
        id: "community.dashboard.contacts.cta",
        defaultMessage: "All incoming messages",
      }),
      neededAbilities: [
        { action: "list", subject: "community", scope: { id: props.clientId } },
      ],
    },
  ];
  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">Ma communauté</h2>
        <p className="text-2xl">
          Animez la communauté des pros qui utilisent votre application.
        </p>
        <hr className="mt-6 mb-6" />
        <div className="grid grid-cols-3 gap-4">
          {cardsContent.map((card) => (
            <CTACard content={card} key={card.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
