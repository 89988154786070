import { AppRoutes } from "../../app/Routes";
import RoutesDoc from "../../modules/Documentation/Routes";
import { catalogueId } from "./Catalogue";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).catalogues;

const routes = (clientId: string, supplierId: string) => {
  return {
    dashboard: base(clientId, supplierId),
    ...RoutesDoc,
    dealersImports: `${base(clientId, supplierId)}/dealers/imports`,
    dealersList: `${base(clientId, supplierId)}/dealers/list`,
    addDealersImport: `${base(clientId, supplierId)}/dealers/add`,
    dealersConversionReport(dealersImportId: string) {
      return `${base(clientId, supplierId)}/dealers/imports/${dealersImportId}`;
    },

    addCatalogue: `${base(clientId, supplierId)}/catalogues/add`,
    conversionReport: (id: catalogueId) => {
      return `${base(clientId, supplierId)}/catalogues/report/${id}`;
    },
    download: (id: catalogueId) => {
      return `${base(clientId, supplierId)}/catalogues/download/${id}`;
    },
    catalogues: `${base(clientId, supplierId)}/catalogues`,
  };
};

export default routes;
