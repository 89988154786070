import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { NewsList } from "./NewsList";
import { NewsJournalAPI } from "./NewsJournalAPI";
import { EMPTY_NEWS, News, NewsJournal, Schedulable } from "./News";
import { Badge } from "../../UIKit/Badge";
import { DateTime } from "luxon";
import { newsDTO, NewsForm } from "./NewsForm";
import {
  getBadgeType,
  getFlavorLabel,
  getPublicationDateLabel,
  getRemovalDateLabel,
  getStatusLabel,
  tableColumn,
} from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { BONotificationPayload } from "../../app/Notifications";

const newsArticle: newsDTO = {
  headline: "",
  id: "",
  image: undefined,
  triggersNotification: false,
  imagePath: "",
  publicationDate: DateTime.local().startOf("day"),
  removalDate: DateTime.local().endOf("day"),
  status: "draft",
  notificationStatus: "notify",
  title: "",
  url: "",
};

export function NewsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const intl = useIntl();
  const journal = new NewsJournalAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const newsColumns: tableColumn<News>[] = [
    {
      label: intl.formatMessage({
        id: "news.title",
        defaultMessage: "Title",
      }),

      content: (news: News) => news.title,
    },
    {
      label: intl.formatMessage({
        id: "schedulable.status",
        defaultMessage: "Status",
      }),
      content: (item: Schedulable) => (
        <Badge
          label={getStatusLabel(item, intl)}
          flavor={getFlavorLabel(item)}
          type={getBadgeType(item)}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.publication.date",
        defaultMessage: "Publication date",
      }),
      content: (item: Schedulable, locale) =>
        getPublicationDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.removal.date",
        defaultMessage: "Removal date",
      }),
      content: (item: Schedulable, locale) => getRemovalDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "news.image",
        defaultMessage: "Image",
      }),
      align: "center",
      content: (news: News) =>
        news.photo ? (
          <img
            alt={news.title}
            src={news.photo}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        ) : (
          ""
        ),
    },
    {
      label: intl.formatMessage({
        id: "news.url",
        defaultMessage: "Destination URL",
      }),
      content: (news: News) => {
        return (
          <a href={news.url !== undefined ? news.url : "#"} target="_blank">
            {news.url.slice(0, 30)}...
          </a>
        );
      },
    },
  ];

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage id="news.add.title" defaultMessage="Add a news" />
        </h1>
        <NewsForm
          journal={journal}
          awaitsValidation={true}
          data={newsArticle}
          onSuccess={() => {
            history.push(ModuleRoutes(clientId, supplierId).productnews);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage id="news.edit.title" defaultMessage="Edit news" />
        </h1>
        <EditNews
          journal={journal}
          notify={props.notify}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").productnews} exact>
        <NewsList
          journal={journal}
          columns={newsColumns}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
    </Switch>
  );
}

function EditNews(props: {
  journal: NewsJournal;
  notify: (not: BONotificationPayload) => void;
  clientId: string;
  supplierId: string;
}) {
  const [news, setNews] = useState<News>(EMPTY_NEWS);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setNews(await props.journal.findOneById(id)))();
  }, [id]);
  const history = useHistory();

  const dto: newsDTO = {
    headline: news.headline,
    id: news.id,
    image: undefined,
    triggersNotification: news.triggersNotification,
    imagePath: news.photo,
    publicationDate: news.publicationDate,
    removalDate: news.removalDate,
    status: news.status,
    notificationStatus: news.notificationStatus,
    title: news.title,
    url: news.url,
  };
  return (
    <NewsForm
      journal={props.journal}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(
          ModuleRoutes(props.clientId, props.supplierId).productnews
        );
      }}
    />
  );
}
