import React from "react";
import { DynamicHeroIcon } from "./DynamicHeroIcon";
import { FormattedMessage } from "react-intl";

export interface MetricCardSliderProps {
  title: string;
  hint?: string;
  data: MetricCardData[];
}

export interface MetricCardData {
  label: string;
  value?: string;
}

export function MetricCardSlider(props: MetricCardSliderProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(0);

  const forgeStyle = (index: number) => {
    if (index === currentIndex) {
      if (index > lastIndex) {
        return "relative animate-cardInR";
      }
      return "relative animate-cardInL";
    } else if (index + 1 === currentIndex) {
      if (lastIndex > currentIndex) {
        return "absolute hidden";
      }
      return "absolute animate-cardOutL";
    } else if (index < currentIndex) {
      return "absolute animate-cardOutL hidden";
    } else {
      return "absolute animate-cardOutR";
    }
  };

  return (
    <div className="rounded-lg bg-gray-50 overflow-hidden">
      <div className="flex flex-col px-4 py-2">
        <dt className="text-2xl flex justify-between items-center">
          <div className="text-2xl font-normal">
            <FormattedMessage id={props.title} />
          </div>
          <div className="flex right-7 top-7 gap-2">
            <button
              className="bg-primary-100 hover:bg-primary-300 text-white rounded-full"
              onClick={() => {
                if (currentIndex > 0) {
                  setLastIndex(currentIndex);
                  setCurrentIndex(currentIndex - 1);
                }
              }}
            >
              <DynamicHeroIcon icon={"ArrowCircleLeftIcon"} width={"7"} />
            </button>
            <button
              className="bg-primary-100 hover:bg-primary-300 text-white rounded-full"
              onClick={() => {
                if (currentIndex < props.data.length - 1) {
                  setLastIndex(currentIndex);
                  setCurrentIndex(currentIndex + 1);
                }
              }}
            >
              <DynamicHeroIcon icon={"ArrowCircleRightIcon"} width={"7"} />
            </button>
          </div>
        </dt>
        <dd>
          <div className={"flex text-6xl font-bold"}>
            {props.data.map((value, index) => (
              <div
                key={index}
                className={`${forgeStyle(index)} items-center flex my-4`}
              >
                {!!value.value && (
                  <span className="bg-gray-200 inline-block p-4 text-4xl text-center rounded-full mr-4">
                    {value.value}x
                  </span>
                )}
                <span>{value.label}</span>
              </div>
            ))}
          </div>
        </dd>
        <div className="text-right italic text-gray-400">
          <FormattedMessage id={props.hint} />
        </div>
      </div>
    </div>
  );
}
