import React from "react";
import { DynamicHeroIcon, IconName } from "./DynamicHeroIcon";

export function TriCard(props: {
  label: string;
  value?: string;
  iconName?: IconName;
}) {
  return (
    <div className="rounded shadow flex flex-row my-4 justify-between">
      <div className=" rounded-l flex items-center p-4">
        {!!props.iconName && (
          <div className="w-12 mr-3 text-primary-400">
            <DynamicHeroIcon icon={props.iconName} width="12" />
          </div>
        )}
        <div className={"" + (!props.value ? " rounded-r " : "")}>
          {props.label}
        </div>
      </div>
      {props.value && (
        <div className="font-bold p-4 rounded-r text-4xl flex items-center justify-center bg-gray-100 w-1/3">
          {props.value}
        </div>
      )}
    </div>
  );
}
