import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { AppRoutes, RoutesDev, TeamRoutes } from "./app/Routes";
import { CataloguesModule } from "./modules/Catalogues/CataloguesModule";

import { QueryClient, QueryClientProvider } from "react-query";
import { Header } from "./UIKit/Header";
import {
  ADMIN_APPLICATION,
  ApplicationContext,
  AuthClient,
  AuthHandler,
  getPkceFromStorage,
  PKCE,
  ProvideAuth,
  useAuth,
} from "./auth";
import { APP_CONFIG } from "./init";
import getPkce from "oauth-pkce";
import { FakeLogin } from "./tests/UI/FakeLogin";
import { NewsModule } from "./modules/News/NewsModule";
import { DocumentationModule } from "./modules/Documentation/DocumentationModule";
import { IntlProvider } from "react-intl";
import { translationsForUsersLocale as frenchTranslations } from "./translations/french";
import { translationsForUsersLocale as englishTranslations } from "./translations/english";
import {
  BONotification,
  BONotificationPayload,
  notificationAutoremoveDelay,
} from "./app/Notifications";
import { VideosModule } from "./modules/Videos/VideosModule";
import { ExhibitionsModule } from "./modules/Exhibitions/ExhibitionsModule";
import { ContributorsModule } from "./modules/Contributors/ContributorsModule";
import { SettingsModule } from "./modules/Settings/SettingsModule";
import { MeetupsModule } from "./modules/Meetups/MeetupsModule";
import { ProductNewsModule } from "./modules/ProductNews/ProductNewsModule";
import { DashboardCMS } from "./modules/DashboardCMS";
import { Dashboard } from "./modules/Dashboard";
import { MetricsModule } from "./modules/Metrics/MetricsModule";
import { CommunityModule } from "./modules/Community/CommunityModule";
import { suppliersMenu } from "./mainMenu";
import { AppsModule } from "./modules/Apps/AppsModule";
import { v4 } from "uuid";
import { dirbatLocale } from "./translations/translations";
import { Toaster } from "./UIKit/Toaster";
import { Application } from "./modules/Apps/Application";
import { AppsDirectoryAPI } from "./modules/Apps/AppsDirectoryAPI";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      useErrorBoundary: true,
    },
  },
});

const APIAuthorizationClient: AuthClient = {
  retrieveToken: (code: string, codeVerifier: string) => {
    return fetch(APP_CONFIG.OAUTH_BASE_URI + "/token", {
      method: "POST",
      body: `grant_type=authorization_code&client_id=${APP_CONFIG.OAUTH_CLIENT_ID}&code=${code}&code_verifier=${codeVerifier}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((r) => r.access_token);
  },
  authorizeURL: (pkce: PKCE) => {
    return (
      `${APP_CONFIG.OAUTH_BASE_URI}/authorize?client_id=${APP_CONFIG.OAUTH_CLIENT_ID}&response_type=code&code_challenge_method=S256&code_challenge=` +
      pkce.challenge
    );
  },
  logoutURL(): string {
    return `${APP_CONFIG.OAUTH_BASE_URI}/logout`;
  },
};

type translationsCatalogue = { [id: string]: string };

const allMessages: { [id: string]: translationsCatalogue } = {
  fr: frenchTranslations,
  en: englishTranslations,
};

function App() {
  const [notifications, setNotifications] = useState<BONotification[]>([]);
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState<translationsCatalogue>(
    allMessages[locale]
  );
  useEffect(() => {
    setMessages(allMessages[locale]);
  }, [locale]);

  function updateNotifications(nots: BONotification[]) {
    setNotifications(nots);
  }

  function removeNotification(not: BONotification) {
    setNotifications((notifications) =>
      notifications.filter((n) => {
        return n.id !== not.id;
      })
    );
  }

  function notify(notif: BONotificationPayload) {
    const notification = { id: v4(), message: notif.message, type: notif.type };
    updateNotifications([...notifications, notification]);
    setTimeout(() => {
      removeNotification(notification);
    }, notificationAutoremoveDelay);
  }

  const pkce: PKCE = getPkceFromStorage();

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} messages={messages}>
        <Router>
          <ErrorBoundary>
            <ProvideAuth>
              <Toaster
                notifications={notifications}
                onRemove={removeNotification}
              >
                <Switch>
                  <Route
                    path={AppRoutes().logout}
                    component={() => {
                      const storage = window.sessionStorage;
                      storage.removeItem("code_verifier");
                      storage.removeItem("code_challenge");
                      storage.removeItem("route_requested");
                      window.location.href = APIAuthorizationClient.logoutURL();
                      return null;
                    }}
                  />
                  <Route
                    path={AppRoutes().login}
                    component={() => {
                      const storage = window.sessionStorage;
                      getPkce(50, (error, { verifier, challenge }) => {
                        storage.setItem("code_verifier", verifier);
                        storage.setItem("code_challenge", challenge);
                        window.location.href = APIAuthorizationClient.authorizeURL(
                          getPkceFromStorage()
                        );
                      });
                      return null;
                    }}
                  />
                  <Route path={AppRoutes().authorizeCallback}>
                    <AuthHandler
                      codeVerifier={pkce.verifier}
                      authClient={APIAuthorizationClient}
                    />
                  </Route>
                  <Route path={RoutesDev.authorizeFake}>
                    <FakeLogin />
                  </Route>
                  <BackOffice
                    setLocale={setLocale}
                    notify={notify}
                  ></BackOffice>
                </Switch>
              </Toaster>
            </ProvideAuth>
          </ErrorBoundary>
        </Router>
      </IntlProvider>
    </QueryClientProvider>
  );
}

function BackOffice(props: {
  setLocale: (locale: string) => void;
  notify: (notif: BONotificationPayload) => void;
}) {
  return (
    <>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").catalogues}
        setLocale={props.setLocale}
      >
        <CataloguesModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").news}
        setLocale={props.setLocale}
      >
        <NewsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").videos}
        setLocale={props.setLocale}
      >
        <VideosModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={TeamRoutes(":teamId").apps}
        setLocale={props.setLocale}
      >
        <AppsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").exhibitions}
        setLocale={props.setLocale}
      >
        <ExhibitionsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").meetups}
        setLocale={props.setLocale}
      >
        <MeetupsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").productnews}
        setLocale={props.setLocale}
      >
        <ProductNewsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").cms}
        exact={true}
        setLocale={props.setLocale}
      >
        <DashboardCMS />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").community}
        setLocale={props.setLocale}
      >
        <CommunityModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").settings}
        setLocale={props.setLocale}
      >
        <SettingsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={TeamRoutes(":teamId").contributors}
        setLocale={props.setLocale}
      >
        <ContributorsModule notify={props.notify} />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").documentation}
        setLocale={props.setLocale}
      >
        <DocumentationModule />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").metrics}
        setLocale={props.setLocale}
      >
        <MetricsModule />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").appHome}
        exact={true}
        setLocale={props.setLocale}
      >
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute
        path={AppRoutes(":clientId", ":supplierId").home}
        exact={true}
        setLocale={props.setLocale}
      >
        <Home />
      </PrivateRoute>
    </>
  );
}

function Home() {
  const auth = useAuth();
  return (
    <Redirect
      to={{
        pathname: TeamRoutes(auth.user.teamId).apps,
      }}
    />
  );
}

function PrivateRoute(props: {
  children: JSX.Element;
  path: string;
  exact?: boolean;
  setLocale: (locale: dirbatLocale) => void;
}) {
  let auth = useAuth();
  useEffect(() => {
    props.setLocale(auth.user.locale);
  }, [auth.user.locale]);
  if (!auth.user) {
    return (
      <Route
        path={props.path}
        exact={props.exact}
        render={({ location }) => {
          const storage = window.sessionStorage;
          storage.setItem("route_requested", location.pathname);
          return (
            <Redirect
              to={{
                pathname: AppRoutes().login,
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <Route path={props.path} exact={props.exact}>
      <AuthenticatedComponent
        setLocale={props.setLocale}
        teamId={auth.user.teamId}
      >
        {props.children}
      </AuthenticatedComponent>
    </Route>
  );
}

function AuthenticatedComponent(props: {
  setLocale: (locale: dirbatLocale) => void;
  children: JSX.Element;
  teamId: string;
}) {
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const currentRoute = useLocation().pathname;
  const auth = useAuth();

  const [application, setApplication] = useState<Application>({
    ...ADMIN_APPLICATION,
    clientId: clientId,
    supplierId: supplierId,
    teamId: props.teamId,
  });
  const api = new AppsDirectoryAPI(auth.user.token, auth.user.locale);

  useEffect(() => {
    async function loadApplication(clientId: string) {
      return await api.getOne(clientId).then((a) => {
        setApplication(a);
      });
    }

    if (clientId) {
      loadApplication(clientId);
    }
  }, [clientId]);

  return (
    <ApplicationContext.Provider value={application}>
      <div className="relative">
        <Header
          currentRoute={currentRoute}
          menu={suppliersMenu(clientId, supplierId, props.teamId)}
          setLocale={props.setLocale}
          clientId={clientId}
          supplierId={supplierId}
          teamId={props.teamId}
        />
        <div className="container mx-auto flex flex-col min-h-screen pt-32 max-w-7xl ">
          {props.children}
        </div>
      </div>
    </ApplicationContext.Provider>
  );
}

export default App;
