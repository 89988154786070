import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { Button } from "../../UIKit/Button";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import RoutesExhibitions from "./Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "../../UIKit/Modal";
import { contentPageResponse } from "../commonTypes";
import { Exhibition, ExhibitionCalendar } from "./Exhibition";

interface ExhibitionsListProps {
  calendar: ExhibitionCalendar;
  columns: tableColumn<Exhibition>[];
  clientId: string;
  supplierId: string;
}

export function ExhibitionsList(props: ExhibitionsListProps) {
  const exhibitionsPerPage = 20;
  const history = useHistory();
  const intl = useIntl();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [exhibitionToDelete, triggerDeleteModal] = useState<Exhibition | false>(
    false
  );
  const queryClient = useQueryClient();
  const {
    isFetching: exhibitionsAreFetching,
    data: exhibitionsPage,
    refetch,
  } = useQuery<contentPageResponse<Exhibition>>(
    "exhibitions::all::page::" + startingAt,
    () => {
      return props.calendar.findPaginated(
        (startingAt - 1) * exhibitionsPerPage + 1,
        exhibitionsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadExhibitionsSlice(number: number) {
    setStartingAt(number);
  }

  function deleteExhibition(exhibitionToDelete: Exhibition) {
    props.calendar.delete(exhibitionToDelete).then((success) => {
      if (success) {
        triggerDeleteModal(false);
        refetch();
      } else {
      }
    });
  }

  if (!exhibitionsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "exhibitions.checking.exhibitions",
            defaultMessage: "verification of the list of exhibitions",
          })}
        />
      </div>
    );
  } else {
    const exhibitions = exhibitionsPage.items;
    const exhibitionsStats = exhibitionsPage.stats;
    return (
      <>
        {exhibitionToDelete && (
          <Modal
            onCancel={() => {
              triggerDeleteModal(false);
            }}
            onConfirm={() => deleteExhibition(exhibitionToDelete)}
            altButtonLabel="cancel"
            mainButtonLabel="delete"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="exhibitions.delete.warning.title"
                  defaultMessage="Delete a show"
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="exhibitions.delete.warning.body"
                  defaultMessage="This deletion is irreversible"
                />
              </p>
            </div>
          </Modal>
        )}
        {exhibitions.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="exhibitions.no.exhibition.yet.1"
                  defaultMessage="...no exhibition for the moment 🤷"
                />
              </p>
              <p>
                <FormattedMessage
                  id="exhibitions.no.exhibition.yet.2"
                  defaultMessage="Fill your calendar!"
                />
              </p>
              <div className="pt-2">
                <Button
                  iconName="PlusIcon"
                  label={"exhibitions.create.first.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(
                      RoutesExhibitions(props.clientId, props.supplierId).add
                    )
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="exhibitions.count.plural"
                  defaultMessage="{num, plural, one {# exhibition} other {# exhibitions}}"
                  values={{ num: exhibitionsStats.totalCount }}
                />
              </h2>
              {exhibitionsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "exhibitions.checking.exhibitions",
                      defaultMessage: "verification of the list of exhibitions",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Exhibition>
              onPageChange={loadExhibitionsSlice}
              totalItemsCount={exhibitionsStats.totalCount || 0}
              itemsPerPage={exhibitionsPerPage}
              data={exhibitions}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  content: (exhibition) => (
                    <div className="flex flex-row">
                      <div className="mr-1">
                        <Button
                          size="small"
                          iconName="PencilIcon"
                          onClick={() => {
                            history.push(
                              RoutesExhibitions(
                                props.clientId,
                                props.supplierId
                              ).edit(exhibition.id)
                            );
                          }}
                          label={"edit"}
                        />
                      </div>
                      <Button
                        size="tiny"
                        type="cancel"
                        iconName="XIcon"
                        onClick={() => {
                          triggerDeleteModal(exhibition);
                        }}
                        label={intl.formatMessage({
                          id: "forms.delete",
                          defaultMessage: "delete",
                        })}
                      />
                    </div>
                  ),
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "exhibitions.add.a.exhibition",
                    defaultMessage: "Add an exhibition",
                  })}
                  onClick={(e) => {
                    history.push(
                      RoutesExhibitions(props.clientId, props.supplierId).add
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
