import { ContentRepository, Statuses } from "../commonTypes";
import { DateTime } from "luxon";

export type VideoStatus = Statuses;

export interface Video {
  title: string;
  url: string;
  headline: string;
  triggersNotification:boolean;
  photo: string;
  publicationDate: DateTime;
  removalDate: DateTime;
  id: string;
  status: VideoStatus;
}

export interface VideoLibrary extends ContentRepository<Video, videoDTO> {
}

export interface videoDTO {
  title: string;
  url: string;
  image: File | undefined;
  imagePath?: string;
  headline: string;
  triggersNotification:boolean;
  publicationDate?: DateTime;
  removalDate?: DateTime;
  id?: string;
  status: VideoStatus;
}
export const EMPTY_VIDEO: Video = {
  headline: "",
  id: "",
  triggersNotification:false,
  photo: "",
  publicationDate: DateTime.local().startOf("day"),
  removalDate: DateTime.local().endOf("day"),
  status: "draft",
  title: "",
  url: ""
};

