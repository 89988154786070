import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { tableColumn } from "../../UIKit/Table";
import { BONotificationPayload } from "../../app/Notifications";
import { Contractor } from "./Contractors";
import { ContractorsDirectoryAPI } from "./ContactorsDirectoryAPI";
import { ContractorsList } from "./ContractorsList";
import { CommunityDashboard } from "./CommunityDashboard";
import { Badge } from "../../UIKit/Badge";
import { OffersDirectoryAPI } from "./OffersDirectoryAPI";
import { offersColumns, OffersList } from "./OffersList";
import { messageColumns } from "./ContactMessagesList";
import { ContactMessage } from "./ContactMessages";
import { MailboxAPI } from "./MailboxAPI";
import { List } from "../../utilities/List";
import { IntlShape, useIntl } from "react-intl";

function contractorsColumns(intl: IntlShape): tableColumn<Contractor>[] {
  return [
    {
      label: intl.formatMessage({
        id: "contractor.name",
        defaultMessage: "Name",
      }),
      content: (contractor: Contractor) => (
        <div>
          {contractor.firstname} {contractor.lastname}
        </div>
      ),
    },
    {
      label: intl.formatMessage({
        id: "contributors.email",
        defaultMessage: "Email",
      }),
      content: (contractor: Contractor) => contractor.email,
    },
    {
      label: intl.formatMessage({
        id: "contributors.registrationDate",
        defaultMessage: "Registration date",
      }),
      content: (contractor: Contractor) =>
        contractor.registrationDate.toLocaleString(),
    },
    {
      label: intl.formatMessage({
        id: "contributors.infos",
        defaultMessage: "status",
      }),
      content: (contractor: Contractor) =>
        contractor.verificationDate ? (
          <Badge
            label={intl.formatMessage({
              id: "contributors.acount.confirmed",
              defaultMessage: "account confirmed",
            })}
            type="ok"
          />
        ) : (
          <Badge
            label={intl.formatMessage({
              id: "contributors.acount.pendind",
              defaultMessage: "account not confirmed",
            })}
            type="ko"
          />
        ),
    },
  ];
}

export function CommunityModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const intl = useIntl();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const contractorsDirectory = new ContractorsDirectoryAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const offersDirectory = new OffersDirectoryAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const mailbox = new MailboxAPI(token, clientId, supplierId, auth.user.locale);

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").contractors} exact>
        <ContractorsList
          directory={contractorsDirectory}
          columns={contractorsColumns(intl)}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").offers} exact>
        <OffersList
          directory={offersDirectory}
          columns={offersColumns(intl)}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").contacts} exact>
        <List<ContactMessage>
          notify={props.notify}
          columns={messageColumns}
          directory={mailbox}
          messages={{
            empty1: intl.formatMessage({
              id: "messages.no.message.yet.1",
              defaultMessage: "No message received yet",
            }),
            empty2: intl.formatMessage({
              id: "messages.no.message.yet.2",
              defaultMessage: "...It won't last!",
            }),
            countPlural: "messages.count.plural",
            loading: intl.formatMessage({
              id: "messages.loading.messages",
              defaultMessage: "Loading messages",
            }),
          }}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").dashboard}>
        <CommunityDashboard clientId={clientId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
}
