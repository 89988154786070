import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { Badge } from "../../UIKit/Badge";
import { DateTime } from "luxon";
import {
  getBadgeType,
  getFlavorLabel,
  getPublicationDateLabel,
  getRemovalDateLabel,
  getStatusLabel,
  tableColumn,
} from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { BONotificationPayload } from "../../app/Notifications";
import { EMPTY_MEETUP, Meetup, MeetupCalendar, meetupDTO } from "./Meetup";
import { MeetupsCalendarAPI } from "./MeetupsCalendarAPI";
import { MeetupForm } from "./MeetupForm";
import { MeetupsList } from "./MeetupsList";
import { Schedulable } from "../News/News";

const emptyMeetup: meetupDTO = {
  id: "",
  publicationDate: DateTime.local(),
  status: "draft",
  title: "",
  description: "",
  location: "",
  removalDate: DateTime.local(),
  startDate: DateTime.local(),
  endDate: DateTime.local(),
};

export function MeetupsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const intl = useIntl();
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const calendar = new MeetupsCalendarAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const meetupsColumns: tableColumn<Meetup>[] = [
    {
      label: "meetups.title",
      content: (meetup: Meetup) => meetup.title,
    },
    {
      label: intl.formatMessage({
        id: "schedulable.status",
        defaultMessage: "Status",
      }),
      content: (item: Schedulable) => (
        <Badge
          label={getStatusLabel(item, intl)}
          flavor={getFlavorLabel(item)}
          type={getBadgeType(item)}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.publication.date",
        defaultMessage: "Publication date",
      }),
      content: (item: Schedulable, locale) =>
        getPublicationDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.removal.date",
        defaultMessage: "Removal date",
      }),
      content: (item: Schedulable, locale) => getRemovalDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "meetups.description",
        defaultMessage: "Description",
      }),
      content: (meetup: Meetup) => <>{meetup.description.slice(0, 30)}...</>,
    },
  ];

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="meetups.add.title"
            defaultMessage="Add a meeting"
          />
        </h1>
        <MeetupForm
          calendar={calendar}
          awaitsValidation={true}
          data={emptyMeetup}
          onSuccess={() => {
            history.push(ModuleRoutes(clientId, supplierId).meetups);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="meetups.edit.title"
            defaultMessage="Edit a meeting"
          />
        </h1>
        <EditMeetup
          calendar={calendar}
          notify={props.notify}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").meetups} exact>
        <MeetupsList
          calendar={calendar}
          columns={meetupsColumns}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
    </Switch>
  );
}

function EditMeetup(props: {
  calendar: MeetupCalendar;
  notify: (not: BONotificationPayload) => void;
  clientId: string;
  supplierId: string;
}) {
  const [meetup, setMeetup] = useState<Meetup>(EMPTY_MEETUP);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setMeetup(await props.calendar.findOneById(id)))();
  }, [id]);
  const history = useHistory();
  const dto: meetupDTO = {
    id: meetup.id,
    publicationDate: meetup.publicationDate,
    removalDate: meetup.removalDate,
    status: meetup.status,
    title: meetup.title,
    description: meetup.description,
    location: meetup.location,
    startDate: meetup.startDate,
    endDate: meetup.endDate,
  };
  return (
    <MeetupForm
      calendar={props.calendar}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(ModuleRoutes(props.clientId, props.supplierId).meetups);
      }}
    />
  );
}
