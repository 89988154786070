import { DateTime } from "luxon";
import { newsDTO } from "./NewsForm";
import { ContentProviderStats, Statuses } from "../commonTypes";
import { NotificationStatus } from "../Notifications/Notifications";

export type NewsStatus = Statuses;

export interface Schedulable {
  publicationDate: DateTime;
  removalDate: DateTime;
  status: Statuses;
}

export interface News extends Schedulable {
  url: string;
  photo: string;
  headline: string;
  triggersNotification: boolean;
  id: string;
  title: string;
  notificationStatus: NotificationStatus;
}

export interface newsPageResponse {
  items: News[];
  stats: ContentProviderStats;
}

export interface NewsJournal {
  findPaginated: (
    firstIdx: number,
    cataloguesPerPage: number
  ) => Promise<newsPageResponse>;
  save: (newsDTO: newsDTO) => Promise<News>;
  findOneById: (id: string) => Promise<News>;

  delete(news: News): Promise<boolean>;
}

export const EMPTY_NEWS: News = {
  headline: "",
  id: "",
  photo: "",
  triggersNotification: false,
  publicationDate: DateTime.local(),
  removalDate: DateTime.local(),
  status: "draft",
  notificationStatus: "notify",
  title: "",
  url: "",
};
