import React, { useEffect, useState } from "react";
import { DateTime, Duration } from "luxon";
import { Input, InputProps } from "./Input";
import { Select } from "./Select";

export interface InputDateProps extends InputProps {
  onChange: (d: DateTime) => void;
  date?: DateTime;
  withTime?: boolean;
}

function generateHourOptions() {
  const hours = [];
  for (let h = 0; h <= 23; h++) {
    hours.push({ label: h + "h", value: h.toString(10) });
  }
  return hours;
}

function generateMinutesOptions() {
  const minutes = [];
  for (let m = 0; m <= 59; m += 15) {
    minutes.push({ label: m + "m", value: m.toString(10) });
  }
  return minutes;
}

export function InputDate(props: InputDateProps) {
  const propsDate =
    props.date instanceof DateTime
      ? !props.date.isValid
        ? undefined
        : props.date
      : undefined;

  const [hour, setHour] = useState<string>(
    propsDate ? propsDate.toFormat("H") : "8"
  );
  const [minutes, setMinutes] = useState<string>(
    propsDate ? propsDate.toFormat("m") : "0"
  );
  const [date, setDate] = useState<string>(
    propsDate?.toFormat("yyyy-LL-dd") || DateTime.local().toFormat("yyyy-LL-dd")
  );
  useEffect(() => {
    props.onChange(forgeTime());
  }, [hour, date, minutes]);

  useEffect(() => {
    setHour(propsDate ? propsDate.toFormat("H") : "8");
    setMinutes(propsDate ? propsDate.toFormat("m") : "0");
    setDate(
      propsDate
        ? propsDate.toFormat("yyyy-LL-dd")
        : DateTime.local().toFormat("yyyy-LL-dd")
    );
  }, [propsDate]);

  function forgeTime() {
    return DateTime.fromFormat(`${date}`, "yyyy-LL-dd").plus(
      Duration.fromObject({
        hours: parseInt(hour),
        minutes: parseInt(minutes),
      })
    );
  }

  return (
    <div className="flex items-end">
      <Input
        size={props.size}
        className={props.className}
        disabled={props.disabled}
        name={props.name}
        label={props.label}
        type={"date"}
        required={props.required}
        isValid={props.isValid}
        validationMessage={props.validationMessage}
        awaitsValidation={props.awaitsValidation}
        value={date}
        onChange={(d) => {
          setDate(DateTime.fromISO(d.target.value).toFormat("yyyy-LL-dd"));
        }}
      />
      {props.withTime && (
        <div className="flex flex-col items-center">
          <Select
            expanded={true}
            options={generateHourOptions()}
            name={props.name + "hours"}
            onChange={(h) => {
              setHour(h);
            }}
            value={hour}
            awaitsValidation={props.awaitsValidation}
          />
          <Select
            expanded={true}
            name={props.name + "minutes"}
            options={generateMinutesOptions()}
            onChange={(m) => {
              setMinutes(m);
            }}
            value={minutes}
            awaitsValidation={props.awaitsValidation}
          />
        </div>
      )}
    </div>
  );
}
