import React from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

const colors = {
  accent: "rgba(245, 191, 98, 1)",
  background: "rgb(248,217,157)",
};

export interface MetricCardChartProps {
  data: MetricCardChartData[];
  label: string;
  width?: number;
  height?: number;
}

export interface MetricCardChartData {
  date: string;
  value: number;
}

let dimension = {
  width: 400,
  height: 150,
  margin: {
    top: 8,
    right: 16,
    bottom: 8,
    left: 16,
  },
};

export function MetricCardChart(props: MetricCardChartProps) {
  if (props.width) dimension.width = props.width;
  if (props.height) dimension.height = props.height;

  const styles = {
    cont: {
      position: "relative" as "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: props.width,
      height: props.height,
    },
    nb: {
      position: "absolute" as "absolute",
      top: 0,
      left: 0,
      marginTop: dimension.margin.top,
      marginBottom: dimension.margin.bottom,
      marginLeft: dimension.margin.left,
      marginRight: dimension.margin.right,
    },
  };

  return (
    <div className="bg-gray-50 rounded-lg" style={styles.cont}>
      <div className="text-6xl font-bold z-10" style={styles.nb}>
        <div className="text-2xl font-normal">{props.label}</div>
        <p>
          {Math.max.apply(
            Math,
            props.data.map(function (o) {
              return o.value;
            })
          )}
        </p>
      </div>
      <ResponsiveContainer>
        <AreaChart
          width={dimension.width}
          height={dimension.height}
          data={props.data}
          margin={dimension.margin}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.background} stopOpacity={1} />
              <stop
                offset="95%"
                stopColor={colors.background}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            isAnimationActive={false}
            stroke={colors.accent}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
