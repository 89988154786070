import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { Button } from "../../UIKit/Button";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import RoutesMeetups from "./Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "../../UIKit/Modal";
import { contentPageResponse } from "../commonTypes";
import { Meetup, MeetupCalendar } from "./Meetup";

interface MeetupsListProps {
  calendar: MeetupCalendar;
  columns: tableColumn<Meetup>[];
  clientId: string;
  supplierId: string;
}

export function MeetupsList(props: MeetupsListProps) {
  const meetupsPerPage = 20;
  const history = useHistory();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [meetupToDelete, triggerDeleteModal] = useState<Meetup | false>(false);
  const intl = useIntl();
  const {
    isFetching: meetupsAreFetching,
    data: meetupsPage,
    refetch,
  } = useQuery<contentPageResponse<Meetup>>(
    "meetups::all::page::" + startingAt,
    () => {
      return props.calendar.findPaginated(
        (startingAt - 1) * meetupsPerPage + 1,
        meetupsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadMeetupsSlice(number: number) {
    setStartingAt(number);
  }

  function deleteMeetup(meetupToDelete: Meetup) {
    props.calendar.delete(meetupToDelete).then((success) => {
      if (success) {
        refetch();
        triggerDeleteModal(false);
      } else {
      }
    });
  }

  if (!meetupsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "meetups.checking.meetups",
            defaultMessage: "verification of the meeting list",
          })}
        />
      </div>
    );
  } else {
    const meetups = meetupsPage.items;
    const meetupsStats = meetupsPage.stats;
    return (
      <>
        {meetupToDelete && (
          <Modal
            onCancel={() => {
              triggerDeleteModal(false);
            }}
            onConfirm={() => deleteMeetup(meetupToDelete)}
            altButtonLabel="cancel"
            mainButtonLabel="delete"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="meetups.delete.warning.title"
                  defaultMessage="Delete a meeting"
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="meetups.delete.warning.body"
                  defaultMessage="This deletion is irreversible"
                />
              </p>
            </div>
          </Modal>
        )}
        {meetups.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="meetups.no.meetup.yet.1"
                  defaultMessage="...no meeting for the moment 🤷"
                />
              </p>
              <p>
                <FormattedMessage
                  id="meetups.no.meetup.yet.2"
                  defaultMessage="Exchange with your users !"
                />
              </p>
              <div className="pt-2">
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "meetups.create.first.cta",
                    defaultMessage: "Add a meeting 🗓",
                  })}
                  type="primary"
                  onClick={() =>
                    history.push(
                      RoutesMeetups(props.clientId, props.supplierId).add
                    )
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="meetups.count.plural"
                  defaultMessage="{num, plural, one {# meeting} other {# meeting}}"
                  values={{ num: meetupsStats.totalCount }}
                />
              </h2>
              {meetupsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "meetups.checking.meetups",
                      defaultMessage: "verification of the meeting list",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Meetup>
              onPageChange={loadMeetupsSlice}
              totalItemsCount={meetupsStats.totalCount || 0}
              itemsPerPage={meetupsPerPage}
              data={meetups}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  content: (meetup) => (
                    <div className="flex flex-row">
                      <div className="mr-1">
                        <Button
                          size="small"
                          iconName="PencilIcon"
                          onClick={() => {
                            history.push(
                              RoutesMeetups(
                                props.clientId,
                                props.supplierId
                              ).edit(meetup.id)
                            );
                          }}
                          label={"edit"}
                        />
                      </div>
                      <Button
                        size="tiny"
                        type="cancel"
                        iconName="XIcon"
                        onClick={() => {
                          triggerDeleteModal(meetup);
                        }}
                        label={intl.formatMessage({
                          id: "forms.delete",
                          defaultMessage: "delete",
                        })}
                      />
                    </div>
                  ),
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "meetups.add.a.meetup",
                    defaultMessage: "Add a meeting",
                  })}
                  onClick={(e) => {
                    history.push(
                      RoutesMeetups(props.clientId, props.supplierId).add
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
