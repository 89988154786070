import { DateTime } from "luxon";
import { productNewsDTO } from "./ProductNewsForm";
import { ContentProviderStats, Statuses } from "../commonTypes";

export type ProductNewsStatus = Statuses;

export interface ProductNews {
  photo: string;
  headline: string;
  publicationDate: DateTime;
  removalDate: DateTime;
  id: string;
  productCode: string;
  title: string;
  status: ProductNewsStatus;
  triggersNotification: boolean;
  notificationDate?: DateTime;
}

export interface productNewsPageResponse {
  items: ProductNews[];
  stats: ContentProviderStats;
}

export interface ProductNewsJournal {
  findPaginated: (
    firstIdx: number,
    productNewsPerPage: number
  ) => Promise<productNewsPageResponse>;
  save: (productNewsDTO: productNewsDTO) => Promise<ProductNews>;
  findOneById: (id: string) => Promise<ProductNews>;

  delete(news: ProductNews): Promise<boolean>;
}

export const EMPTY_PRODUCT_NEWS: ProductNews = {
  headline: "",
  id: "",
  photo: "",
  productCode: "",
  publicationDate: DateTime.local(),
  removalDate: DateTime.local(),
  triggersNotification: false,
  status: "draft",
  title: "",
};
