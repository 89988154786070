import { Application } from "./Application";
import { Input } from "../../UIKit/form/Input";
import React from "react";

export interface CloneAppFormState {
  appName: string;
  supplierName: string;
  identifier: string;
}

export function CloneAppForm(props: {
  sourceApp: Application;
  onChange: (status: boolean, state: CloneAppFormState) => void;
  value: CloneAppFormState;
  submitting: boolean;
}) {
  function onChange(field: keyof CloneAppFormState, value: string) {
    const newState: CloneAppFormState = { ...props.value };
    newState[field] = value;
    props.onChange(isValid(newState), newState);
  }

  function isValid(state: CloneAppFormState): boolean {
    return !!state.identifier && !!state.supplierName;
  }

  return (
    <div className="w-96">
      <h2>
        Cloner{" "}
        <em>
          <strong>{props.sourceApp.name}</strong>
        </em>
      </h2>
      <Input
        name="identifier"
        label={"apps.new.app.identifier"}
        placeholder="com.dirbat.dirbat"
        isValid={!!props.value.identifier}
        required={true}
        onChange={(e) => onChange("identifier", e.target.value)}
        followChanges={true}
        awaitsValidation={true}
      />
      <Input
        name="appName"
        label="apps.new.app.name"
        isValid={!!props.value.appName}
        required={true}
        onChange={(e) => onChange("appName", e.target.value)}
        followChanges={true}
        awaitsValidation={true}
      />
      <Input
        name="supplierName"
        label="apps.new.suppplier.name"
        isValid={!!props.value.supplierName}
        required={true}
        onChange={(e) => onChange("supplierName", e.target.value)}
        followChanges={true}
        awaitsValidation={true}
      />
    </div>
  );
}
