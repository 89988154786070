import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DynamicHeroIcon } from "../../UIKit/DynamicHeroIcon";

export function ConfigurationCardMultipleValue(props: {
  title: string;
  initialValues: string[];
  onSubmit: (value: string[]) => void;
}) {
  const [values, setValues] = useState(props.initialValues);

  const submit = (values: string[]) => {
    props.onSubmit(values);
  };

  const addCard = () => {
    setValues([...values, ""]);
  };

  const deleteCard = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const editCard = (index: number, input: string) => {
    const newValues = [...values];
    newValues[index] = input;
    setValues(newValues);
  };

  const arrayEquals = (a: string[], b: string[]) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  return (
    <div className="flex items-center justify-between bg-gray-100 text-base p-4 rounded-md gap-4">
      <FormattedMessage id={props.title} />
      <div className="flex flex-1 flex-col items-center w-full gap-2">
        {values.map((value, index) => (
          <div className="flex flex-row gap-2 items-center justify-center w-full mx-10">
            <input
              onInput={(input) => {
                editCard(index, input.currentTarget.value);
              }}
              className="rounded-lg justify-center p-2 w-full h-10"
              value={value}
            />
            <button
              className="flex flex-row p-2 bg-red-500 text-white rounded-full h-8 w-8"
              onClick={() => {
                deleteCard(index);
              }}
            >
              <DynamicHeroIcon icon={"MinusIcon"} width={"4"} />
            </button>
          </div>
        ))}
        <button
          className="flex flex-row p-2 bg-primary-400 mt-1 text-white rounded-full h-8 w-8"
          onClick={() => {
            addCard();
          }}
        >
          <DynamicHeroIcon icon={"PlusIcon"} width={"4"} />
        </button>
      </div>
      <div className="h-8 w-8">
        <button
          className={
            "relative p-2 text-secondary rounded-full h-full w-full " +
            (arrayEquals(values, props.initialValues)
              ? "bg-primary-50"
              : "bg-primary-300 animate-bounce")
          }
          disabled={values === props.initialValues}
          onClick={() => {
            submit(values);
          }}
        >
          <DynamicHeroIcon icon={"CheckIcon"} width={"4"} />
        </button>
      </div>
    </div>
  );
}
