import React, { useEffect, useState } from "react";
import { BONotificationPayload } from "../../app/Notifications";
import { DateTime } from "luxon";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { Input } from "../../UIKit/form/Input";
import { InputDate } from "../../UIKit/form/InputDate";
import { Switch } from "../../UIKit/form/Swich";
import { Button } from "../../UIKit/Button";
import { MeetupCalendar, meetupDTO, MeetupStatuses } from "./Meetup";
import { FormattedMessage, useIntl } from "react-intl";

export interface MeetupFormProps {
  awaitsValidation: true;
  data: meetupDTO;
  calendar: MeetupCalendar;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
}

export function MeetupForm(props: MeetupFormProps) {
  let step = 1;
  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const [title, setTitle] = useState<string>(props.data.title);
  const [description, setDescription] = useState<string>(
    props.data.description
  );
  const [startDate, setStartDate] = useState<DateTime>(props.data.startDate);
  const [endDate, setEndDate] = useState<DateTime>(props.data.startDate);
  const [location, setLocation] = useState<string>(props.data.location);
  const [publicationDate, setPublicationDate] = useState<DateTime>(
    props.data.publicationDate
  );
  const [removalDate, setRemovalDate] = useState<DateTime>(
    props.data.removalDate
  );
  const [status, setStatus] = useState<MeetupStatuses>(props.data.status);
  const intl = useIntl();

  function isValid(): boolean {
    return (
      !!title &&
      !!location &&
      !!description &&
      !!startDate &&
      !!publicationDate &&
      !!status
    );
  }
  useEffect(() => {
    setTitle(props.data.title);
    setLocation(props.data.location);
    setDescription(props.data.description);
    setStartDate(props.data.startDate);
    setEndDate(props.data.endDate);
    setPublicationDate(props.data.publicationDate);
    props.data.removalDate && setRemovalDate(props.data.removalDate);
    setStatus(props.data.status);
  }, [props.data]);
  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const meetupDTO: meetupDTO = {
        title: title,
        description: description,
        startDate: startDate,
        endDate: endDate,
        publicationDate: publicationDate,
        removalDate: removalDate,
        id: props.data.id,
        status: status,
        location: location,
      };
      props.calendar
        .save(meetupDTO)
        .then((savedMeetup) => {
          props.onSuccess();
        })
        .catch((reason) => props.notify({ type: "error", message: reason }));
    }
  }
  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.content",
              defaultMessage: "Content",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="title"
            label={intl.formatMessage({
              id: "meetups.title",
              defaultMessage: "Title",
            })}
            value={title}
            isValid={!!title}
            required={true}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="location"
            label={intl.formatMessage({
              id: "meetups.location",
              defaultMessage: "Location",
            })}
            value={location}
            isValid={!!location}
            required={true}
            onChange={(e: any) => {
              setLocation(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="description"
            label={intl.formatMessage({
              id: "meetups.description",
              defaultMessage: "description",
            })}
            value={description}
            isValid={!!description}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <InputDate
            name="startDate"
            label={intl.formatMessage({
              id: "meetups.startDate",
              defaultMessage: "Start",
            })}
            isValid={!!startDate}
            date={startDate}
            withTime={true}
            onChange={(d: DateTime) => {
              setStartDate(d);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <InputDate
            name="endDate"
            label={intl.formatMessage({
              id: "meetups.endDate",
              defaultMessage: "End",
            })}
            isValid={!!endDate}
            date={endDate}
            withTime={true}
            onChange={(d: DateTime) => {
              setEndDate(d);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
        </div>
        <div className="mb-4 w-1/3">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.schedule",
              defaultMessage: "Schedule",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <div className="flex flex-row">
            <div className=" w-1/2">
              <InputDate
                name="publicationDate"
                label={intl.formatMessage({
                  id: "schedulable.publication.date",
                  defaultMessage: "Publication date",
                })}
                date={publicationDate}
                onChange={(d: DateTime) => {
                  setPublicationDate(d);
                }}
                isValid={!!publicationDate}
                awaitsValidation={awaitsValidation}
                required={true}
              />
            </div>
            <div className="ml-4 w-1/2">
              <InputDate
                name="removalDate"
                label={intl.formatMessage({
                  id: "schedulable.removal.date",
                  defaultMessage: "Removal date",
                })}
                date={removalDate}
                onChange={(d: DateTime) => {
                  setRemovalDate(d);
                }}
                isValid={!!removalDate}
                awaitsValidation={awaitsValidation}
                required={true}
              />
            </div>
          </div>
          <div>
            <FormattedMessage id="status" defaultMessage="status" />
          </div>
          <Switch
            choice1Label="published"
            choice2Label="draft"
            choice2Value="draft"
            choice1Value="published"
            onChange={setStatus}
            value={status}
            name="status"
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        {props.data.id ? (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: "meetups.edit.submit",
              defaultMessage: "Edit this meeting",
            })}
          />
        ) : (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: "meetups.add.submit",
              defaultMessage: "Add this meeting",
            })}
          />
        )}
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            type="secondary"
            label={intl.formatMessage({
              id: "cancel",
              defaultMessage: "cancel",
            })}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
