import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { Button } from "../../UIKit/Button";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import RoutesVideos from "./Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "../../UIKit/Modal";
import { Video, VideoLibrary } from "./Video";
import { contentPageResponse } from "../commonTypes";

interface VideosListProps {
  library: VideoLibrary;
  columns: tableColumn<Video>[];
  clientId: string;
  supplierId: string;
}

export function VideosList(props: VideosListProps) {
  const videosPerPage = 20;
  const history = useHistory();
  const intl = useIntl();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [videoToDelete, triggerDeleteModal] = useState<Video | false>(false);
  const queryClient = useQueryClient();
  const { isFetching: videosAreFetching, data: videosPage, refetch } = useQuery<
    contentPageResponse<Video>
  >(
    "videos::all::page::" + startingAt,
    () => {
      return props.library.findPaginated(
        (startingAt - 1) * videosPerPage + 1,
        videosPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadVideosSlice(number: number) {
    setStartingAt(number);
  }

  function deleteVideo(videoToDelete: Video) {
    props.library.delete(videoToDelete).then((success) => {
      if (success) {
        refetch();
        triggerDeleteModal(false);
      } else {
      }
    });
  }

  if (!videosPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "videos.checking.videos",
            defaultMessage: "check the list of videos",
          })}
        />
      </div>
    );
  } else {
    const videos = videosPage.items;
    const videosStats = videosPage.stats;
    return (
      <>
        {videoToDelete && (
          <Modal
            onCancel={() => {
              triggerDeleteModal(false);
            }}
            onConfirm={() => deleteVideo(videoToDelete)}
            altButtonLabel="cancel"
            mainButtonLabel="delete"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="videos.delete.warning.title"
                  defaultMessage="Delete a video"
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="videos.delete.warning.body"
                  defaultMessage="This suppression is irreversible"
                />
              </p>
            </div>
          </Modal>
        )}
        {videos.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="videos.no.video.yet.1"
                  defaultMessage="...no video referenced at this time 🤷"
                />
              </p>
              <p>
                <FormattedMessage
                  id="videos.no.video.yet.2"
                  defaultMessage="Just use the ones you post on Youtube to get started"
                />
              </p>
              <div className="pt-2">
                <Button
                  iconName="PlusIcon"
                  label={"videos.create.first.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(
                      RoutesVideos(props.clientId, props.supplierId).add
                    )
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="videos.count.plural"
                  defaultMessage="{num, plural, one {# video} other {# videos}}"
                  values={{ num: videosStats.totalCount }}
                />
              </h2>
              {videosAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "videos.checking.videos",
                      defaultMessage: "check the list of videos",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Video>
              onPageChange={loadVideosSlice}
              totalItemsCount={videosStats.totalCount || 0}
              itemsPerPage={videosPerPage}
              data={videos}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  content: (video) => (
                    <div className="flex flex-row">
                      <div className="mr-1">
                        <Button
                          size="small"
                          iconName="PencilIcon"
                          onClick={() => {
                            history.push(
                              RoutesVideos(
                                props.clientId,
                                props.supplierId
                              ).edit(video.id)
                            );
                          }}
                          label={"edit"}
                        />
                      </div>
                      <Button
                        size="tiny"
                        type="cancel"
                        iconName="XIcon"
                        onClick={() => {
                          triggerDeleteModal(video);
                        }}
                        label={intl.formatMessage({
                          id: "forms.delete",
                          defaultMessage: "delete",
                        })}
                      />
                    </div>
                  ),
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label={intl.formatMessage({
                    id: "videos.add.a.video",
                    defaultMessage: "Add a video",
                  })}
                  onClick={(e) => {
                    history.push(
                      RoutesVideos(props.clientId, props.supplierId).add
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
