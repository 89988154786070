import React, { useEffect, useState } from "react";
import { Input } from "../../UIKit/form/Input";
import { FileUploadZone } from "../../UIKit/form/FileUploadZone";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { Button } from "../../UIKit/Button";
import { DateTime } from "luxon";
import { NewsJournal, NewsStatus } from "./News";
import { BONotificationPayload } from "../../app/Notifications";
import { NotificationStatus } from "../Notifications/Notifications";
import { SchedulingFormWidget } from "../../UIKit/form/SchedulingFormWidget";
import {
  AlreadyNotified,
  NotificationFormWidget,
} from "../../UIKit/form/NotificationFormWidget";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";

export interface NewsFormProps {
  awaitsValidation: boolean;
  data: newsDTO;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
  journal: NewsJournal;
}

export interface newsDTO {
  title: string;
  url: string;
  image: File | undefined;
  imagePath?: string;
  headline: string;
  publicationDate: DateTime;
  removalDate: DateTime;
  triggersNotification: boolean;
  notificationStatus: NotificationStatus;
  id?: string;
  status: NewsStatus;
}

export function NewsForm(props: NewsFormProps) {
  let step = 1;
  const { user } = useAuth();

  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const [title, setTitle] = useState<string>(props.data.title);
  const [url, setUrl] = useState<string>(props.data.url);
  const [headline, setHeadline] = useState<string>(props.data.headline);
  const [publicationDate, setPublicationDate] = useState<DateTime>(
    props.data.publicationDate ? props.data.publicationDate : DateTime.local()
  );
  const [removalDate, setRemovalDate] = useState<DateTime>(
    props.data.removalDate ? props.data.removalDate : DateTime.local()
  );
  const [triggersNotification, setTriggersNotification] = useState<boolean>(
    props.data.triggersNotification
  );
  const [image, setImage] = useState<File | undefined>(undefined);
  const [status, setStatus] = useState<NewsStatus>(props.data.status);
  const [
    notificationStatus,
    setNotificationStatus,
  ] = useState<NotificationStatus>("notify");
  const intl = useIntl();

  function onFileUpdate(file: File) {
    setImage(file);
  }

  function isValid(): boolean {
    return (
      !!title &&
      !!url &&
      !!headline &&
      !!publicationDate &&
      !!status &&
      !!notificationStatus &&
      (!!image || !!props.data.imagePath)
    );
  }

  useEffect(() => {
    setTitle(props.data.title);
    setUrl(props.data.url);
    setHeadline(props.data.headline);
    setPublicationDate(props.data.publicationDate);
    setTriggersNotification(props.data.triggersNotification);
    props.data.removalDate && setRemovalDate(props.data.removalDate);
    setStatus(props.data.status);
    setNotificationStatus("notify");
  }, [props.data]);

  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const newsDTO: newsDTO = {
        title: title,
        url: url,
        image: image,
        imagePath: props.data.imagePath,
        headline: headline,
        publicationDate: publicationDate,
        removalDate: removalDate,
        triggersNotification: triggersNotification,
        notificationStatus: props.data.notificationStatus,
        id: props.data.id,
        status: status,
      };
      props.journal
        .save(newsDTO)
        .then((_savedNews) => {
          props.onSuccess();
        })
        .catch((reason) => props.notify({ type: "error", message: reason }));
    }
  }

  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.content",
              defaultMessage: "Content",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="title"
            label={intl.formatMessage({
              id: "news.title",
              defaultMessage: "Title",
            })}
            value={title}
            isValid={!!title}
            maxLength={60}
            required={true}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="url"
            label={intl.formatMessage({
              id: "news.url",
              defaultMessage: "Destination URL",
            })}
            value={url}
            isValid={!!url}
            onChange={(e: any) => {
              setUrl(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="headline"
            label={intl.formatMessage({
              id: "news.headline",
              defaultMessage: "Headline",
            })}
            maxLength={100}
            isValid={!!headline}
            value={headline}
            onChange={(e: any) => {
              setHeadline(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <div className="mb-4">
            <div className="flex flex-row items-start justify-start">
              <div className="">
                <FileUploadZone
                  label={intl.formatMessage({
                    id: "news.field.label.image",
                    defaultMessage: "Image",
                  })}
                  onFileUpdate={onFileUpdate}
                  required={true}
                  awaitsValidation={awaitsValidation}
                  validationMessage="news.validation.image.required"
                  isValid={!!image || !!props.data.imagePath}
                  onClear={() => {
                    setImage(undefined);
                  }}
                />
              </div>
              <img
                src={image ? URL.createObjectURL(image) : props.data.imagePath}
                className="h-40 ml-4 mt-6"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mb-4 w-1/3">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.schedule",
              defaultMessage: "Schedule",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />

          <SchedulingFormWidget
            onChange={(data) => {
              setPublicationDate(data.publicationDate);
              setRemovalDate(data.removalDate);
              setStatus(data.publicationStatus);
            }}
            awaitsValidation={props.awaitsValidation}
            publicationDate={publicationDate}
            removalDate={removalDate}
            publicationStatus={status}
          />
          {props.data.notificationStatus === "processed" ? (
            <AlreadyNotified />
          ) : (
            <NotificationFormWidget
              triggersNotification={triggersNotification}
              triggersImmediately={publicationDate < DateTime.local()}
              onChange={setTriggersNotification}
              notification={{
                applicationName: user.supplier,
                body: title,
                title: "News",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row items-center">
        {props.data.id ? (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: "news.edit.submit",
              defaultMessage: "Edit this news",
            })}
          />
        ) : (
          <Button
            onClick={onSubmit}
            disabled={false}
            iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
            label={intl.formatMessage({
              id: "news.add.submit",
              defaultMessage: "Add this news",
            })}
          />
        )}
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            type="secondary"
            label="cancel"
            iconName="BanIcon"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
