import React, { useEffect, useState } from "react";
import { Input } from "../../UIKit/form/Input";
import { FileUploadZone } from "../../UIKit/form/FileUploadZone";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { Button } from "../../UIKit/Button";
import { DateTime } from "luxon";
import { ProductNewsJournal, ProductNewsStatus } from "./ProductNews";
import { BONotificationPayload } from "../../app/Notifications";
import { SchedulingFormWidget } from "../../UIKit/form/SchedulingFormWidget";
import { useAuth } from "../../auth";
import { NotificationFormWidget } from "../../UIKit/form/NotificationFormWidget";
import { useIntl } from "react-intl";

export interface ProductNewsFormProps {
  awaitsValidation: boolean;
  data: productNewsDTO;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
  journal: ProductNewsJournal;
}

export interface productNewsDTO {
  title: string;
  productCode: string;
  image: File | undefined;
  imagePath?: string;
  headline: string;
  publicationDate: DateTime;
  triggersNotification?: boolean;
  removalDate?: DateTime;
  id?: string;
  status: ProductNewsStatus;
}

export function ProductNewsForm(props: ProductNewsFormProps) {
  let step = 1;
  const { user } = useAuth();
  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const [title, setTitle] = useState<string>(props.data.title);
  const [productCode, setProductCode] = useState<string>(
    props.data.productCode
  );
  const [headline, setHeadline] = useState<string>(props.data.headline);
  const [publicationDate, setPublicationDate] = useState<DateTime>(
    props.data.publicationDate ? props.data.publicationDate : DateTime.local()
  );
  const [removalDate, setRemovalDate] = useState<DateTime>(
    props.data.removalDate ? props.data.removalDate : DateTime.local()
  );
  const [image, setImage] = useState<File | undefined>(undefined);
  const [status, setStatus] = useState<ProductNewsStatus>(props.data.status);
  const [triggersNotification, setTriggersNotification] = useState<boolean>(
    !!props.data.triggersNotification
  );

  function onFileUpdate(file: File) {
    setImage(file);
  }

  function isValid(): boolean {
    return !!title && !!headline && !!publicationDate && !!status;
  }

  useEffect(() => {
    setTitle(props.data.title);
    setProductCode(props.data.productCode);
    setHeadline(props.data.headline);
    setPublicationDate(props.data.publicationDate);
    props.data.removalDate && setRemovalDate(props.data.removalDate);
    setStatus(props.data.status);
    setTriggersNotification(!!props.data.triggersNotification);
  }, [props.data]);

  const intl = useIntl();
  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const productNewsDTO: productNewsDTO = {
        title: title,
        productCode: productCode,
        image: image,
        imagePath: props.data.imagePath,
        headline: headline,
        publicationDate: publicationDate,
        removalDate: removalDate,
        id: props.data.id,
        triggersNotification: triggersNotification,
        status: status,
      };
      props.journal
        .save(productNewsDTO)
        .then((_savedNews) => {
          props.onSuccess();
        })
        .catch((reason) => props.notify({ type: "error", message: reason }));
    }
  }

  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.content",
              defaultMessage: "Content",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="title"
            label={intl.formatMessage({
              id: "productnews.title",
              defaultMessage: "Title",
            })}
            value={title}
            isValid={!!title}
            required={true}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="productCode"
            label={intl.formatMessage({
              id: "productnews.productCode",
              defaultMessage: "Product Code",
            })}
            value={productCode}
            isValid={!!productCode}
            required={true}
            onChange={(e: any) => {
              setProductCode(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="headline"
            label={intl.formatMessage({
              id: "productnews.headline",
              defaultMessage: "Headline",
            })}
            isValid={!!headline}
            value={headline}
            onChange={(e: any) => {
              setHeadline(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <div className="mb-4">
            <div className="flex flex-row items-start justify-start">
              <div className="">
                <FileUploadZone
                  label={intl.formatMessage({
                    id: "productnews.field.label.image",
                    defaultMessage: "Image",
                  })}
                  onFileUpdate={onFileUpdate}
                  required={false}
                  awaitsValidation={awaitsValidation}
                  validationMessage={intl.formatMessage({
                    id: "productnews.validation.image.required",
                    defaultMessage: "The image is mandatory",
                  })}
                  onClear={() => {
                    setImage(undefined);
                  }}
                />
              </div>
              <img
                src={image ? URL.createObjectURL(image) : props.data.imagePath}
                className="h-40 ml-4 mt-6"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mb-4 w-1/3">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.schedule",
              defaultMessage: "Schedule",
            })}
            step={step++}
            awaitsValidation={props.awaitsValidation}
          />
          <SchedulingFormWidget
            onChange={(data) => {
              setPublicationDate(data.publicationDate);
              setRemovalDate(data.removalDate);
              setStatus(data.publicationStatus);
            }}
            awaitsValidation={props.awaitsValidation}
            publicationDate={publicationDate}
            removalDate={removalDate}
            publicationStatus={status}
          />
          <NotificationFormWidget
            triggersNotification={triggersNotification}
            triggersImmediately={publicationDate < DateTime.local()}
            onChange={setTriggersNotification}
            notification={{
              applicationName: user.supplier,
              body: headline,
              title: "Nouveauté",
            }}
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <Button
          onClick={onSubmit}
          disabled={false}
          iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
          label={
            props.data.id
              ? intl.formatMessage({
                  id: "productnews.edit.submit",
                  defaultMessage: "Edit this new product",
                })
              : intl.formatMessage({
                  id: "productnews.add.submit",
                  defaultMessage: "Add this new product",
                })
          }
        />
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            type="secondary"
            label={intl.formatMessage({
              id: "cancel",
              defaultMessage: "cancel",
            })}
            iconName="BanIcon"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
