import React, { useState } from "react";
import { ColorPicker } from "../../UIKit/ColorPicker";
import { FormattedMessage } from "react-intl";
import { DynamicHeroIcon } from "../../UIKit/DynamicHeroIcon";

export function ConfigurationCardColorPicker(props: {
  title: string;
  initialValue: string;
  onSubmit: (value: string) => void;
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [color, setColor] = useState(props.initialValue);

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const triggerOutColorPickerTimer = () => {
    setTimer(
      setTimeout(() => {
        setDisplayColorPicker(false);
      }, 500)
    );
  };

  return (
    <div className="flex items-center justify-between bg-gray-100 text-base p-4 rounded-md gap-4">
      <div className="w-40">
        <FormattedMessage id={props.title} />
      </div>
      <div
        className="flex flex-row justify-center items-center"
        onMouseLeave={() => triggerOutColorPickerTimer()}
        onMouseEnter={() => clearTimeout(timer)}
      >
        <input
          className="rounded-lg justify-center p-4 h-10 mr-4"
          value={color}
          onInput={(input) => {
            setColor(input.currentTarget.value);
          }}
        />
        <div
          className="shadow rounded-lg w-10 h-10"
          style={{ backgroundColor: color }}
          onClick={toggleColorPicker}
        ></div>
        {displayColorPicker && (
          <div
            className="absolute transform translate-x-56 translate-y-20"
            style={{}}
          >
            <ColorPicker color={color} onChange={setColor} />
          </div>
        )}
      </div>
      <div className="flex justify-end w-40">
        <div className="h-8 w-8">
          <button
            className={
              "relative p-2 text-secondary rounded-full h-full w-full " +
              (color === props.initialValue
                ? "bg-primary-50"
                : "bg-primary-300 animate-bounce")
            }
            disabled={color === props.initialValue}
            onClick={() => {
              props.onSubmit(color);
            }}
          >
            <DynamicHeroIcon icon={"CheckIcon"} width={"4"} />
          </button>
        </div>
      </div>
    </div>
  );
}
