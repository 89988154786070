import { makeServer } from "./server";

export const APP_CONFIG = {
  API_BASE_URI: process.env.REACT_APP_API_BASE_URI,
  OAUTH_BASE_URI: process.env.REACT_APP_OAUTH_BASE_URI,
  OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
  ENVIRONMENT: process.env.REACT_APP_ENV,
};

export const init: () => void =
  APP_CONFIG.ENVIRONMENT !== "production" && APP_CONFIG.API_BASE_URI==="http://localhost:3000"
    ? () => makeServer({ environment: APP_CONFIG.ENVIRONMENT })
    : () => {};
