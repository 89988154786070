import React from "react";

interface switchProps {
  choice1Label: string;
  choice2Label: string;
  choice1Value: any;
  choice2Value: any;
  value: any;
  name: string;
  onChange: (value: any) => void;
}

export function Switch(props: switchProps) {
  function onSelect(e: any) {
    props.onChange(e.target.value);
  }

  return (
    <div className="flex flex-col">
      <label className="mr-4">
        <input
          type="radio"
          name={props.name}
          value={props.choice1Value}
          checked={props.value === props.choice1Value}
          onChange={onSelect}
        />
        <span className="ml-1">{props.choice1Label}</span>
      </label>
      <label>
        <input
          type="radio"
          name={props.name}
          value={props.choice2Value}
          checked={props.value === props.choice2Value}
          onChange={onSelect}
        />
        <span className="ml-1">{props.choice2Label}</span>
      </label>
    </div>
  );
}
