import { ConfigurationCardText } from "../../UIKit/ConfigurationCardText";
import { Toggle } from "../../UIKit/Toggle";
import React from "react";
import { appConfiguration } from "../Apps/Application";
import { ConfigurationCardColorPicker } from "./ConfigurationCardColorPicker";
import { ConfigurationCardMultipleValue } from "./ConfigurationCardMultipleValue";

export interface ResolverConfigurationFormProps {
  configuration: appConfiguration;
  onSubmit: (feature: string, values: string[]) => void;
}

export function ResolverConfigurationForm(
  props: ResolverConfigurationFormProps
) {
  if (props.configuration.type === "BOOLEAN") {
    return (
      <Toggle
        label={props.configuration.key}
        toggled={props.configuration.values[0] === "true"}
        onToggle={(value) => {
          props.onSubmit(props.configuration.key, [value ? "true" : "false"]);
        }}
      />
    );
  } else if (props.configuration.type === "COLOR") {
    return (
      <ConfigurationCardColorPicker
        title={props.configuration.key}
        initialValue={props.configuration.values[0]}
        onSubmit={(value) => {
          props.onSubmit(props.configuration.key, [value]);
        }}
      />
    );
  } else if (props.configuration.type === "MULTIPLE_STRING") {
    return (
      <ConfigurationCardMultipleValue
        title={props.configuration.key}
        initialValues={props.configuration.values}
        onSubmit={(value) => {
          props.onSubmit(props.configuration.key, value);
        }}
      />
    );
  } else {
    return (
      <ConfigurationCardText
        title={props.configuration.key}
        type={props.configuration.type}
        initialValue={props.configuration.values[0]}
        onSubmit={(feature, value) => {
          props.onSubmit(feature, [value]);
        }}
      />
    );
  }
}
