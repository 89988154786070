import { Link } from "react-router-dom";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MetricValue } from "../modules/Metrics/Metric";

export interface MetricCardContent {
  title: string;
  currentValue: MetricValue;
  trendGood?: string;
  trendBad?: string;
  linkText?: string;
  destination?: string;
}

export function MetricCardNumber(props: { content: MetricCardContent }) {
  return (
    <div className="rounded-lg  bg-gray-50">
      <div className="px-4 py-2">
        <dt className="text-2xl flex justify-between items-center">
          <div>{props.content.title}</div>
          {(!!props.content.trendBad || !!props.content.trendGood) && (
            <div>
              <div className="ml-2 text-xl font-bold">
                {!!props.content.trendGood && (
                  <div className="text-green-500">
                    {props.content.trendGood}
                  </div>
                )}
                {!!props.content.trendBad && (
                  <div className="text-red-500">{props.content.trendBad}</div>
                )}
              </div>
              <div className="text-xs">sur 7 jours</div>
            </div>
          )}
        </dt>
        <dd>
          <div className="flex items-end">
            <div className="text-6xl font-bold">
              {props.content.currentValue.value}
            </div>
          </div>
        </dd>
      </div>
      {!!props.content.destination && (
        <div className="bg-gray-200 p-4 rounded-b-lg text-xl">
          <Link
            to={props.content.destination}
            className="text-blue-400 font-bold"
          >
            &gt; <FormattedMessage id={props.content.linkText} />
          </Link>
        </div>
      )}
    </div>
  );
}
