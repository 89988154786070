import React, { useState } from "react";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Contractor,
  ContractorsDirectory,
  contractorsPageResponse,
} from "./Contractors";

interface ContractorsListProps {
  directory: ContractorsDirectory;
  columns: tableColumn<Contractor>[];
}

export function ContractorsList(props: ContractorsListProps) {
  const contractorsPerPage = 20;
  const intl = useIntl();
  const [startingAt, setStartingAt] = useState<number>(1);
  const {
    isFetching: contractorsAreFetching,
    data: contractorsPage,
  } = useQuery<contractorsPageResponse>(
    "contractors::all::page::" + startingAt,
    () => {
      return props.directory.findPaginated(
        (startingAt - 1) * contractorsPerPage + 1,
        contractorsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadContractorsSlice(number: number) {
    setStartingAt(number);
  }

  if (!contractorsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "contractors.checking.contractors",
            defaultMessage: "Loading pro accounts",
          })}
        />
      </div>
    );
  } else {
    const contractors = contractorsPage.items;
    const contractorsStats = contractorsPage.stats;
    return (
      <>
        {contractors.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="contractors.no.contractor.yet.1"
                  defaultMessage="No professional at this time"
                />
              </p>
              <p>
                <FormattedMessage
                  id="contractors.no.contractor.yet.2"
                  defaultMessage="...it can't last!"
                />
              </p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="contractors.count.plural"
                  defaultMessage="{num, plural, one {# professional} other {# professionals}}"
                  values={{ num: contractorsStats.totalCount }}
                />
              </h2>
              {contractorsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "contractors.checking.contractors",
                      defaultMessage: "Loading pro accounts",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Contractor>
              onPageChange={loadContractorsSlice}
              totalItemsCount={contractorsStats.totalCount || 0}
              itemsPerPage={contractorsPerPage}
              data={contractors}
              page={startingAt}
              columns={props.columns}
            />
          </div>
        )}
      </>
    );
  }
}
