import { APICataloguesProvider } from "./CataloguesDirectoryAPI";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { DealersImportConversionReport } from "./Dealers";
import { Loading } from "../../UIKit/Loading";
import { TriCard } from "../../UIKit/TriCard";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { ConversionReportNotAvailable } from "./ConversionReportNotAvailable";

export function DealerImportConversionReport(props: {
  cataloguesProvider: APICataloguesProvider;
}) {
  const { dealersImportId } = useParams<{ dealersImportId: string }>();
  const intl = useIntl();
  try {
    const {
      isFetching: reportIsFetching,
      data: conversionReport,
    } = useQuery<DealersImportConversionReport>(
      "dealersImport::conversionReport::" + dealersImportId,
      () => {
        return props.cataloguesProvider.getDealersImportReport(dealersImportId);
      }
    );
    if (!conversionReport) {
      return (
        <div className="min-h-screen flex flex-col justify-center items-center">
          <Loading
            label={intl.formatMessage({
              id: "dealersImport.report.fetching.report",
              defaultMessage: "Loading report...",
            })}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex justify-between">
            <TriCard
              label={intl.formatMessage({
                id: "dealersImport.report.number.of.valid.dealers",
                defaultMessage: "Number of valid dealers",
              })}
              iconName="BadgeCheckIcon"
              value={conversionReport.processableItemsCount?.toString()}
            />
            <TriCard
              label={intl.formatMessage({
                id: "dealersImport.report.number.of.errored.dealers",
                defaultMessage: "Number of invalid dealers",
              })}
              value={conversionReport.itemsWithErrorsCount?.toString()}
              iconName="ExclamationCircleIcon"
            />
            <TriCard
              label={intl.formatMessage({
                id: "dealersImport.report.number.of.dealers",
                defaultMessage: "Number of dealers found",
              })}
              iconName="StarIcon"
              value={conversionReport.itemsCount?.toString()}
            />
          </div>
          {conversionReport.fileErrors?.map((e) => (
            <TriCard label={e} iconName="ExclamationIcon" />
          ))}
          {conversionReport.errors &&
            Object.entries(conversionReport.errors).map(
              ([lineNumber, error]) => (
                <div className="hover:bg-gray-50 mb-4 bg-white shadow overflow-hidden sm:rounded-lg flex flex-row items-center">
                  <div className="transform -rotate-90 origin-center text-center">
                    <FormattedMessage id="dealer" defaultMessage="Dealer" />
                  </div>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      <FormattedMessage
                        id="dealersImport.report.line"
                        defaultMessage="Line"
                      />{" "}
                      <span className="inline-flex bg-primary-400 rounded-full p-2">
                        {lineNumber}
                      </span>
                    </h3>
                  </div>
                  <div className="border-l border-gray-200 flex-1">
                    <dl>
                      {error.violations.map((violation) => {
                        return (
                          <div className="bg-gray-50 px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300">
                            <dt className="text-sm font-medium text-gray-500">
                              <FormattedMessage
                                id={
                                  "dealersImport.report." +
                                  violation.propertyPath
                                }
                              />
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <FormattedMessage
                                id={"dealersImport.report." + violation.title}
                              />
                            </dd>
                          </div>
                        );
                      })}
                    </dl>
                  </div>
                </div>
              )
            )}
        </div>
      );
    }
  } catch (e) {
    if (e instanceof ConversionReportNotAvailable) {
      return <div>report not available for import {dealersImportId}</div>;
    }
    throw e;
  }
}
