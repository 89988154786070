import React, { HTMLAttributes, useEffect, useState } from "react";
import { validableFormWidgetProps } from "./forms";
import { InputLabel } from "./InputLabel";
import { UI_Size } from "../definitions";

export interface InputProps
  extends validableFormWidgetProps,
    HTMLAttributes<HTMLElement> {
  name: string;
  label: string;
  type?: string;
  size?: UI_Size;
  required?: boolean;
  autofocus?: boolean;
  testid?: string;
  value?: string;
  prefix?: string;
  disabled?: boolean;
  suffix?: string;
  maxLength?: number;
  onChange: (e: any) => void;
  followChanges?: boolean;
}

interface InputFieldProps
  extends validableFormWidgetProps,
    HTMLAttributes<HTMLElement> {
  onFocus?: (e: any) => void;
  placeholder?: string;
  name: string;
  id?: string;
  required?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  testid?: string;
  size?: UI_Size;
  type?: string;
  value?: string;
  prefix?: string;
  suffix?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  className?: string;
  maxLength?: number;
  followChanges?: boolean;
}

export function InputField(props: InputFieldProps) {
  const invalidClassNames =
    props.awaitsValidation || props.isValid
      ? " focus:ring-blue-700 focus:border-blue-500"
      : " ring-red-500 border-red-500 bg-red-50";
  return (
    <div className="mt-1 flex rounded-md shadow-sm">
      {props.prefix && (
        <span
          className={
            "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500" +
            (props.size === "small" ? " px-2 text-sm " : " px-3 text-base ")
          }
        >
          {props.prefix}
        </span>
      )}
      <input
        id={props.id}
        data-testid={props.testid}
        type={props.type || "text"}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        autoFocus={props.autofocus}
        required={props.required}
        onBlur={props.onBlur}
        maxLength={props.maxLength}
        onChange={props.onChange}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        className={
          "z-10 flex-1 block rounded border-gray-300 " +
          (props.prefix && " rounded-l-none ") +
          (props.suffix && " rounded-r-none ") +
          (invalidClassNames || "") +
          (props.className || "") +
          (props.size === "small" ? " p-1 px-2 text-sm " : " text-base ") +
          (props.disabled === true ? " bg-gray-200 " : "")
        }
      />
      {props.suffix && (
        <span
          className={
            "z-0 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500" +
            (props.size === "small" ? " px-2 text-sm " : " px-3 text-base ")
          }
        >
          {props.suffix}
        </span>
      )}
    </div>
  );
}

export function Input(props: InputProps) {
  const [value, setValue] = useState(props.value);
  const uniqid = Math.random().toString();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function onChange(e: any) {
    if (props.followChanges) {
      props.onChange(e);
    } else {
      setValue(e.target.value);
    }
  }
  function onBlur(e: any) {
    props.onChange(e);
  }

  return (
    <div className={"mb-2" + props.className}>
      <div className="col-span-3 sm:col-span-2">
        <div className="flex justify-between items-end">
          <InputLabel
            label={props.label}
            htmlFor={uniqid}
            required={props.required}
            size={props.size}
            isValid={props.isValid}
            awaitsValidation={props.awaitsValidation}
            validationMessage={props.validationMessage}
          />
          {!!props.maxLength && (
            <div className="text-xs">
              {value?.length}/{props.maxLength}
            </div>
          )}
        </div>

        <InputField
          id={uniqid}
          testid={props.testid}
          isValid={props.isValid}
          awaitsValidation={props.awaitsValidation}
          validationMessage={props.validationMessage}
          type={props.type}
          name={props.name}
          value={value}
          size={props.size}
          placeholder={props.placeholder}
          autofocus={props.autofocus}
          required={props.required}
          disabled={props.disabled}
          onBlur={onBlur}
          onChange={onChange}
          prefix={props.prefix}
          maxLength={props.maxLength}
          suffix={props.suffix}
        />
      </div>
    </div>
  );
}
