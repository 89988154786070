import React from "react";

export function FAQ() {
  return (
    <article className="prose">
      <h2>Faut-il fournir autre chose que les données produit ?</h2>
      <p>Oui !</p>
      <p>Nous avons besoin de quelques éléments complémentaires afin de pouvoir finaliser le
        déploiement d’une application qui soit en phase avec votre charte
        graphique : <ul>
          <li>votre logo, dans un format vectoriel (idéalement SVG, sinon
            PDF), à défaut en PNG.
          </li>
          <li>les codes hexadécimaux de 3 couleurs : couleur
            principale couleur de fond couleur “focus”
          </li>
          <li>le nom que vous souhaitez
            donner à votre application (il doit être court, il accompagne les icônes
            sur l’écran des téléphones de vos utilisateurs. Votre nom de société peut
            évidemment être utilisé)
          </li>
        </ul></p>

      <h2>Qui peut m’aider chez DirBat ?</h2>
      <p>Notre équipe technique est bien évidemment à votre disposition pour vous assister dans
        la mise en place des échanges de données avec DirBat.</p>
      <p>Envoyez un email à <a href="mailto:tech@dirbat.com">tech@dirbat.com</a> avec la description de votre
        problématique, les
        coordonnées d’un interlocuteur dans votre organisation, et idéalement un
        exemple détaillé (fichier, documentation...) afin que nous puissions
        revenir vers vous.</p>
    </article>
  );
}
