import React, { useState } from "react";
import { useQuery } from "react-query";
import { Loading } from "../UIKit/Loading";
import { CenterInScreen } from "../UIKit/CenterInScreen";
import { FormattedMessage } from "react-intl";
import { PaginatedTable, tableColumn } from "../UIKit/Table";
import { BONotificationPayload } from "../app/Notifications";
import { Repository } from "../modules/Repository";
import { PageResponse } from "../modules/commonTypes";

interface listMessages {
  empty2: string;
  empty1: string;
  countPlural: string;
  loading: string;
}

interface ListProps<T> {
  directory: Repository<T>;
  columns: tableColumn<T>[];
  notify: (not: BONotificationPayload) => void;
  messages: listMessages;
}

export function List<T>(props: ListProps<T>) {
  const itemsPerPage = 20;
  const [startingAt, setStartingAt] = useState<number>(1);
  const { isFetching: itemsAreFetching, data: page } = useQuery<
    PageResponse<T>
  >(
    "contactMessages::all::page::" + startingAt,
    () => {
      return props.directory.findPaginated(
        (startingAt - 1) * itemsPerPage + 1,
        itemsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadItemsSlice(number: number) {
    setStartingAt(number);
  }

  if (!page) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading label={props.messages.loading} />
      </div>
    );
  } else {
    const items = page.items;
    const itemsStats = page.stats;
    return (
      <>
        {items.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>{props.messages.empty1}</p>
              <p>{props.messages.empty2}</p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id={props.messages.countPlural}
                  values={{ num: itemsStats.totalCount }}
                />
              </h2>
              {itemsAreFetching && (
                <div className="items-center">
                  <Loading label={props.messages.loading} />
                </div>
              )}
            </div>
            <PaginatedTable<T>
              onPageChange={loadItemsSlice}
              totalItemsCount={itemsStats.totalCount || 0}
              itemsPerPage={itemsPerPage}
              data={items}
              page={startingAt}
              columns={[...props.columns]}
            />
          </div>
        )}
      </>
    );
  }
}
