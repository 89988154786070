import React from "react";
import { useParams } from "react-router-dom";
import { DownloadAssetLink } from "./DocumentationModule";

export function DealersDocumentation() {
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  return (
    <div>
      <article className="prose max-w-none">
        <h1>Dealers management</h1>
        <h2>How to import your dealers data into DirBat?</h2>
      </article>
      <article className="prose">
        <p>
          Dealers are updated by importing your data in Excel format, via your
          back-office interface.
        </p>
        <p>
          Updates are made incrementally:
          <br />
          importing a file adds new dealers, and updates existing dealers if
          necessary.
          <br />
          You can therefore make one-off corrections to a selection of dealers
          by re-importing a file containing only this selection.
        </p>
        <p>
          The excel file must include one (1) tab, necessarily named "
          <strong>dealers</strong>".
          <br />
          You can download an example of a dealer file by{" "}
          <DownloadAssetLink
            assetURL="/sample_dealers_dirbat_xls.xlsx"
            fileName={"dirbatDealersSample.xlsx"}
            label="clicking here"
          />
        </p>
      </article>
      <article className="prose max-w-none">
        <div className="flex flex-row items-center justify-start bg-gray-100 p-4 rounded mb-8 mt-8">
          <div className="text-4xl pr-4">⚠️</div>
          <div>
            Beware to respect <strong>the naming</strong> ot sheets and columns.
            <br />
            The import modules analyses your file and searches for the elements
            based on their <em>name</em>, not on their <em>location</em>.
          </div>
        </div>
      </article>
      <article className="prose">
        <h2>Sheet DEALERS</h2>
        <p>
          The <em>dealers</em> sheet MUST includes the{" "}
          <strong>10 columns</strong> detailed in the following table :
        </p>
      </article>
      <article className="prose max-w-none">
        <table className="">
          <thead className="sticky top-16 bg-white">
            <tr>
              <th>Column name</th>
              <th>description</th>
              <th>type</th>
              <th>mandatory</th>
              <th>
                Unique&nbsp;
                <a href={"#note1"} className="">
                  1
                </a>
              </th>
              <th>Sample(s)</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colSpan={7}>
                <p>
                  <a id="note1" />a field marked as{" "}
                  <em>
                    <strong>unique</strong>
                  </em>{" "}
                  implies that the concerned value must be present only once for
                  all your dealers.
                </p>
              </td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td className="font-bold">internalCode</td>
              <td>Your internal code for this dealer</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>unique</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  DIS234
                </div>
              </td>
              <td>
                This code allows you to update your data during successive
                imports. It can be a code from your CRM, or ERP
              </td>
            </tr>
            <tr>
              <td className="font-bold">name</td>
              <td>Dealer's name</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>-</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Point P Orléans
                </div>
              </td>
              <td>The displayed name of the dealer</td>
            </tr>
            <tr>
              <td className="font-bold">address</td>
              <td>Physical address of the dealer</td>
              <td>text(600)</td>
              <td>mandatory</td>
              <td>&nbsp;</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  12, route de Paris
                </div>
              </td>
              <td rowSpan={3}>
                These coordinates are used to geocode the GPS coordinates of the
                dealer
              </td>
            </tr>
            <tr>
              <td className="font-bold">city</td>
              <td>City</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>&nbsp;</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Orléans
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-bold">zipcode</td>
              <td>Postal code</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  45000
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-bold">country</td>
              <td>Country code</td>
              <td>text(2)</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  fr
                </div>
              </td>
              <td>Use ISO_3166-1 country codes on 2 letters</td>
            </tr>
            <tr>
              <td className="font-bold">phone</td>
              <td>Phone number</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  01 02 03 04 05
                </div>
              </td>
              <td>
                Indicate preferably the "generic" phone of your dealer, rather
                than the one of your direct contacts.
              </td>
            </tr>
            <tr>
              <td className="font-bold">lat</td>
              <td>Latitude</td>
              <td>text(20)</td>
              <td>optional</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  46.9443234
                </div>
              </td>
              <td rowSpan={2}>
                The coordinates GPS are useful if you notice that the location
                determined automatically is not correct.
              </td>
            </tr>
            <tr>
              <td className="font-bold">lng</td>
              <td>Longitude</td>
              <td>text(20)</td>
              <td>optional</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  -1.344323
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-bold">website</td>
              <td>Website</td>
              <td>Public URL</td>
              <td>optional</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://www.pointp.fr/infos-agence/orleans-centre-point-p-3413
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </article>
    </div>
  );
}
