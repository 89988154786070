import React from "react";

export function RadioChoice<T>(props: {
  label: string;
  value: T;
  checked?: boolean;
  onCheck: (value: T) => void;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={
        (props.checked
          ? " bg-gray-100 border-primary-400 "
          : " hover:border-gray-200 border-transparent ") +
        " lg:w-1/3 group flex flex-row bg-gray-50 border rounded p-4 mr-8 "
      }
      onClick={() => props.onCheck(props.value)}
    >
      <div
        className={
          (props.checked ? " bg-primary-400 " : "") +
          " cursor-pointer flex-shrink-0 shadow-md rounded bg-white w-24 h-24 text-center flex-col flex items-center justify-center"
        }
      >
        <div className={props.checked ? " font-bold " : ""}>{props.label}</div>
      </div>
      <div className="ml-4 flex-shrink">{props.children}</div>
    </div>
  );
}
