import React, { Component, ErrorInfo, ReactNode } from "react";
import { CenterInScreen } from "./UIKit/CenterInScreen";
import {
  NotAuthenticatedError,
  NotAuthorizedError,
  ServerError,
} from "./utilities/APIClient";
import { Button } from "./UIKit/Button";
import { Redirect } from "react-router-dom";
import { AppRoutes } from "./app/Routes";
import { ConversionReportNotAvailable } from "./modules/Catalogues/ConversionReportNotAvailable";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorCode?: string;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(e: Error): State {
    if (e instanceof NotAuthenticatedError) {
      return { hasError: true, errorCode: "401" };
    }
    if (e instanceof NotAuthorizedError) {
      return { hasError: true, errorCode: "403" };
    }
    if (e instanceof ConversionReportNotAvailable) {
      return { hasError: true, errorCode: "404" };
    }
    if (e instanceof ServerError) {
      return { hasError: true, errorCode: "500" };
    }
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      if (this.state.errorCode === "403") {
        this.setState({ hasError: false });
        // todo : handle logout properly
        return <Redirect to={AppRoutes().logout} />;
      }
      if (this.state.errorCode === "401") {
        return (
          <CenterInScreen>
            <h1>
              Votre session a expiré.
              <br />
              Vous allez devoir vous reconnecter.
              <br />
              <Button
                size="small"
                label="Reconnection"
                onClick={() => this.setState({ hasError: false })}
              />
            </h1>
          </CenterInScreen>
        );
      }
      if (this.state.errorCode === "404") {
        return (
          <CenterInScreen>
            <h1>
              La ressource demandée n'a pas été trouvée :-(
              <br />
              <Button
                size="small"
                label="continuer"
                onClick={() => this.setState({ hasError: false })}
              />
            </h1>
          </CenterInScreen>
        );
      }
      return (
        <CenterInScreen>
          <h1>
            Nous avons un problème.
            <br />
            Quelque chose ne s'est pas passé comme prévu.
          </h1>
        </CenterInScreen>
      );
    }
    return <>{this.props.children}</>;
  }
}
