import { AppRoutes } from "../../app/Routes";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).community;

const routes = (clientId: string, supplierId: string) => {
  return {
    dashboard: base(clientId, supplierId),
    contacts: `${base(clientId, supplierId)}/contacts`,
    contractors: `${base(clientId, supplierId)}/contractors`,
    offers: `${base(clientId, supplierId)}/offers`,
  };
};

export default routes;
