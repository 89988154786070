import { TeamRoutes } from "../../app/Routes";

const base = TeamRoutes().apps;

const routes = (clientId?: string) => {
  return {
    apps: base,
    configure: `${base}/${clientId}/configure`,
  };
};

export default routes;
