import React, { HTMLAttributes, useRef, useState } from "react";
import { Button } from "../Button";
import { validableFormWidgetProps } from "./forms";
import { InputLabel } from "./InputLabel";
import { useIntl } from "react-intl";

function extractFiles(fileList: FileList) {
  const files: File[] = [];
  for (var i = 0; i < fileList.length; i++) {
    files.push(fileList.item(i)!);
  }
  return files;
}

export interface FileUploadZoneProps
  extends validableFormWidgetProps,
    HTMLAttributes<HTMLElement> {
  onFileUpdate: (file: File) => void;
  onClear: () => void;
  label: string;
}

function fileIsAnImage(f: File) {
  return f.type.match(/^image\/(.*)/);
}

export function FileUploadZone(props: FileUploadZoneProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef(null);
  const intl = useIntl();
  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const extractedFiles = extractFiles(e.target.files);
      setFiles(extractedFiles);
      props.onFileUpdate(extractedFiles[0]);
    }
  }

  function onDrop(e: React.DragEvent<HTMLLabelElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files) {
      setFiles(extractFiles(e.dataTransfer.files));
    }
  }

  function onDragOver(e: any) {
    e.preventDefault();
    setFocused(true);
  }

  function clearInput(input: HTMLInputElement) {
    input.value = "";
    setFiles([]);
    props.onClear();
  }

  return (
    <div className="">
      <InputLabel
        label={props.label}
        required={props.required}
        isValid={props.isValid}
        awaitsValidation={props.awaitsValidation}
        validationMessage={props.validationMessage}
      />
      <label
        htmlFor="file"
        className={
          "inline-block border-dashed border-2 h-40 p-4 flex items-center justify-center " +
          (focused ? " border-primary-400" : "") +
          (!props.isValid && !props.awaitsValidation ? " border-red-400" : "")
        }
        onDragOver={onDragOver}
        onDragExit={() => {
          setFocused(false);
        }}
        onDrop={(e) => onDrop(e)}
        onMouseOver={() => setFocused(true)}
        onMouseOut={() => setFocused(false)}
      >
        {files.length ? (
          <div className="flex flex-col items-center justify-center h-40">
            {files.map((f, k) => {
              return (
                <div key={k} className="">
                  {f.name}
                </div>
              );
            })}
            <div>
              <Button
                label={intl.formatMessage({
                  id: "forms.filezone.change",
                  defaultMessage: "change",
                })}
                iconName="SwitchHorizontalIcon"
                size="tiny"
                type="secondary"
                onClick={() => {
                  // @ts-ignore
                  inputRef.current.click();
                }}
              />
              <div className="ml-4">
                <Button
                  label={intl.formatMessage({
                    id: "forms.filezone.remove",
                    defaultMessage: "delete",
                  })}
                  iconName="XIcon"
                  size="tiny"
                  type="secondary"
                  onClick={() => {
                    clearInput(inputRef.current!);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <strong className="font-bold text-primary-400">
              {intl.formatMessage({
                id: "upload.widget.choose.file",
                defaultMessage: "Choose a file",
              })}
            </strong>
            <span className="">
              {" "}
              {intl.formatMessage({
                id: "upload.widget.drop.here",
                defaultMessage: "or drag it here",
              })}
            </span>
          </div>
        )}
      </label>
      <input
        ref={inputRef}
        className="w-0 h-0 absolute"
        type="file"
        name="files"
        id="file"
        onChange={onFileChange}
      />
    </div>
  );
}
