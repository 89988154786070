import * as HeroIcons from "@heroicons/react/solid";

export type IconName = keyof typeof HeroIcons;
interface IconProps {
  icon: IconName;
  width: string;
}

export const DynamicHeroIcon = ({ icon, width }: IconProps) => {
  const SingleIcon = HeroIcons[icon];

  return <SingleIcon className={`w-${width} h-${width}`} />;
};
