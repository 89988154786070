import React, { useEffect, useState } from "react";
import { BellIcon } from "@heroicons/react/solid";
import { Switch } from "./Swich";
import { FormattedMessage, useIntl } from "react-intl";

function NotificationPreview(props: { notification: PushNotification }) {
  return (
    <article className="my-4">
      <div className="text-xs text-right italic pb-1">
        <FormattedMessage
          defaultMessage="Preview of the notification, appearance may vary based on the terminal"
          id="notification.preview.comment"
        />
      </div>
      <div className="bg-gray-100 rounded shadow-lg p-4">
        <p className="uppercase space-x-12">
          <BellIcon className="w-5 inline-block mr-1" />
          {props.notification.applicationName}
        </p>
        <h3 className="font-bold">{props.notification.title}</h3>
        <p>{props.notification.body}</p>
      </div>
    </article>
  );
}

export function NotificationFormWidget(props: {
  triggersNotification: boolean;
  triggersImmediately: boolean;
  onChange: (value: boolean) => void;
  notification: PushNotification;
}) {
  const [triggersNotification, setTriggersNotification] = useState<boolean>(
    props.triggersNotification
  );
  const intl = useIntl();
  useEffect(() => {
    props.onChange(triggersNotification);
  }, [triggersNotification]);
  useEffect(() => {
    setTriggersNotification(props.triggersNotification);
  }, [props.triggersNotification]);
  return (
    <div className="mt-6 ">
      <h3 className="font-bold mb-1">
        <BellIcon className="w-5 inline" /> Notifications
      </h3>
      <Switch
        choice1Label={
          props.triggersImmediately
            ? intl.formatMessage({
                id: "notification.notify.immediately",
                defaultMessage:
                  "notify immediately (as soon as I save this item)",
              })
            : intl.formatMessage({
                id: "notification.notify.upon.publication",
                defaultMessage: "notify on the day of publication",
              })
        }
        choice1Value={"notify"}
        choice2Label={intl.formatMessage({
          id: "notification.no.notification",
          defaultMessage: "No notification",
        })}
        choice2Value={"silent"}
        onChange={(val) => setTriggersNotification(val === "notify")}
        value={triggersNotification ? "notify" : "silent"}
        name="triggersNotification"
      />
      {triggersNotification && (
        <NotificationPreview notification={props.notification} />
      )}
    </div>
  );
}

export interface PushNotification {
  title: string;
  body: string;
  applicationName: string;
}

export function AlreadyNotified() {
  return (
    <div className="mt-6 ">
      <h3 className="font-bold mb-1">
        <BellIcon className="w-5 inline" /> Notifications
      </h3>
      <p>
        <FormattedMessage
          id="notification.item.already.notified"
          defaultMessage="This item has already been notified"
        />
      </p>
    </div>
  );
}
