import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { ProductNewsList } from "./ProductNewsList";
import { ProductNewsJournalAPI } from "./ProductNewsJournalAPI";
import {
  EMPTY_PRODUCT_NEWS,
  ProductNews,
  ProductNewsJournal,
} from "./ProductNews";
import { Badge } from "../../UIKit/Badge";
import { DateTime } from "luxon";
import { productNewsDTO, ProductNewsForm } from "./ProductNewsForm";
import {
  getBadgeType,
  getFlavorLabel,
  getPublicationDateLabel,
  getRemovalDateLabel,
  getStatusLabel,
  tableColumn,
} from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { BONotificationPayload } from "../../app/Notifications";
import { Schedulable } from "../News/News";

const newsArticle: productNewsDTO = {
  headline: "",
  id: "",
  image: undefined,
  imagePath: "",
  publicationDate: DateTime.local(),
  removalDate: DateTime.local(),
  status: "draft",
  title: "",
  productCode: "",
};

export function ProductNewsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const intl = useIntl();
  const journal = new ProductNewsJournalAPI(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const productNewsColumns: tableColumn<ProductNews>[] = [
    {
      label: intl.formatMessage({
        id: "productnews.title",
        defaultMessage: "Title",
      }),
      content: (news: ProductNews) => news.title,
    },
    {
      label: intl.formatMessage({
        id: "productnews.productCode",
        defaultMessage: "Product code",
      }),
      content: (news: ProductNews) => news.productCode,
    },
    {
      label: intl.formatMessage({
        id: "schedulable.status",
        defaultMessage: "Status",
      }),
      content: (item: Schedulable) => (
        <Badge
          label={getStatusLabel(item, intl)}
          flavor={getFlavorLabel(item)}
          type={getBadgeType(item)}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.publication.date",
        defaultMessage: "Publication date",
      }),
      content: (item: Schedulable, locale) =>
        getPublicationDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.removal.date",
        defaultMessage: "Removal date",
      }),
      content: (item: Schedulable, locale) => getRemovalDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "productnews.image",
        defaultMessage: "Image",
      }),
      align: "center",
      content: (productNews: ProductNews) =>
        productNews.photo ? (
          <img
            alt={productNews.title}
            src={productNews.photo}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        ) : (
          ""
        ),
    },
  ];

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="productnews.add.title"
            defaultMessage="Add a new product"
          />
        </h1>
        <ProductNewsForm
          journal={journal}
          awaitsValidation={true}
          data={newsArticle}
          onSuccess={() => {
            history.push(ModuleRoutes(clientId, supplierId).productnews);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="productnews.edit.title"
            defaultMessage="Edit a new product"
          />
        </h1>
        <EditProductNews
          journal={journal}
          notify={props.notify}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").productnews} exact>
        <ProductNewsList
          journal={journal}
          columns={productNewsColumns}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
    </Switch>
  );
}

function EditProductNews(props: {
  journal: ProductNewsJournal;
  notify: (not: BONotificationPayload) => void;
  clientId: string;
  supplierId: string;
}) {
  const [productNews, setProductNews] = useState<ProductNews>(
    EMPTY_PRODUCT_NEWS
  );
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setProductNews(await props.journal.findOneById(id)))();
  }, [id]);
  const history = useHistory();

  const dto: productNewsDTO = {
    headline: productNews.headline,
    id: productNews.id,
    image: undefined,
    imagePath: productNews.photo,
    triggersNotification: productNews.triggersNotification,
    publicationDate: productNews.publicationDate,
    removalDate: productNews.removalDate,
    status: productNews.status,
    title: productNews.title,
    productCode: productNews.productCode,
  };
  return (
    <ProductNewsForm
      journal={props.journal}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(
          ModuleRoutes(props.clientId, props.supplierId).productnews
        );
      }}
    />
  );
}
