import React, { ReactElement } from "react";
import { CenterInScreen } from "./CenterInScreen";
import { Button } from "./Button";
import { SpinnerLoading } from "./SpinnerLoading";
import { Portal } from "./Portal";

export function Modal(props: {
  onCancel: () => void;
  onConfirm: () => void;
  disabled?: boolean;
  mainButtonLabel?: string;
  altButtonLabel?: string;
  children: ReactElement;
  loading?: boolean;
}) {
  return (
    <Portal className="modal-root" el="div">
      <div className="fixed top-0 z-50 w-screen h-screen bg-gray-100 bg-opacity-80">
        <CenterInScreen>
          <div className=" shadow-lg bg-white max-w-10/12  max-h-screen  items-center justify-between flex flex-col rounded-lg overflow-scroll">
            <div className="flex flex-col flex-1 justify-center p-8 w-full">
              <div className="relative">
                {!!props.loading && (
                  <div className="absolute w-full h-full bg-white bg-opacity-90 z-50 flex items-center justify-center">
                    <SpinnerLoading />
                  </div>
                )}
                {props.children}
              </div>
            </div>
            {(!!props.mainButtonLabel || !!props.altButtonLabel) && (
              <div className="rounded-b-lg bg-gray-50 p-4 w-full items-center flex justify-end">
                {props.altButtonLabel && (
                  <div className="mr-4">
                    <Button
                      onClick={props.onCancel}
                      type="cancel"
                      label={props.altButtonLabel}
                    />
                  </div>
                )}
                {props.mainButtonLabel && (
                  <Button
                    onClick={!props.disabled ? props.onConfirm : () => {}}
                    disabled={!!props.disabled}
                    label={props.mainButtonLabel}
                  />
                )}
              </div>
            )}
          </div>
        </CenterInScreen>
      </div>
    </Portal>
  );
}
