import { AppRoutes } from "../../app/Routes";

const base = (clientId: string, supplierId: string) =>
  AppRoutes(clientId, supplierId).settings;

const routes = (clientId: string, supplierId: string) => {
  return {
    settings: base(clientId, supplierId),
    privacyPolicy: `${base(clientId, supplierId)}/privacyPolicy`,
    generalParameters: `${base(clientId, supplierId)}/parameters`,
  };
};

export default routes;
