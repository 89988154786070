import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { Button } from "../../UIKit/Button";
import Routes from "./Routes";
import { PaginatedTable } from "../../UIKit/Table";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { FormattedMessage, useIntl } from "react-intl";
import { DealersPageResponse, DealersProvider } from "./DealersProvider";
import { Dealer } from "./Dealers";
import { Modal } from "../../UIKit/Modal";
import { BONotificationPayload } from "../../app/Notifications";
import { Input } from "../../UIKit/form/Input";

interface DealersListProps {
  dealersProvider: DealersProvider;
  notify: (notification: BONotificationPayload) => void;
}

function ExcelExtractor(props: { dealersProvider: DealersProvider }) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <div className="relative">
      <Button
        onClick={() => {
          setIsDownloading(true);
          props.dealersProvider
            .downloadExport()
            .then(() => {
              setIsDownloading(false);
            })
            .catch(() => {
              setIsDownloading(false);
            });
        }}
        loading={isDownloading}
        label={intl.formatMessage({
          id: "dealers.cta.download",
          defaultMessage: "Download dealers in Excel",
        })}
        type="secondary"
        iconName="DownloadIcon"
      />
    </div>
  );
}

export function DealersList(props: DealersListProps) {
  const dealersPerPage = 30; // constrained by the API
  const history = useHistory();
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();

  const intl = useIntl();

  const [startingAt, setStartingAt] = useState<number>(1);
  const [toSearch, setToSearch] = useState<string>("");
  const [searchDraft, setSearchDraft] = useState<string>("");

  const {
    isFetching: listsAreFetching,
    data: dealersListPage,
    refetch,
  } = useQuery<DealersPageResponse>(
    "dealersList::all::page::" + startingAt + "::name::" + toSearch,
    () => {
      return props.dealersProvider.findPaginatedDealers(
        (startingAt - 1) * dealersPerPage + 1,
        dealersPerPage,
        toSearch
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadDealersSlice(number: number) {
    setStartingAt(number);
  }

  function onDelete(d: Dealer) {
    props.dealersProvider
      .delete(d.id)
      .then(() => {
        refetch().then(() => {
          props.notify({
            type: "success",
            message: intl.formatMessage({
              id: "dealersImports.actions.delete.success",
              defaultMessage: "The dealer has been successfully deleted",
            }),
          });
        });
        return true;
      })
      .catch(() => {
        props.notify({
          type: "error",
          message: intl.formatMessage({
            id: "dealersImports.actions.delete.error",
            defaultMessage: "An error occurred while deleting the dealer",
          }),
        });
        return true;
      });
  }

  if (!dealersListPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "dealers.loading",
            defaultMessage: "Loading dealers",
          })}
        />
      </div>
    );
  } else {
    const dealers: Dealer[] = dealersListPage.items;

    const dealersStats = dealersListPage.stats;
    return (
      <>
        {dealers.length === 0 && startingAt === 1 && toSearch === "" ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="no.dealers.yet"
                  defaultMessage="No dealer for the moment"
                />
              </p>
              <p>
                <FormattedMessage
                  id="no.dealers.yet.invite"
                  defaultMessage="Your users want to buy your products!"
                />
              </p>
              <div className="pt-2">
                <Button
                  label={"import.first.dealers.cta"}
                  type="primary"
                  onClick={() =>
                    history.push(Routes(clientId, supplierId).addDealersImport)
                  }
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2 mt-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage id="dealers" defaultMessage="Dealers" /> &gt;{" "}
                <FormattedMessage
                  id="dealers.count.plural"
                  defaultMessage="{num, plural, one {# dealer} other {# dealers}}"
                  values={{ num: dealersStats.totalCount }}
                />
              </h2>
              {listsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "dealers.checking.dealers",
                      defaultMessage: "Checking the distributors",
                    })}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-row pb-4 w-56 gap-2">
                <Input
                  name={"Search By Name"}
                  label={intl.formatMessage({
                    id: "dealers.search.by.name",
                    defaultMessage: "Search by name",
                  })}
                  onChange={(e) => {
                    setSearchDraft(e.target.value);
                  }}
                  isValid={true}
                />
                <div className="self-end pb-1">
                  <Button
                    onClick={() => {
                      setToSearch(searchDraft);
                    }}
                    label={"search"}
                    size="small"
                  />
                </div>
              </div>
              <ExcelExtractor dealersProvider={props.dealersProvider} />
            </div>
            <PaginatedTable<Dealer>
              onPageChange={loadDealersSlice}
              totalItemsCount={dealersStats.totalCount || 0}
              itemsPerPage={dealersPerPage}
              data={dealers}
              page={startingAt}
              columns={[
                {
                  label: "dealers.name",
                  content: (d) => <span>{d.name}</span>,
                },
                {
                  label: "dealers.city",
                  content: (d) => <span>{d.city}</span>,
                },
                {
                  label: "dealers.phone",
                  content: (d) => <span>{d.phone}</span>,
                },
                {
                  label: intl.formatMessage({
                    id: "dealersImports.actions",
                    defaultMessage: "Actions",
                  }),
                  content: (d) => <DeleteDealers d={d} onDelete={onDelete} />,
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label="dealersImports.add.an.import"
                  onClick={(_e) => {
                    history.push(Routes(clientId, supplierId).addDealersImport);
                  }}
                />
              </div>
            </div>{" "}
          </div>
        )}
      </>
    );
  }
}

function DeleteDealers(props: { d: Dealer; onDelete: (d: Dealer) => void }) {
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const onConfirmation = () => {
    setDisplayConfirmation(false);
    props.onDelete(props.d);
  };

  return (
    <>
      <Button
        onClick={() => {
          setDisplayConfirmation(true);
        }}
        label={"dealersImports.actions.delete"}
        type="destroy"
        size="tiny"
      />
      {displayConfirmation && (
        <Modal
          onCancel={() => {
            setDisplayConfirmation(false);
          }}
          onConfirm={() => {
            onConfirmation();
          }}
          altButtonLabel="cancel"
          mainButtonLabel="delete"
        >
          <>
            <FormattedMessage
              id="dealersImports.actions.delete.confirmation"
              defaultMessage="Are you sure you want to delete this dealer?"
            />
            <hr />
            <ul>
              <li>
                <FormattedMessage id="dealers.name" defaultMessage="Name" /> :{" "}
                {props.d.name}
              </li>
              <li>
                <FormattedMessage id="dealers.city" defaultMessage="City" /> :{" "}
                {props.d.city}
              </li>
              <li>
                <FormattedMessage id="dealers.phone" defaultMessage="Phone" /> :{" "}
                {props.d.phone}
              </li>
            </ul>
          </>
        </Modal>
      )}
    </>
  );
}
