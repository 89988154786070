import {
  ProductNews,
  ProductNewsJournal,
  productNewsPageResponse,
} from "./ProductNews";
import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { productNewsDTO } from "./ProductNewsForm";
import { handleErrors } from "../../app/APIError";
import { dirbatLocale } from "../../translations/translations";

function getOneProductNews(productNewsData: []): ProductNews[] {
  return productNewsData.map((productNewsDatum: any) =>
    mapProductNewsFromApi(productNewsDatum)
  );
}

function mapProductNewsFromApi(productNewsDatum: any): ProductNews {
  return {
    headline: productNewsDatum.headline,
    id: productNewsDatum.id,
    photo: productNewsDatum.photoUrl,
    productCode: productNewsDatum.productCode,
    publicationDate: DateTime.fromISO(productNewsDatum.publishedAt),
    removalDate: DateTime.fromISO(productNewsDatum.unpublishedAt),
    notificationDate: DateTime.fromISO(productNewsDatum.notificationDate),
    status: productNewsDatum.status,
    title: productNewsDatum.title,
    triggersNotification: productNewsDatum.triggersNotification,
  };
}

export class ProductNewsJournalAPI
  extends APIClient
  implements ProductNewsJournal {
  constructor(
    token: string,
    clientId: string,
    private supplierId: string,
    locale: dirbatLocale
  ) {
    super(token, locale);
  }
  findPaginated(
    firstIdx: number,
    _newsPerPage: number
  ): Promise<productNewsPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${
        this.supplierId
      }/product_news?page=${Math.floor(firstIdx / _newsPerPage) + 1}`
    )
      .then((response) => response.json())
      .then((productNewsData) => {
        return {
          items: getOneProductNews(productNewsData["hydra:member"]),
          stats: { totalCount: productNewsData["hydra:totalItems"] },
        };
      });
  }

  findOneById(id: string): Promise<ProductNews> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/product_news/${id}`
    )
      .then((response) => response.json())
      .then((newsData): ProductNews => mapProductNewsFromApi(newsData));
  }

  delete(news: ProductNews): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/product_news/${news.id}`,
      {
        method: "delete",
      }
    )
      .then((response) => {
        return response.status === 204;
      })
      .then((success): boolean => success);
  }

  save(dto: productNewsDTO): Promise<ProductNews> {
    const method = "POST";
    const endPointUrl = dto.id
      ? `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/product_news/${dto.id}`
      : `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/product_news`;
    let body;

    body = new FormData();
    body.append("headline", dto.headline);
    if (dto.image !== undefined) {
      body.append("photoFile", dto.image);
    }
    body.append("productCode", dto.productCode);
    body.append("publishedAt", dto.publicationDate!.toISO());
    body.append("title", dto.title);
    body.append(
      "triggersNotification",
      !!dto.triggersNotification ? "true" : ""
    );
    body.append("status", dto.status);
    dto.removalDate && body.append("unpublishedAt", dto.removalDate.toISO());

    let init: RequestInit = {
      method: method,
      body: body,
    };
    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((productNewsData) => {
        return mapProductNewsFromApi(productNewsData);
      })
      .catch((e: Error) =>
        Promise.reject(JSON.parse(e.message)["hydra:description"])
      );
  }
}
