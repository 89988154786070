import React, { useEffect, useState } from "react";
import { Input } from "../../UIKit/form/Input";
import { Button } from "../../UIKit/Button";
import { BONotificationPayload } from "../../app/Notifications";
import { ContributorsDirectory } from "./Contributors";
import { isEmail } from "../../utilities/Str";
import { useIntl } from "react-intl";

export interface ContributorFormProps {
  awaitsValidation: boolean;
  data: contributorDTO;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
  directory: ContributorsDirectory;
  teamId: string;
}

export interface contributorDTO {
  lastname: string;
  firstname: string;
  email: string;
  teamId: string;
  id?: string;
}

export function ContributorForm(props: ContributorFormProps) {
  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const intl = useIntl();
  const [lastname, setLastname] = useState<string>(props.data.lastname);
  const [firstname, setFirstname] = useState<string>(props.data.firstname);
  const [email, setEmail] = useState<string>(props.data.email);
  function isValid(): boolean {
    return !!lastname && !!firstname && isEmail(email);
  }
  useEffect(() => {
    setLastname(props.data.lastname);
    setFirstname(props.data.firstname);
    setEmail(props.data.email);
  }, [props.data]);
  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const contributorDTO: contributorDTO = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        id: props.data.id,
        teamId: props.teamId,
      };
      props.directory
        .save(contributorDTO, props.teamId)
        .then((_savedContributor) => {
          props.onSuccess();
          props.notify({
            type: "success",
            message: "contributors.contributor.saved",
          });
        })
        .catch((reason) => {
          props.notify({ type: "error", message: reason });
        });
    }
  }
  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <Input
            name="firstname"
            label={intl.formatMessage({
              id: "contributors.firstname",
              defaultMessage: "Firstname",
            })}
            value={firstname}
            isValid={!!firstname}
            required={true}
            onChange={(e: any) => {
              setFirstname(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="lastname"
            label={intl.formatMessage({
              id: "contributors.lastname",
              defaultMessage: "Lastname",
            })}
            value={lastname}
            isValid={!!lastname}
            required={true}
            onChange={(e: any) => {
              setLastname(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="email"
            label={intl.formatMessage({
              id: "contributors.email",
              defaultMessage: "Email",
            })}
            value={email}
            isValid={isEmail(email)}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <Button
          onClick={onSubmit}
          disabled={false}
          iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
          label={
            props.data.id
              ? intl.formatMessage({
                  id: "contributors.edit.submit",
                  defaultMessage: "Save this user",
                })
              : intl.formatMessage({
                  id: "contributors.add.submit",
                  defaultMessage: "Add this user",
                })
          }
        />
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            iconName="BanIcon"
            type="secondary"
            label={intl.formatMessage({
              id: "cancel",
              defaultMessage: "cancel",
            })}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
