import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { contentPageResponse } from "../commonTypes";
import { Meetup, MeetupCalendar, meetupDTO } from "./Meetup";
import { handleErrors } from "../../app/APIError";
import { dirbatLocale } from "../../translations/translations";

function getMeetup(meetupData: []): Meetup[] {
  return meetupData.map((meetupDatum: any) => mapMeetupFromApi(meetupDatum));
}

function mapMeetupFromApi(meetupDatum: any): Meetup {
  return {
    id: meetupDatum.id,
    publicationDate: meetupDatum.publishedAt
      ? DateTime.fromISO(meetupDatum.publishedAt)
      : DateTime.local(),
    removalDate: meetupDatum.unpublishedAt
      ? DateTime.fromISO(meetupDatum.unpublishedAt)
      : DateTime.local(),
    status: meetupDatum.status,
    title: meetupDatum.title,
    description: meetupDatum.description,
    location: meetupDatum.location,
    startDate: meetupDatum.startDate
      ? DateTime.fromISO(meetupDatum.startDate)
      : DateTime.local(),
    endDate: meetupDatum.endDate
      ? DateTime.fromISO(meetupDatum.endDate)
      : DateTime.local(),
  };
}

export class MeetupsCalendarAPI extends APIClient implements MeetupCalendar {
  constructor(
    token: string,
    clientId: string,
    private supplierId: string,
    locale: dirbatLocale
  ) {
    super(token, locale);
  }
  findPaginated(
    firstIdx: number,
    itemsPerPage: number
  ): Promise<contentPageResponse<Meetup>> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/meetups?page=${
        Math.floor(firstIdx / itemsPerPage) + 1
      }`
    )
      .then((response) => response.json())
      .then((meetupData) => {
        return {
          items: getMeetup(meetupData["hydra:member"]),
          stats: { totalCount: meetupData["hydra:totalItems"] },
        };
      });
  }

  findOneById(id: string): Promise<Meetup> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/meetups/${id}`
    )
      .then((response) => response.json())
      .then((meetupData): Meetup => mapMeetupFromApi(meetupData));
  }

  delete(meetup: Meetup): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/meetups/${meetup.id}`,
      {
        method: "delete",
      }
    )
      .then((response) => {
        return response.status === 204;
      })
      .then((success): boolean => success);
  }

  save(dto: meetupDTO): Promise<Meetup> {
    const method = dto.id ? "PUT" : "POST";
    const endPointUrl = dto.id
      ? `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/meetups/${dto.id}`
      : `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/meetups`;

    const init: RequestInit = {
      method: method,
      body: JSON.stringify({
        description: dto.description,
        title: dto.title,
        location: dto.location,
        startDate: dto.startDate?.toUTC().toISO(),
        endDate: dto.endDate?.toUTC().toISO(),
        publishedAt: dto.publicationDate?.startOf("day").toUTC().toISO(),
        unpublishedAt: dto.removalDate?.endOf("day").toUTC().toISO(),
        status: dto.status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((meetupData) => {
        return mapMeetupFromApi(meetupData);
      })
      .catch((e: Error) =>
        Promise.reject(JSON.parse(e.message)["hydra:description"])
      );
  }
}
