import { DateTime } from "luxon";

type DealerID = string;

export interface Dealer {
  id: DealerID;
  name: string;
  city: string;
  phone: string;
}
export type DealersImportStatus = "pending" | "processing" | "converted";

export interface DealersImport {
  id: string;
  status: DealersImportStatus;
  progress: number;
  createdAt: DateTime;
}

export interface DealersImportConversionReport {
  errorsByDealer: {}[];
  itemsCount: number;
  itemsWithErrorsCount: number;
  processableItemsCount: number;
  validItems: number;
  fileErrors: [];
  errors: {
    [lineNumber: number]: DealersImportConversionError;
  };
}
interface DealersImportConversionError {
  type: string;
  title: string;
  detail: string;
  violations: conversionViolation[];
}
interface conversionViolation {
  propertyPath: string;
  title: string;
  parameters: {};
}

export function reportShouldBeAvailable(dealersImport: DealersImport) {
  return (
    dealersImport.status !== "processing" && dealersImport.status !== "pending"
  );
}
