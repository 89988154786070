import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { CataloguesList } from "./CataloguesList";
import { CatalogueForm } from "./CatalogueForm";
import { APICataloguesProvider } from "./CataloguesDirectoryAPI";
import { useAuth } from "../../auth";
import { CataloguesDashboard } from "./CataloguesDashboard";
import { DealersImportList } from "./DealersImportList";
import { APIDealersImportProvider } from "./DealersImportProviderAPI";
import { DealersImportForm } from "./DealersImportForm";
import { DealersList } from "./DealersList";
import { CatalogueConversionReport } from "./CatalogueConversionReport";
import { DealerImportConversionReport } from "./DealerImportConversionReport";
import { BONotificationPayload } from "../../app/Notifications";

export function CataloguesModule(props: {
  notify: (notification: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const cataloguesProvider = new APICataloguesProvider(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  const distributionProvider = new APIDealersImportProvider(
    token,
    clientId,
    supplierId,
    auth.user.locale
  );
  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").addCatalogue} exact>
        <CatalogueForm
          clientId={clientId}
          catalogueProvider={cataloguesProvider}
          supplierId={supplierId}
          onComplete={() =>
            history.push(ModuleRoutes(clientId, supplierId).catalogues)
          }
        />
      </Route>
      <Route
        path={ModuleRoutes(":clientId", ":supplierId").conversionReport(
          ":catalogueId"
        )}
        exact
      >
        <CatalogueConversionReport cataloguesProvider={cataloguesProvider} />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").catalogues} exact>
        <CataloguesList
          cataloguesProvider={cataloguesProvider}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route
        path={ModuleRoutes(":clientId", ":supplierId").dealersImports}
        exact
      >
        <DealersImportList dealersImportProvider={distributionProvider} />
      </Route>
      <Route
        path={ModuleRoutes(":clientId", ":supplierId").dealersConversionReport(
          ":dealersImportId"
        )}
        exact
      >
        <DealerImportConversionReport cataloguesProvider={cataloguesProvider} />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").dealersList} exact>
        <DealersList
          dealersProvider={distributionProvider}
          notify={props.notify}
        />
      </Route>
      <Route
        path={ModuleRoutes(":clientId", ":supplierId").addDealersImport}
        exact
      >
        <DealersImportForm
          clientId={clientId}
          supplierId={supplierId}
          dealersImportProvider={distributionProvider}
          onComplete={() =>
            history.push(ModuleRoutes(clientId, supplierId).dealersImports)
          }
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").dashboard}>
        <CataloguesDashboard clientId={clientId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
}
