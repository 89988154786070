class APIError implements Error {
  constructor(message: string, name: string) {
    this.message = message;
    this.name = name;
  }

  message: string;
  name: string = "APIError";
}

export async function handleErrors(response: Response): Promise<Response> {
  if (response.status >= 400) {
    throw Error(await response.text());
  }
  return response;
}
