import React, { useEffect, useState } from "react";
import {
  appConfigurationsBySections,
  Application,
  AppsDirectory,
} from "../Application";
import { BONotificationPayload } from "../../../app/Notifications";
import { useParams } from "react-router-dom";
import { AppsConfigurationsForm } from "../../Settings/AppsConfigurationsForm";

export interface AdminAppsConfigurations {
  AppsDirectory: AppsDirectory;
  notify: (notification: BONotificationPayload) => void;
}

export function AdminAppsConfigurations(props: AdminAppsConfigurations) {
  const { clientId } = useParams<{ clientId: string }>();
  const [app, setApp] = useState<Application>();
  const [sectionedAppConfig, setSectionedAppConfig] = useState<
    appConfigurationsBySections[]
  >();
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    props.AppsDirectory.getOne(clientId).then((app) => {
      setApp(app);
    });
  }, [clientId]);

  useEffect(() => {
    if (!app) return;
    props.AppsDirectory.getConfigs(app?.id).then((configs) => {
      setSectionedAppConfig(configs);
      setRefetch(false);
    });
  }, [app, refetch]);

  const submit = (feature: string, values: string[]) => {
    if (!app) return;
    props.AppsDirectory.updateConfig(app?.id, feature, values[0])
      .then(() => {
        props.notify({
          message: "appsConfigurationsAdminForm.success",
          type: "success",
        });
        setRefetch(true);
      })
      .catch((e) => {
        console.log(e);
        props.notify({
          message: e.message,
          type: "error",
        });
      });
  };

  return (
    <AppsConfigurationsForm
      listsAreFetching={sectionedAppConfig === undefined || refetch}
      sectionedAppConfig={sectionedAppConfig}
      submit={submit}
      notify={props.notify}
      appName={app?.name}
    />
  );
}
